import { Input } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { BaseButton } from "../BaseComponents";
import { initiateSecureAction, verifySecureAction } from "./accountApi";

export const AccountMfa = ({ onSuccess }: any) => {
  const [timer, setTimer] = useState(120);
  const [isPendingVerification, setIsPendingVerification] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState("");

  useEffect(() => {
    initiateSecureAction().catch(() => {
      setErrorMessage("Please try again later");
    });
  }, []);

  useEffect(() => {
    let intervalId: any = null;

    if (isPendingVerification) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return intervalId ? () => clearInterval(intervalId) : () => {};
  }, [isPendingVerification]);

  const handleClickResendCode = () => {
    setTimer(120);
    setOtpCode("");
    setErrorMessage("");
    initiateSecureAction();
  };

  const handleClickSubmitCode = () => {
    verifySecureAction({ otpCode })
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        setErrorMessage("Invalid code");
      });
  };

  const getCountdown = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer - minutes * 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="max-w-96 m-auto h-full flex flex-col items-center justify-center">
      <h1 className="text-3xl">Enter SMS Code</h1>
      <Input
        classNames={{
          base: "mt-5",
          errorMessage: "text-lg font-switzer",
          innerWrapper: "font-light text-3xl",
          input: "text-sage font-light text-3xl placeholder:text-sage",
          label:
            "tracking-widest text-griselda font-semibold mb-8 uppercase text-lg",
        }}
        isInvalid={errorMessage.length > 0}
        maxLength={6}
        onValueChange={setOtpCode}
        placeholder="000000"
        value={otpCode}
        variant="underlined"
      />
      <p
        className={`transition-colors py-6 text-${
          errorMessage.length > 0 ? "danger" : "transparent invisible"
        }`}
      >
        {errorMessage}
      </p>
      <BaseButton
        className="w-full"
        disableRipple
        isDisabled={otpCode.length !== 6}
        isLoading={loading}
        onPress={handleClickSubmitCode}
      >
        Verify
      </BaseButton>
      <p className="mt-4 text-griselda font-light">
        Didn&apos;t receive the code?{" "}
        {timer > 0 && `Resend in ${getCountdown()}`}
        {timer <= 0 && (
          <button
            className="font-semibold underline"
            onClick={handleClickResendCode}
          >
            Resend
          </button>
        )}
      </p>
    </div>
  );
};
