import { X } from "@phosphor-icons/react";
import { ReactNode, useEffect, useRef } from "react";
import Sheet from "react-modal-sheet";

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
};

const BottomSheet = ({ children, className = "", isOpen, onClose, title = "" }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const contentElement = ref.current || document.createElement("div");
      const focusable = contentElement.querySelectorAll<
        HTMLButtonElement | HTMLInputElement
      >("button, input");
      const firstElement = focusable[0];
      const lastElement = focusable[focusable.length - 1];

      const handleKeydown = (event: KeyboardEvent) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
          if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
        if (event.key === "Escape") {
          onClose();
        }
      };

      contentElement.addEventListener("keydown", handleKeydown);

      return () => {
        contentElement.removeEventListener("keydown", handleKeydown);
      };
    }
  }, [isOpen, onClose]);

  return (
    <Sheet
      className={className}
      detent="content-height"
      disableScrollLocking
      isOpen={isOpen}
      onClose={onClose}
    >
      <Sheet.Container>
        <Sheet.Content>
          <div ref={ref}>
            <div className="flex items-center justify-between p-5">
              <h3 className="text-2xl">{title}</h3>
              <button onClick={onClose}>
                <X size={32} fill="#ADBCAD"/>
              </button>
            </div>
            <div className="px-5 pb-5">{children}</div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default BottomSheet;
