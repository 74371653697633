import { useState } from "react";
import { Input } from "@nextui-org/react";
import { BaseButton } from "../BaseComponents";
import { LoginLayoutEmailSent, LoginLayoutPage } from "./LoginLayout";
import { requestMagicLinkWithEmail } from "./loginApi";

const noop = () => {};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const LoginInputEmail = () => {
  const [email, setEmail] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleChangeEmail = (value: string) => {
    const validation = validateEmail(value);
    setIsValid(validation !== null);
    setEmail(value);
  };

  const handlePressButton = () => {
    if (isValid) {
      setHasSubmitted(true);
      requestMagicLinkWithEmail(email).then(noop).catch(noop);
    }
  };

  const handleResend = () => {
    if (isValid) {
      requestMagicLinkWithEmail(email).then(noop).catch(noop);
    }
  };

  return (
    <LoginLayoutPage>
      <>
        {hasSubmitted ? (
          <LoginLayoutEmailSent
            message={
              <>
                If you are an existing user or have been invited to join, you
                will receive a link in your inbox at{" "}
                <span className="break-all">{email}</span>
              </>
            }
            onResend={handleResend}
          />
        ) : (
          <>
            <h1 className="text-3xl sm:text-4xl mb-4">
              Login or Create Your Account
            </h1>
            <p className="text-midnight font-light text-lg">
              To receive a link, you must be an existing user or have already
              received an invitation to join.
            </p>
            <Input
              classNames={{
                base: "mt-16",
                label: "tracking-widest text-griselda font-semibold mb-16",
                input:
                  "my-2 text-sage font-light text-2xl placeholder:text-strokeGrey",
              }}
              label="EMAIL"
              onValueChange={handleChangeEmail}
              placeholder="you@email.com"
              variant="underlined"
            />
            <BaseButton
              className="w-full mt-6 mb-5"
              isDisabled={!isValid}
              onPress={handlePressButton}
            >
              Send Link
            </BaseButton>
            <p className="text-midnight font-light text-lg">
              Having trouble? Contact{" "}
              <a href="mailto:support@getwhale.co" className="font-normal">
                support@getwhale.co
              </a>
            </p>
          </>
        )}
      </>
    </LoginLayoutPage>
  );
};
