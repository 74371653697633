import { useEffect, useState } from "react";
import { fetchInvestorAgreementMeta } from "../shared/onboardingApi";
import { BaseLoadingScreen } from "../../BaseComponents";
import { AgreementMetaType } from "../onboardingTypes";

const date = new Date().toLocaleString("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

export const SecuritiesAccountControlAgreement = () => {
  const [loadingMessage, setLoadingMessage] = useState("");
  const [meta, setMeta] = useState<AgreementMetaType>();

  useEffect(() => {
    fetchInvestorAgreementMeta()
      .then((data: AgreementMetaType) => {
        setMeta(data);
        setLoadingMessage("");
      })
      .catch(() => {
        console.log("There was an error loading the investor agreements");
        setLoadingMessage("We are having trouble, please try again later.");
      });
  }, []);

  return loadingMessage === "" ? (
    <div className="p-5 switzer">
      <h1 className="text-center font-semibold text-2xl mb-5 uppercase">
        Securities Account and Deposit Account Control Agreement
      </h1>
      <p className="mb-3">
        This Securities Account and Deposit Account Control Agreement (as
        amended, supplemented or otherwise modified from time to time, this{" "}
        <span className="underline">"Agreement"</span>), dated as of {date}, is
        entered into by and among{" "}
        <span className="font-semibold">{meta?.propertyLegalName}</span> (the{" "}
        <span className="underline">"Secured Party"</span>),{" "}
        <span className="font-semibold">{`${meta?.legalFirstName} ${meta?.legalLastName}`}</span>{" "}
        (the <span className="underline">"Pledgor"</span>), [Build Whale RIA]
        ("Adviser") and{" "}
        <span className="font-semibold">Alpaca Securities LLC</span> ("Broker").
      </p>
      <p className="mb-3">
        WHEREAS, the Pledgor has entered into an{" "}
        <span className="font-semibold">Alpaca Customer Agreement</span> (the{" "}
        <span className="underline">"Brokerage Agreement"</span>) with the
        Broker pursuant to which the Broker has agreed to act as a securities
        intermediary for Pledgor and, in that capacity, maintain a Securities
        Account (as defined below) for the Pledgor described on Schedule A
        hereto (together with any substitute, replacement or renumbering of such
        account, the <span className="underline">"Brokerage Account"</span>) to
        which certain Financial Assets (as defined below) may be credited from
        time to time;
      </p>
      <p className="mb-3">
        WHEREAS, the Pledgor has granted to the Secured Party a security
        interest in and lien upon the Brokerage Account, the Financial Assets
        contained therein, and any and all Proceeds (as defined below) of any
        thereof, whether now or hereafter existing or arising (collectively, the{" "}
        <span className="underline">"Collateral"</span>) to secure all
        obligations of the Pledgor under the{" "}
        <span className="font-semibold">Lease Agreement</span> (the{" "}
        <span className="underline">"Lease Agreement"</span>); and
      </p>
      <p className="mb-3">
        WHEREAS, the Secured Party and the Pledgor have requested that the
        Broker enter into this Agreement with them to perfect the security
        interest of the Secured Party in the Collateral, and Broker is willing
        to do so upon the terms contained in this Agreement;
      </p>
      <p className="mb-3">
        NOW, THEREFORE, for good and valuable consideration the receipt and
        sufficiency of which is hereby acknowledged, the parties to this
        Agreement hereby agree as follows:
      </p>
      <p className="my-6">
        1. <span className="underline">Definitions</span> The following terms
        shall have the meanings indicated below:
      </p>
      <p className="mb-3">
        <span className="underline">"Business Day"</span> means any day other
        than a Saturday, Sunday or day on which banks are authorized or required
        by law to close.
      </p>
      <p className="mb-3">
        <span className="underline">"Code"</span> means the Uniform Commercial
        Code of the State of Delaware.
      </p>
      <p className="mb-3">
        <span className="underline">"Court Order"</span> has the meaning set
        forth in <span className="underline">Section 11</span>.
      </p>
      <p className="mb-3">
        <span className="underline">"Effective Time"</span> means, with respect
        to any Notice, a reasonable period of time not to exceed two Business
        Days after Delivery of a Notice to Broker.
      </p>
      <p className="mb-3">
        <span className="underline">"Entitlement Order"</span> has the meaning
        given in Section 8-102(a)(8) of the Code.
      </p>
      <p className="mb-3">
        <span className="underline">"Fees"</span> has the meaning set forth in{" "}
        <span className="underline">Section 9</span>.
      </p>
      <p className="mb-3">
        <span className="underline">"Financial Assets"</span> has the meaning
        given in Article 8 of the Code.
      </p>
      <p className="mb-3">
        <span className="underline">"Instructions"</span> means, with respect to
        the Brokerage Account and the Financial Assets, Entitlement Orders and
        other instructions given by or on behalf of Pledgor to Broker concerning
        the Brokerage Account in accordance with the Brokerage Agreement and
        Entitlement Orders and other instructions of Secured Party concerning
        the Brokerage Account given in the manner required by this Agreement.
      </p>
      <p className="mb-3">
        <span className="underline">"Item"</span> has the meaning set forth in{" "}
        <span className="underline">Section 8</span>.
      </p>
      <p className="mb-3">
        <span className="underline">"Losses"</span> has the meaning set forth in{" "}
        <span className="underline">Section 13(a)</span>.
      </p>
      <p className="mb-3">
        <span className="underline">"Notice"</span> means a Notice of Exclusive
        Control or a Notice of Termination, as applicable.
      </p>
      <p className="mb-3">
        <span className="underline">"Notice of Exclusive Control"</span> means a
        notice, substantially in the form of{" "}
        <span className="underline">Exhibit A</span> hereto, stating that the
        Secured Party is exercising control over the Collateral.
      </p>
      <p className="mb-3">
        <span className="underline">"Notice of Termination"</span> means a
        notice, substantially in the form of{" "}
        <span className="underline">Exhibit B</span> hereto, stating that the
        Secured Party's security in the Collateral has been released and that
        this Agreement is terminated.
      </p>
      <p className="mb-3">
        <span className="underline">"Proceeds"</span> has the meaning given in
        Section 9-102(a)(64) of the Code.
      </p>
      <p className="mb-3">
        <span className="underline">"Securities Account"</span> has the meaning
        given in Section 8-501(a) of the Code.
      </p>
      <p className="my-6">
        2. <span className="underline">Broker Representations</span>
      </p>
      <p className="mb-3">
        (a) The Broker hereby represents and warrants to the Secured Party and
        the Pledgor that: (a) the Brokerage Account has been established in the
        name of the Pledgor as described on Schedule A, (b) Broker has not
        entered into any currently effective agreement with any person relating
        to the Brokerage Account and/or any Collateral under which Broker may be
        obligated to comply with instructions originated by a person other than
        the Pledgor or the Secured Party, (c) in the ordinary course of its
        business, Broker maintains Securities Accounts for others and is acting
        in that capacity in connection with the Brokerage Account and this
        Agreement; (d) Broker is a "broker" within the meaning of § 8-102(a)(3)
        of the Code; (e) the Brokerage Account is and will be maintained by the
        Broker as a Securities Account with respect to Financial Assets credited
        thereto; and (f) for purposes of Article 8 of the Code, the State of
        Delaware is Broker's securities intermediary jurisdiction.
      </p>
      <p className="my-6">
        3. <span className="underline">Pledgor Representations</span>. Pledgor
        represents and warrants to the Secured Party that it has granted a
        security interest in the Collateral to Secured Party and has not
        assigned or granted a security interest in the Collateral that is
        currently effective except to the Secured Party.
      </p>
      <p className="my-6">
        4. <span className="underline">Secured Party Representations</span>. The
        Secured Party represents to Broker that the Pledgor has granted a
        security interest in the Collateral in favor of the Secured Party to
        secure Pledgor's obligations to Secured Party under the Lease Agreement.
      </p>
      <p className="my-6">
        5. <span className="underline">Confirmation of Other Matters</span>. All
        parties acknowledge and agree that: (i) this Agreement is intended to
        constitute an authenticated record for purposes of § 9104 of the Code,
        (ii) Broker has not reviewed the Lease Agreement, and neither of them
        makes any representation or warranty as to the sufficiency or
        effectiveness of such agreements, and (iii) Broker may hold Financial
        Assets credited to the Brokerage Account in the name of Broker or its
        nominee and may utilize any subcustodian, securities depository or
        clearing corporation to the extent it deems appropriate, subject to the
        rules, terms and conditions of such subcustodian, securities depository
        or clearing corporation and provided that Broker identifies on its
        records as belonging to Pledgor and pledged to Secured Party a quantity
        of securities as part of a fungible bulk of securities held in Broker's
        account at such subcustodian, securities depository or clearing
        corporation and represented in accounts which include only assets held
        by Broker for its customers. Unless required by law, Broker will not
        agree with any third party that Broker will comply with instructions
        originated by such third party directing disposition of the Collateral
        without the prior written consent of the Secured Party and the Pledgor.
        The Secured Party acknowledges and agrees that Broker is not responsible
        for the validity, priority or enforceability of the Secured Party's
        security interest in the Collateral or any other assets of the Pledgor.
      </p>
      <p className="my-6">
        6. <span className="underline">Control</span>. Secured Party may
        exercise sole and exclusive control of the Collateral necessary to
        secure all obligations of the Pledgor under the Lease Agreement at any
        time by delivering to Broker a Notice of Exclusive Control. From and
        after the Effective Time of a Notice of Exclusive Control received by
        Broker, Broker shall, without inquiry and in reliance upon such Notice
        of Exclusive Control, comply with Instructions, including Entitlement
        Orders, solely from Secured Party with respect to the Collateral.
        Secured Party agrees that Secured Party will only issue a Notice of
        Exclusive Control if Secured Party has determined in good faith that an
        event of default or other similar event has occurred under the Lease
        Agreement which entitles Secured Party to exercise its rights as a
        secured party with respect to the Collateral. Broker shall not have any
        liability to Secured Party or Pledgor for complying with a Notice of
        Exclusive Control or Instructions (including Entitlement Orders)
        originated by Secured Party. Broker will be fully protected in complying
        with a Notice of Exclusive Control (and any instructions, including
        Entitlement Orders, originated by Secured Party) whether or not Pledgor
        may allege that no rights of Secured Party exist to provide such
        instructions or to issue the Notice of Exclusive Control. For avoidance
        of doubt, each Instruction or Notice of Exclusive Control shall apply to
        the Brokerage Account listed on{" "}
        <span className="underline">Schedule A</span>.
      </p>
      <p className="my-6">
        7. <span className="underline">Subordination of Rights</span>. Except as
        provided in this Agreement, Broker agrees it shall not setoff, charge,
        deduct or otherwise withdraw funds from the Brokerage Account. The
        parties agree that any security interest in or lien on, or right of
        set-off against any of the Collateral that Broker may have now or in the
        future is hereby waived other than with respect to (a) with respect to
        Broker, any advances that Broker may from time to time make to, or for
        the benefit of, Pledgor for purposes of clearing or settling purchases
        or sales of securities in the Brokerage Account and (b) any fees,
        charges, expenses and other amounts not described in clause (a) above
        owed to Broker and incurred in connection with the performance of its
        duties hereunder or pursuant to the Brokerage Agreement related to the
        maintenance and operation of the Brokerage Account, for which Broker
        shall have a prior claim to the Collateral having priority over the
        interest of Secured Party.
      </p>
      <p className="my-6">
        8. <span className="underline">Fees</span>. At any time, Broker is
        permitted to charge the Brokerage Account for all of its fees and
        expenses for the maintenance and administration of such account and the
        services provided hereunder (collectively, "Fees"). If the Financial
        Assets and cash credited to the Brokerage Account are insufficient to
        cover such Fees, the amount thereof or the shortfall, as applicable,
        will be paid by the Pledgor to Broker, as the case may be, upon demand
        therefor. After the delivery of a Notice of Exclusive Control, the
        Secured Party agrees to pay such Fees within 15 calendar days after
        demand, without setoff or counterclaim, to the extent such Fees are not
        timely paid in full by the Pledgor. Any failure of the Pledgor or the
        Secured Party to make such payment shall be deemed a breach of this
        Agreement.
      </p>
      <p className="my-6">
        9.{" "}
        <span className="underline">
          Statements, Confirmations and Notices of Adverse Claims
        </span>
        . The Pledgor agrees that Broker may furnish to the Secured Party copies
        of all statements and confirmations concerning the Brokerage Account to
        the Secured Party upon the written request and at the sole expense of
        the Secured Party. If Broker receives written notice of any lien,
        encumbrance or adverse claim against the Collateral, if not prohibited
        by law, then the part receiving such notice will use commercially
        reasonable efforts to promptly notify the Secured Party and the Pledgor
        thereof.
      </p>
      <p className="my-6">
        10.{" "}
        <span className="underline">
          No Responsibility or Liability Except for Gross Negligence or Willful
          Misconduct
        </span>
        . Broker shall not have any obligations hereunder including any claims,
        causes of action, liabilities, lawsuits, demands and damages of any kind
        or nature between the Pledgor and the Secured Party, which shall be
        entirely governed by that certain lease agreement entered by and between
        the Pledgor and the Secured Party, except for those expressly set forth
        herein. Without limiting the foregoing, Broker shall not (i) have any
        responsibility or liability to the Pledgor or the Secured Party for any
        Losses except to the extent determined by a court of competent
        jurisdiction in a final nonappealable judgment to have resulted from its
        own gross negligence or willful misconduct, (ii) be charged with
        knowledge of any provisions of the Lease Agreement or of any other
        agreements or arrangements entered into between Pledgor and Secured
        Party, (iii) have any responsibility or liability to the Pledgor or
        Secured Party with respect to the Collateral or the value or sufficiency
        of the Collateral, (iv) have any liability for any action or inaction of
        any subcustodian, securities depository or clearing corporation, (v)
        have any fiduciary duties under this Agreement to any other party.
        Broker shall be entitled to rely, and shall be fully protected in
        relying, upon any Instruction or Notice received by it that it
        reasonably believes to be from an authorized person and shall have no
        duty to investigate the genuineness, validity or appropriateness of any
        Instruction or Notice, and (vii) may, with respect to questions of law,
        obtain the advice of counsel and shall bear no liability with respect to
        anything done or omitted by it in good faith in conformity with such
        advice. The substantial compliance by Broker with its standard
        procedures for provision of the services required under this Agreement
        shall be deemed to constitute the exercise of ordinary care. In the
        event Broker acts with gross negligence or willful misconduct, the
        Pledgor and the Secured Party expressly agree that the its liability
        shall be limited to actual damages directly caused by such breach, and
        in no event shall Broker be liable for any incidental, indirect,
        special, punitive or consequential damages or attorneys' fees regardless
        of whether or not it knew of the likelihood or was made aware of the
        possibility of any such damages. [In addition, Broker shall not have any
        responsibility or liability to the Secured Party for complying with
        Instructions concerning the Collateral from the Pledgor which are
        received by it before the Effective Time of a Notice of Exclusive
        Control.] [To be updated to reflect operation of accounts] Broker shall
        not have any responsibility or liability to the Pledgor for complying
        with a Notice of Exclusive Control or complying with Instructions
        concerning the Brokerage Account or the Collateral originated by the
        Secured Party, and neither of them shall have any responsibility to
        investigate the appropriateness of any such Instructions, Notice of
        Exclusive Control or the existence or enforceability of the Pledgor's
        obligations to the Secured Party, even if Pledgor notifies them that the
        Secured Party is not legally entitled to originate any such Instruction
        or Notice of Exclusive Control, unless Broker has been served with an
        injunction, restraining order or other legal process issued by a court
        of competent jurisdiction (a "Court Order") enjoining it from complying
        and has had a reasonable opportunity to act on such Court Order. Broker
        shall not have any responsibility or liability to the Pledgor or Secured
        Party with respect to the Brokerage Account or the value of the
        Collateral, including damages arising from complying with a court order
        or injunction.
      </p>
      <p className="my-6">
        11.{" "}
        <span className="underline">
          Legal Restrictions to Performance of Obligations
        </span>
        . Whether prior or subsequent to the receipt by Broker of a Notice of
        Exclusive Control, in the event that Pledgor becomes subject to a
        voluntary or involuntary proceeding under the United States Bankruptcy
        Code, if Broker is otherwise served with a Court Order which it in good
        faith believes affects the Collateral, or Broker is of the opinion that
        acting upon the instructions of either the Pledgor or the Secured Party
        would result in the violation of any applicable law, rule or regulation,
        then it may cease acting upon the Instructions of both the Pledgor and
        the Secured Party otherwise required by the terms hereof or other
        Account Agreement (as defined below) or such Notice of Exclusive Control
        until such time as it receives a Court Order or other assurances
        satisfactory to it establishing that it may act on any such
        Instructions.
      </p>
      <p className="my-6">
        12. <span className="underline">Indemnification</span>.
      </p>
      <p className="mb-3">
        (a) The Pledgor hereby agrees to indemnify and hold harmless Broker, and
        each of their respective directors, officers, agents and employees
        against any and all losses, claims, causes of action, liabilities,
        lawsuits, demands and damages of any kind or nature, including without
        limitation, any and all court costs and reasonable attorney's fees and
        expenses (collectively, <span className="underline">"Losses"</span>), in
        any way related to or arising out of or in connection with this
        Agreement or any action taken or not taken by Broker pursuant hereto;
        provided that a party and its respective directors, officers, agents and
        employees shall not be entitled to indemnification hereunder to the
        extent of Losses that a court of competent jurisdiction has determined
        in a final nonappealable judgment resulted from for such party's own
        gross negligence or willful misconduct.
      </p>
      <p className="mb-3">
        (b) To the extent that the Pledgor has failed to satisfy its obligations
        under subsection (a) above, the Secured Party hereby agrees to indemnify
        and hold harmless Broker and each of their respective directors,
        officers, agents and employees against any and all Losses,{" "}
        <span className="underline">provided</span> that in the case of Losses
        arising prior to the delivery of a Notice of Exclusive Control to
        Broker, the Secured Party's indemnity obligations to Broker hereunder
        shall be limited to only those Losses which arise from Instructions
        issued to Broker by the Secured Party and,{" "}
        <span className="underline">provided</span>,
        <span className="underline">further</span>, that Broker shall be
        entitled to indemnification to the extent that such Losses are
        determined by a court of competent jurisdiction by final and
        nonappealable judgment to have resulted from its own gross negligence or
        willful misconduct.
      </p>
      <p className="mb-3">
        (c) The provisions of this <span className="underline">Section 13</span>{" "}
        shall survive the termination of this Agreement.
      </p>
      <p className="my-6">
        13. <span className="underline">Customer Agreement</span>. In the event
        of a conflict between this Agreement, the Brokerage Account Agreement,
        or any other agreement between Broker, on the one hand, and the Pledgor,
        on the other hand, affecting the Brokerage Account (each an{" "}
        <span className="underline">"Account Agreement"</span>), the terms of
        this Agreement will prevail. However, this Agreement will not interfere,
        negatively affect or otherwise obstruct or prevent Broker from raising
        any claim or defense against the Pledgor available to it under any
        Account Agreement or create any third party beneficiary rights under any
        Account Agreement in favor of the Secured Party.
      </p>
      <p className="my-6">
        14. <span className="underline">Termination</span>. Unless earlier
        terminated pursuant to this Section, this Agreement shall continue in
        effect until the Secured Party has delivered a Notice of Termination to
        each of Broker and Pledgor. Upon receipt of such Notice of Termination,
        the obligations of Broker to the Secured Party with respect to the
        operation and maintenance of the Broker Account shall terminate, and the
        Secured Party shall have no further right to originate Instructions
        concerning the Custody Account.
      </p>
      <p className="my-6">
        15. <span className="underline">Complete Agreement</span>. This
        Agreement and the instructions and notices required or permitted to be
        executed and delivered hereunder set forth the entire agreement of the
        parties with respect to the subject matter hereof, and subject to
        <span className="underline">Section 14</span> above supersede any prior
        agreement and contemporaneous oral agreements of the parties concerning
        its subject matter.
      </p>
      <p className="my-6">
        16. <span className="underline">Amendments</span>. No amendment,
        modification or termination of this Agreement (except as otherwise
        specified in <span className="underline">Section 15</span> above), nor
        any assignment of any rights hereunder (except to the extent
        contemplated by <span className="underline">Section 19</span> below),
        shall be binding on any party hereto unless it is in writing and is
        signed by each of the parties hereto, and any attempt to so amend,
        modify, terminate or assign except pursuant to such a writing shall be
        null and void. No waiver of any rights hereunder shall be binding on any
        party hereto unless such waiver is in writing and signed by the party
        against whom the enforcement is sought.
      </p>
      <p className="my-6">
        17. <span className="underline">Severability</span>. If any term or
        provision set forth in this Agreement shall be invalid or unenforceable,
        the remainder of this Agreement, other than the term or provision held
        invalid or unenforceable, shall be construed in all respects as if such
        invalid or unenforceable term or provision were omitted.
      </p>
      <p className="my-6">
        18. <span className="underline">Successors</span>. The terms of this
        Agreement shall be binding upon, and shall inure to the benefit of, the
        parties hereto and their respective corporate successors or assigns.
        This Agreement may be assigned by the Secured Party to any successor of
        the Secured Party under its Financing Agreements with the Pledgor,{" "}
        <span className="underline">provided</span> that written notice thereof
        is given by the Secured Party to Broker and the Pledgor in accordance
        with <span className="underline">Section 19</span> of this Agreement.
      </p>
      <p className="my-6">
        19. <span className="underline">Notices</span>. All notices (except a
        Notice of Exclusive Control and Notice of Termination which shall be
        delivered to Broker by Build Whale, Inc. solely for administrative
        convenience at the addresses set out on{" "}
        <span className="underline">Exhibit A and Exhibit B</span>,
        respectively) shall be delivered to the parties as follows:
      </p>
      <p className="my-6">
        (a) if to Broker, to it at:
        <br />
        <br />
        ALPACA SECURITIES LLC
        <br />
        42881 LAKE BABCOCK DR.
        <br />
        SUITE 200
        <br />
        BABCOCK RANCH, FL 33982 USA
        <br />
        Attn: Broker Support
        <br />
        Tel: 941-231-4093
        <br />
        Email: brokersupport@alpaca.markets
      </p>
      <p className="my-6">
        (b) if to the Pledgor, to it at:
        <br />
        <br />
        {`${meta?.legalFirstName} ${meta?.legalLastName}`}
        <br />
        {meta?.kycAddress?.thoroughfare}
        <br />
        {meta?.kycAddress?.premise && (
          <>
            {meta?.kycAddress?.premise} <br />
          </>
        )}
        {meta?.kycAddress?.locality}, {meta?.kycAddress?.administrativeArea}{" "}
        {meta?.kycAddress?.postalCode}
        <br />
        Tel: {meta?.phoneNumber}
        <br />
        Email: {meta?.email}
      </p>
      <p className="my-6">
        (c) if to the Secured Party, to it at:
        <br />
        <br />
        {meta?.propertyLegalName}
        <br />
        {meta?.propertyAddress?.thoroughfare}
        <br />
        {meta?.propertyAddress?.premise && (
          <>
            {meta?.propertyAddress?.premise} <br />
          </>
        )}
        {meta?.propertyAddress?.locality},{" "}
        {meta?.propertyAddress?.administrativeArea}{" "}
        {meta?.propertyAddress?.postalCode}
        <br />
        Attn: Finance / Accounting Department
        <br />
        Tel: {meta?.propertyPhoneNumber}
        <br />
        Email: {meta?.propertyEmail}
      </p>
      <p className="mb-3">
        Except as otherwise expressly provided herein, any notice, order,
        instruction, request, or other communication required or permitted to be
        given hereunder shall be in writing and deemed to have been properly
        given when delivered in person, or when sent by telecopy or other
        electronic means and electronic confirmation of error free receipt is
        received or upon receipt of notice sent by certified or registered
        United States mail, return receipt requested, postage prepaid,
        addressed, as set out in this{" "}
        <span className="underline">Section 20</span>. Any party may change its
        address for notices in the manner set forth above.
      </p>
      <p className="my-6">
        20. <span className="underline">Counterparts</span>. This Agreement may
        be executed in any number of counterparts, all of which shall constitute
        one and the same instrument, and any party hereto may execute this
        Agreement by signing and delivering one or more counterparts. Delivery
        by telecopy, electronic transmission or portable document format (.pdf)
        of an executed counterpart of a signature page to this Agreement shall
        be effective as delivery of an original executed counterpart hereof.
      </p>
      <p className="my-6 font-semibold">
        21.{" "}
        <span className="underline">
          Governing Law and Waiver of Jury Trial
        </span>
        . This Agreement shall be governed by, and construed in accordance with,
        federal law and, to the extent not preempted by federal law, the
        internal laws of the State of Delaware. To the extent permitted by
        applicable law, each party waives all rights to trial by jury in any
        action, claim or proceeding (including any counterclaim) of any type
        arising out of or directly or indirectly relating to this Agreement.
      </p>
      <p className="my-6">
        22. <span className="underline">Force Majeure</span>. Notwithstanding
        any other provision in this Agreement to the contrary, Broker shall not
        be liable for any failure to perform, or delay in performance hereunder,
        if (i) such failure, inability or delay is due to acts of god,
        terrorism, war, civil commotion, governmental action, fire, explosion,
        strikes, other industrial disturbances, equipment malfunction, action,
        nonaction, or delayed action on the part of the Pledgor, the Secured
        Party, or any other entity, or any other events or circumstances that
        are beyond the reasonable control of Broker, or (ii) such failure or
        delay resulted from Broker's reasonable belief that the action would
        violate any law, order, decree, guideline, rule or regulation of any
        governmental authority or court.
      </p>
      <p className="my-6">
        23. <span className="underline">USA PATRIOT ACT NOTICE</span>. Pledgor
        and Secured Party hereby acknowledge that Broker is subject to federal
        laws, including the Customer Identification Program (CIP) requirements
        under the USA PATRIOT Act and its implementing regulations, pursuant to
        which Broker must obtain, verify and record information that allow them
        to identify each of Pledgor and Secured Party. Accordingly, prior to
        opening an account, Broker may ask Pledgor and/or Secured Party to
        provide certain information including, but not limited to, Pledgor's
        and/or Secured Party's name, physical address, tax identification number
        and other information that will help Broker to identify and verify each
        of Pledgor's and Secured Party's identity such as organizational
        documents, certificate of good standing, license to do business or other
        pertinent identifying information.
      </p>
      <p className="mb-3">
        IN WITNESS WHEREOF, the parties hereto have executed or caused this
        Deposit Account Control Agreement to be executed by their respective
        officers thereunto duly authorized, as of the date written above and it
        shall take effect as a sealed instrument.
      </p>
      <p className="mb-1 font-semibold uppercase">{`${meta?.legalFirstName} ${meta?.legalLastName}`}</p>
      <p>
        By:{" "}
        <span className="underline homemade-apple-regular">{`${meta?.legalFirstName} ${meta?.legalLastName}`}</span>
      </p>
      <p className="mt-5 mb-1 font-semibold uppercase">
        {meta?.propertyLegalName}
      </p>
      <p>
        By:{" "}
        <span className="underline homemade-apple-regular">
          {meta?.propertyLegalName}
        </span>
      </p>
      <p className="mt-5 mb-1 font-semibold uppercase">Alpaca Securities LLC</p>
      <p>
        By: <span className="underline homemade-apple-regular">Tony Lee</span>
      </p>
      <p className="text-xs">Name: Tony Lee</p>
      <p className="text-xs">Title: President</p>
      <p className="mt-5 mb-1 font-semibold uppercase">Build Whale, Inc.</p>
      <p>
        By:{" "}
        <span className="underline homemade-apple-regular">
          Arthur J. Petraglia
        </span>
      </p>
      <p className="text-xs">Name: Arthur J. Petraglia</p>
      <p className="text-xs">Title: CEO</p>
    </div>
  ) : (
    <BaseLoadingScreen message={loadingMessage} />
  );
};
