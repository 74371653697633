import { OnboardingFinancialPep } from "./OnboardingFinancialPep";

type Props = {
  onSelect: (isStockExchangeEmployee: boolean) => void;
};

const QUESTION =
  "Do you or a family member work for a stock exchange or brokerage?";

const WARNING = `By selecting "yes", you will be required to email Whale a letter written and signed by a compliance officer on company letterhead explicitly granting permission for you to own the account.`;

export const OnboardingFinancialStockExchangeEmployee = ({
  onSelect,
}: Props) => {
  return (
    <OnboardingFinancialPep
      question={QUESTION}
      warning={WARNING}
      onSelect={onSelect}
    />
  );
};
