import { useCallback, useState } from "react";
import { Cake } from "@phosphor-icons/react";
import { DateObjectType } from "../../components/date-input/DateInputHelpers";
import { getBirthDateError } from "../shared/onboardingDateHelpers";
import DateInput from "../../components/date-input/DateInput";

type Props = {
  isoDate?: string;
  onChange: (data: { isValid: boolean; date: string | null }) => void;
};

export const OnboardingDateOfBirth = ({ isoDate = "", onChange }: Props) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = useCallback(
    ({ day, month, year }: DateObjectType) => {
      const hasMonth = month.length === 2;
      const hasDay = day.length === 2;
      const hasYear = year.length === 4;
      const error = getBirthDateError({ day, month, year });

      if (hasMonth && hasDay && hasYear) {
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        onChange({ isValid: !error, date: date.toISOString() });
      } else {
        onChange({ isValid: false, date: null });
      }

      setErrorMessage(error);
    },
    [onChange]
  );

  return (
    <div className="w-full h-full max-w-xl m-auto p-5 flex items-center justify-center">
      <DateInput
        datePlaceholderText="Date of Birth"
        errorMessage={errorMessage}
        icon={Cake}
        initialIsoValue={isoDate}
        name="Date of Birth"
        onChange={handleChange}
      />
    </div>
  );
};
