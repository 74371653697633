import { Waves } from "@phosphor-icons/react";
import { WebsiteCompareTable } from "./WebsiteCompareTable";

export const WebsiteComparison = () => (
  <div className="plant-background sm:text-white mb-32 py-32 px-10 md:px-16 lg:px-20 xl:px-28 grid grid-rows-1 grid-cols-1 xl:grid-cols-3">
    <div className="mb-10">
      <Waves className="hidden sm:flex sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-32 lg:h-32 xl:w-24 xl:h-24 2xl:w-32 2xl:h-32" weight="thin" />
      <h3 className="text-5xl sm:text-7xl md:text-7xl lg:text-8xl xl:text-7xl 2xl:text-8xl font-light md:font-normal">
        We're different.
      </h3>
    </div>
    <div className="flex justify-center md:col-span-2">
      <WebsiteCompareTable />
    </div>
  </div>
);
