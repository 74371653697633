import { Lock } from "@phosphor-icons/react";

export type DepositType = {
  amount: number;
  status: string;
  damages: number | null;
  address: string;
  propertyMarketingName: string;
};

type Props = {
  deposits: DepositType[];
};

export const AccountRentalDepositsTable = ({ deposits }: Props) => {
  const circleColors: { [status: string]: string } = {
    RELEASED: "text-[#4ABA6A]",
    ACTIVE: "text-[#3780ED]",
  };

  return (
    <>
      {deposits.map(({ address, status }, index) => (
        <div
          className={`flex justify-between items-center py-4 ${
            index === deposits.length - 1 ? "border-b-0" : "border-b-1"
          }`}
        >
          <div className="flex items-center">
            <Lock size={32} />
            <div className="ml-2">
              <p className="outfit">{address}</p>
              <p className="text-xl">
                <span className={circleColors[status.toUpperCase()] || ""}>
                  ●
                </span>
                <span className="ml-1 text-sm font-light">Locked</span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
