import { API_URL } from "../../constants";
import { CreateInvestmentAccountType, InvestorType } from "../onboardingTypes";

const FETCH_META_URL = `${API_URL}/investor/onboardMetaData`;
const UPDATE_META_URL = `${API_URL}/investor/meta`;
const CREATE_URL = `${API_URL}/investor/createInvestmentAccount`;
const FETCH_AGREEMENTS_URL = `${API_URL}/investor/getAgreements`;
const FETCH_AGREEMENT_META_URL = `${API_URL}/investor/agreementMeta`;
const FETCH_PLAID_LINK_TOKEN_URL = `${API_URL}/investor/plaidLinkToken`;
const SUBMIT_PLAID_PUBLIC_TOKEN_URL = `${API_URL}/investor/submitPublicToken`;
const CREATE_ACH_RELATIONSHIP_AND_TRANSFER_FUNDS_URL = `${API_URL}/investor/createAchRelationshipAndTransferFunds`;

export const fetchInvestorMeta = async () => {
  const response = await fetch(`${FETCH_META_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchInvestorMeta");
  }
};

export const updateInvestorMeta = async (data: InvestorType) => {
  const response = await fetch(UPDATE_META_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "PUT",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in updateInvestorMeta");
  }
};

export const createInvestmentAccount = async (
  data: CreateInvestmentAccountType
) => {
  const response = await fetch(CREATE_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in createInvestmentAccount");
  }
};

export const fetchInvestorAgreements = async () => {
  const response = await fetch(`${FETCH_AGREEMENTS_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchInvestorAgreements");
  }
};

export const fetchInvestorAgreementMeta = async () => {
  const response = await fetch(`${FETCH_AGREEMENT_META_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchInvestorAgreementMeta");
  }
};

export const fetchPlaidLinkToken = async () => {
  const response = await fetch(`${FETCH_PLAID_LINK_TOKEN_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchPlaidLinkToken");
  }
};

export const submitPlaidPublicToken = async (data: { publicToken: string }) => {
  const response = await fetch(SUBMIT_PLAID_PUBLIC_TOKEN_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in submitPlaidPublicToken");
  }
};


export const createAchRelationshipAndTransferFunds = async () => {
  const response = await fetch(CREATE_ACH_RELATIONSHIP_AND_TRANSFER_FUNDS_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in createAchRelationshipAndTransferFunds");
  }
};
