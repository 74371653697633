import SocialSecurityInput from "../../components/social-security-input/SocialSecurityInput";

type Props = {
  initialConfirmationValue: string;
  initialValue: string;
  onChange: (value: string) => void;
  onChangeConfirm: (value: string) => void;
  showConfirm: boolean;
};

export const OnboardingFinancialSocialSecurity = ({
  initialConfirmationValue,
  initialValue,
  onChange,
  onChangeConfirm,
  showConfirm,
}: Props) => {
  return (
    <div className="w-full h-full max-w-xl m-auto p-5 flex items-center justify-center">
      <div>
        <h1 className="text-3xl mb-2">SSN or ITIN</h1>
        <p className="mb-4 font-light text-griselda">
          Required for identity verification
        </p>
        <SocialSecurityInput initialValue={initialValue} onChange={onChange} />
        {showConfirm && (
          <div className="mt-16">
            <p className="mb-4 font-light text-griselda">
              Confirm your SSN or ITIN
            </p>
            <SocialSecurityInput
              initialValue={initialConfirmationValue}
              onChange={onChangeConfirm}
            />
          </div>
        )}
      </div>
    </div>
  );
};
