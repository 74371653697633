import { ReactNode, useEffect, useState } from "react";
import { Navbar, NavbarBrand } from "@nextui-org/react";
import { BaseLoadingScreen } from "../BaseComponents";
import { WhaleLogo } from "../helpers/Svgs";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import { NavLink } from "react-router-dom";

type LoginLayoutPageProps = {
  children: ReactNode;
  isLoading?: boolean;
};

export const LoginLayoutPage = ({
  children,
  isLoading = false,
}: LoginLayoutPageProps) => {
  useEffect(() => {
    document.title = "Whale | Login";
  }, []);

  return isLoading ? (
    <BaseLoadingScreen message="Secure Sign-In" />
  ) : (
    <>
      <Navbar
        height="80px"
        isBlurred={false}
        isBordered
        maxWidth="full"
        position="static"
      >
        <NavbarBrand>
          <NavLink to="/">
            <WhaleLogo />
          </NavLink>
        </NavbarBrand>
      </Navbar>
      <div className="w-full h-full max-w-xl m-auto p-5">
        <div className="h-full flex flex-col items-center justify-center">
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

type LoginLayoutEmailSentProps = {
  message: string | ReactNode;
  onResend: () => void;
};

export const LoginLayoutEmailSent = ({
  message,
  onResend,
}: LoginLayoutEmailSentProps) => {
  const [hasResent, setHasResent] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClickButton = () => {
    setHasResent(true);
    onResend();
  };

  return (
    <>
      <h1 className="text-3xl sm:text-4xl mb-3">Check your email!</h1>
      <p className="text-griselda font-light mb-3 text-lg">{message}</p>
      {hasResent ? (
        <p className="font-semibold text-lg flex items-center">
          <PaperPlaneTilt size={24} className="mr-1" /> Sent!
        </p>
      ) : (
        <>
          {timer < 1 ? (
            <button
              className="underline font-semibold text-lg"
              onClick={handleClickButton}
            >
              Resend link
            </button>
          ) : (
            <p className="font-semibold text-lg text-griselda">
              Haven't received it? You can resend again in {timer} seconds
            </p>
          )}
        </>
      )}
    </>
  );
};
