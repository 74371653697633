import { useEffect, useState } from "react";
import { AgreementType } from "../onboardingTypes";
import { BaseButton, BaseLoadingScreen } from "../../BaseComponents";
import { fetchInvestorAgreements } from "../shared/onboardingApi";
import { FileText } from "@phosphor-icons/react";
import { useOnboardingContext } from "../shared/OnboardingContext";

const DYNAMIC_AGREEMENTS = [
  "SECURITIES_AND_ACCOUNT_CONTROL_AGREEMENT",
  "INVESTMENT_ADVISORY_AGREEMENT",
  "LEASE_ADDENDUM",
  "ALPACA_CUSTOMER_AGREEMENT",
];

type Props = {
  isSaving: boolean;
  onAgree: (agreements: AgreementType[]) => void;
};

export const OnboardingFinancialTerms = ({ isSaving, onAgree }: Props) => {
  const [agreements, setAgreements] = useState<AgreementType[]>([]);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const { isOrganic } = useOnboardingContext();

  useEffect(() => {
    fetchInvestorAgreements()
      .then((data: { agreements: AgreementType[] }) => {
        setAgreements(data.agreements);
      })
      .catch(() => {
        setLoadingMessage("We are having trouble, please try again later.");
      });
  }, []);

  const handlePressAgree = () => {
    onAgree(agreements);
  };

  const staticAgreements = agreements.filter(
    (agreement: AgreementType) => !DYNAMIC_AGREEMENTS.includes(agreement.nameId)
  );

  return (
    <div className="w-full h-full max-w-xl m-auto p-5 flex items-center justify-center">
      {staticAgreements.length > 0 ? (
        <div>
          <h1 className="text-3xl xl:text-5xl mb-6">Terms & Conditions</h1>
          <p className="text-midnight font-light">
            By tapping or clicking the "Agree" button below, you agree that you
            accept electronic delivery of the following documents, and that you
            have{" "}
            <span className="font-semibold">carefully reviewed and agree</span>{" "}
            to the terms of each and will retain copies for your records:
          </p>
          <ul className="my-5 switzer font-light text-midnight">
            <LinkElement
              link="/join/investment-advisory-agreement"
              name="Investment Advisory Agreement"
            />
            <LinkElement
              link="/join/securities-account-control-agreement"
              name="Securities Account Control Agreement"
            />
            {!isOrganic && (
              <LinkElement link="/join/lease-addendum" name="Lease Addendum" />
            )}
            <LinkElement
              link="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf"
              name="Alpaca Customer Agreement"
            />
            {staticAgreements.map(({ link, name }) => (
              <LinkElement link={link} name={name} key={name} />
            ))}
          </ul>
          <BaseButton
            className="w-full my-4"
            isLoading={isSaving}
            onPress={handlePressAgree}
          >
            Agree & submit
          </BaseButton>
        </div>
      ) : (
        <BaseLoadingScreen message={loadingMessage} />
      )}
    </div>
  );
};

const LinkElement = ({ link, name }: { link: string; name: string }) => (
  <li className="flex items-center mb-5">
    <FileText size={32} />
    <a className="underline ml-2" href={link} target="_blank" rel="noreferrer">
      {name}
    </a>
  </li>
);
