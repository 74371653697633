import { Button, Input, Navbar, NavbarBrand } from "@nextui-org/react";
import { WhaleLogo } from "../helpers/Svgs";
import { BaseButton } from "../BaseComponents";
import { useState } from "react";
import { AccountPlaid } from "./AccountPlaid";
import { ArrowLeft, CheckCircle } from "@phosphor-icons/react";
import { NavLink, useLoaderData } from "react-router-dom";
import { USD_NUMBER_FORMAT } from "../constants";
import { initiateWithdrawal } from "./accountApi";
import { AccountMfa } from "./AccountMfa";

type LoaderDataType = {
  investor: {
    firstName: string;
    lastName: string;
  };
  depositTotalFunds: number;
  totalInterestEarned: number;
  totalActiveDepositObligation: number;
  depositObligations: Array<{
    amount: number;
    status: string;
    damages: number | null;
    address: string;
    propertyMarketingName: string;
  }>;
  transactions: Array<{
    date: string;
    amount: number;
    status: string;
  }>;
};

export const AccountWithdraw = () => {
  const [hasVerified, setHasVerified] = useState(false);
  const [showPlaid, setShowPlaid] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { depositTotalFunds, totalActiveDepositObligation } =
    useLoaderData() as LoaderDataType;

  const availableToWithdraw = depositTotalFunds - totalActiveDepositObligation;

  const enableButton =
    inputValue.match(/^\d+\.\d{0,2}$/) &&
    Number(inputValue) <= availableToWithdraw;

  const handleSubmit = () => {
    initiateWithdrawal({ withdrawAmount: Number(inputValue) })
      .then(() => {
        setShowPlaid(false);
        setHasSubmitted(true);
      })
      .catch(() => {
        console.log("There was an error initiating the transfer");
      });
  };

  if (!hasVerified) {
    return <AccountMfa onSuccess={() => setHasVerified(true)} />;
  }

  return (
    <>
      <Navbar
        height="80px"
        isBlurred={false}
        isBordered
        maxWidth="full"
        position="static"
      >
        {showPlaid && (
          <Button
            aria-label="Back"
            className="min-w-12"
            isIconOnly
            onPress={() => setShowPlaid(false)}
            radius="md"
            variant="light"
          >
            <ArrowLeft size={24} />
          </Button>
        )}
        <NavbarBrand className="justify-center">
          <WhaleLogo />
        </NavbarBrand>
        {showPlaid && <div className="min-w-12" />}
      </Navbar>
      <div className="grow flex flex-col items-center justify-center">
        {hasSubmitted ? (
          <div className="p-10 flex flex-col items-center">
            <CheckCircle size={48} />
            <h1 className="text-3xl my-4">Transfer Initiated</h1>
            <p className="text-griselda font-light mb-4">
              Your transfer is expected to take 1-3 business days. Look out for
              an email with an update!
            </p>
            <BaseButton as={NavLink} to="/account/overview">
              Back to Overview
            </BaseButton>
          </div>
        ) : (
          <>
            {!showPlaid && (
              <>
                <Input
                  classNames={{
                    base: "mt-16 text-center max-w-96",
                    innerWrapper: "font-light text-3xl",
                    label:
                      "tracking-widest text-griselda font-semibold mb-16 uppercase",
                    input:
                      "text-sage font-light text-3xl placeholder:text-strokeGrey",
                  }}
                  label="Enter Amount"
                  placeholder="0.00"
                  variant="underlined"
                  startContent="$"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  value={inputValue}
                  onValueChange={setInputValue}
                />
                <p className="font-light text-griselda mt-4">
                  You have {USD_NUMBER_FORMAT.format(availableToWithdraw)}{" "}
                  available.
                </p>
                <BaseButton
                  className="mt-4 w-full max-w-96"
                  onPress={() => setShowPlaid(true)}
                  isDisabled={!enableButton}
                >
                  Continue
                </BaseButton>
              </>
            )}
            {showPlaid && (
              <AccountPlaid
                withdrawAmount={Number(inputValue)}
                onSuccessfulLink={() => {}}
                onSubmit={handleSubmit}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
