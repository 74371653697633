import { API_URL } from "../constants";

const FETCH_FOOTER_DOCUMENTS_URL = `${API_URL}/public/footerDocuments`;

export const fetchFooterDocuments = async () => {
  const response = await fetch(`${FETCH_FOOTER_DOCUMENTS_URL}`);
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchFooterDocuments");
  }
};
