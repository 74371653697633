import {
  ChangeEvent,
  ElementType,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import InputWithLabel from "../input-with-label/InputWithLabel";
import { getFormattedDate } from "./DateInputHelpers";
import { Tooltip } from "@nextui-org/react";

const numberRegex = new RegExp("^[0-9]+$");

type DateFormatType = "mm/dd/yyyy" | "dd/mm/yyyy";
type EventType = ChangeEvent<HTMLInputElement>;

type Props = {
  datePlaceholderText: string;
  errorMessage?: string;
  format?: DateFormatType;
  icon: ElementType;
  initialIsoValue?: string;
  name: string;
  onChange: (date: { month: string; day: string; year: string }) => void;
};

const DateInput = ({
  datePlaceholderText,
  errorMessage = "",
  format = "mm/dd/yyyy",
  icon: Icon,
  initialIsoValue = "",
  name,
  onChange,
}: Props) => {
  const initialValue = initialIsoValue
    ? {
        month: (new Date(initialIsoValue).getMonth() + 1)
          .toString()
          .padStart(2, "0"),
        day: new Date(initialIsoValue).getDate().toString().padStart(2, "0"),
        year: new Date(initialIsoValue).getFullYear().toString(),
      }
    : { month: "", day: "", year: "" };

  const [month, setMonth] = useState(initialValue.month);
  const [day, setDay] = useState(initialValue.day);
  const [year, setYear] = useState(initialValue.year);

  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const yearRef = useRef(null);

  useEffect(() => {
    onChange({ month, day, year });
  }, [month, day, year, onChange]);

  const inputData = {
    MONTH: {
      label: "Month",
      maxValue: "12",
      minValue: "01",
      placeholder: "MM",
      ref: monthRef,
      setFunc: setMonth,
      value: month,
    },
    DAY: {
      label: "Day",
      maxValue: "31",
      minValue: "01",
      placeholder: "DD",
      ref: dayRef,
      setFunc: setDay,
      value: day,
    },
    YEAR: {
      label: "Year",
      maxValue: (new Date().getFullYear() + 100).toString(),
      minValue: (new Date().getFullYear() - 100).toString(),
      placeholder: "YYYY",
      ref: yearRef,
      setFunc: setYear,
      value: year,
    },
  };

  const inputs =
    format === "dd/mm/yyyy"
      ? [inputData["DAY"], inputData["MONTH"], inputData["YEAR"]]
      : [inputData["MONTH"], inputData["DAY"], inputData["YEAR"]];

  const handleBlur = (
    minValue: string,
    setValue: (value: string) => void,
    value: string
  ) => {
    if (value === "") {
      setValue(value);
    } else if (Number(value) <= Number(minValue)) {
      setValue(minValue);
    } else if (value.length !== minValue.length) {
      setValue(value.padStart(minValue.length, "0"));
    }
  };

  const handleChange = (
    maxValue: string,
    nextRef: RefObject<HTMLInputElement>,
    setValue: (value: string) => void,
    value: string
  ) => {
    if (value.length > 0 && !numberRegex.test(value)) {
      return;
    }

    const maxFirstDigit = Number(maxValue[0]);
    const numberValue = Number(value);
    const stringLength = value.length;
    const totalDigits = maxValue.length;

    if (stringLength >= totalDigits) {
      setValue(numberValue > Number(maxValue) ? maxValue : value);
      nextRef?.current?.focus();
      return;
    }

    if (stringLength === 1 && numberValue > maxFirstDigit) {
      setValue(value.padStart(totalDigits, "0"));
      nextRef?.current?.focus();
      return;
    }

    setValue(value);
  };

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.select();

  const formattedDate = getFormattedDate(month, day, year);

  return (
    <div className="w-full">
      <div className="flex items-center mb-14">
        <Icon size={56} className="mr-4" color="#5C6D6B" weight="duotone" />
        <div>
          <h2 className="my-1 text-small font-semibold uppercase tracking-widest text-griselda">
            {name}
          </h2>
          {formattedDate.length > 0 ? (
            <h3 className="text-midnight text-2xl sm:text-4xl">
              {formattedDate}
            </h3>
          ) : (
            <h3 className="text-midSage text-2xl sm:text-4xl">
              {datePlaceholderText}
            </h3>
          )}
        </div>
      </div>
      <Tooltip
        classNames={{
          content: ["bg-griselda", "text-white"],
          base: ["before:bg-griselda"],
        }}
        content={<p className="mx-5 my-3">{errorMessage}</p>}
        isOpen={errorMessage.length > 0}
        offset={30}
        placement="bottom"
        showArrow
      >
        <div className="flex justify-between">
          {inputs.map(
            (
              { label, setFunc, minValue, maxValue, ref, placeholder, value },
              index
            ) => {
              const nextRef = inputs[index + 1]?.ref;
              return (
                <InputWithLabel
                  className="px-3 w-1/3"
                  inputMode="numeric"
                  key={label}
                  label={label}
                  onBlur={(e: EventType) =>
                    handleBlur(minValue, setFunc, e.target.value)
                  }
                  onChange={(e: EventType) =>
                    handleChange(maxValue, nextRef, setFunc, e.target.value)
                  }
                  onClick={handleSelect}
                  onFocus={handleSelect}
                  pattern="[0-9]*"
                  placeholder={placeholder}
                  ref={ref}
                  type="text"
                  value={value}
                />
              );
            }
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default DateInput;
