import { Input } from "@nextui-org/react";
import { useOnboardingContext } from "../shared/OnboardingContext";

type Props = {
  onChangeEmployerAddress: (value: string) => void;
  onChangeEmployerName: (value: string) => void;
  onChangeOccupation: (value: string) => void;
};

export const OnboardingFinancialPrimaryEmployer = ({
  onChangeEmployerAddress,
  onChangeEmployerName,
  onChangeOccupation,
}: Props) => {
  const {
    investorLocal: { employerAddress, jobTitle, nameOfEmployer },
  } = useOnboardingContext();
  return (
    <div className="w-full h-full sm:flex sm:items-center sm:justify-center">
      <div className="w-full max-w-xl p-5">
        <div>
          <h1 className="text-3xl mb-8">Primary Employer</h1>
          <Input
            classNames={{
              base: "mt-5",
              label: "tracking-widest text-griselda font-semibold mb-2",
              input: "text-sage font-light text-md",
            }}
            defaultValue={nameOfEmployer}
            label="NAME OF EMPLOYER"
            onValueChange={onChangeEmployerName}
            placeholder="Type here"
            variant="underlined"
          />
          <Input
            classNames={{
              base: "mt-5",
              label: "tracking-widest text-griselda font-semibold mb-2",
              input: "text-sage font-light text-md",
            }}
            defaultValue={jobTitle}
            label="OCCUPATION"
            onValueChange={onChangeOccupation}
            placeholder="Type here"
            variant="underlined"
          />
          <Input
            classNames={{
              base: "mt-5",
              label: "tracking-widest text-griselda font-semibold mb-2",
              input: "text-sage font-light text-md",
            }}
            defaultValue={employerAddress}
            label="EMPLOYER ADDRESS"
            onValueChange={onChangeEmployerAddress}
            placeholder="Type here"
            variant="underlined"
          />
        </div>
      </div>
    </div>
  );
};
