import { useEffect, useState } from "react";
import { fetchJwt, validateJwt } from "./loginApi";
import { LoginLayoutEmailSent, LoginLayoutPage } from "./LoginLayout";
import { JwtTokenType } from "./loginTypes";

const noop = () => {};

const DEFAULT_PAGES: { [key: string]: string } = {
  DASHBOARD: "/account/overview",
  ONBOARDING: "/join",
};

export const LoginVerifyMagicLink = () => {
  const [isInvalid, setIsInvalid] = useState(false);

  const urlToken = new URLSearchParams(window.location.search).get("token");
  const existingJwt = localStorage.getItem("whaleAuth");

  const handleIsInvalid = () => {
    setIsInvalid(true);
  };

  const handleFetchJwt = () => {
    if (urlToken) {
      fetchJwt(urlToken)
        .then(({ token: { access_token: jwt } }: JwtTokenType) => {
          handleValidateJwt(jwt, handleIsInvalid);
        })
        .catch(handleIsInvalid);
    } else {
      window.location.href = "/";
    }
  };

  const handleValidateJwt = (jwt: string, onError: () => void) => {
    validateJwt(jwt)
      .then(({ defaultPage }) => {
        localStorage.setItem("whaleAuth", jwt);
        const redirect = DEFAULT_PAGES[defaultPage] || null;
        if (redirect) {
          window.location.href = redirect;
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    if (existingJwt) {
      handleValidateJwt(existingJwt, handleFetchJwt);
    } else {
      handleFetchJwt();
    }
    // eslint-disable-next-line
  }, []);

  const handleResend = () => {
    if (urlToken) {
      fetchJwt(urlToken).catch(noop);
    }
  };

  return (
    <LoginLayoutPage isLoading={!isInvalid}>
      <LoginLayoutEmailSent
        message="The sign-in link is expired. We just sent a new sign-in link to your email address."
        onResend={handleResend}
      />
    </LoginLayoutPage>
  );
};
