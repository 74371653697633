import { ReactNode } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import { Info, PencilSimple } from "@phosphor-icons/react";
import { BaseButton } from "../../BaseComponents";

const noop = () => {};

type Props = {
  infoPopover?: string;
  isEditable?: boolean;
  name: string;
  onClick?: () => void;
  value?: ReactNode;
};

const ConfirmInfoField = ({
  infoPopover = "",
  isEditable = true,
  name,
  onClick = noop,
  value,
}: Props) => {
  return (
    <div className="flex items-center justify-between">
      <div className="my-2">
        <div className="flex items-center">
          <h2 className="my-1 mr-1 text-small font-semibold uppercase tracking-widest text-griselda">
            {name}
          </h2>
          {infoPopover.length > 0 && (
            <Popover
              classNames={{
                content: ["bg-griselda", "text-white"],
                base: ["before:bg-griselda"],
              }}
              placement="bottom"
              showArrow
            >
              <PopoverTrigger>
                <button
                  className="flex size-5 items-center justify-center rounded-full fill-strokeGrey hover:fill-sage"
                  type="button"
                >
                  <Info size={20} color="" weight="fill" />
                </button>
              </PopoverTrigger>
              <PopoverContent>
                <p className="px-1 py-2 text-tiny">{infoPopover}</p>
              </PopoverContent>
            </Popover>
          )}
        </div>
        {value ? (
          <p className="my-1 text-dareDevil">{value}</p>
        ) : (
          <p className="my-1 text-darkGrey">Missing</p>
        )}
      </div>
      {isEditable && (
        <>
          {value ? (
            <button
              className="flex size-11 items-center justify-center rounded-full ml-4 bg-warmGrey text-griselda hover:bg-taupe"
              onClick={onClick}
              type="button"
            >
              <PencilSimple size={25} />
            </button>
          ) : (
            <BaseButton onPress={onClick}>Add</BaseButton>
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmInfoField;
