import {
  BellRinging,
  Lightning,
  LockKey,
  PaperPlaneRight,
  User,
} from "@phosphor-icons/react";

const HOW_IT_WORKS_STEPS = [
  {
    number: 1,
    description: "Resident opens Whale account and funds their rental deposit",
    icon: User,
  },
  {
    number: 2,
    description:
      "Whale locks the deposit and the resident starts earning interest",
    icon: LockKey,
  },
  {
    number: 3,
    description: "Whale notified of any charges when the resident moves out",
    icon: BellRinging,
  },
  {
    number: 4,
    description: "Whale sends move-out charges to the landlord",
    icon: PaperPlaneRight,
  },
  {
    number: 5,
    description:
      "Resident's remaining balance made immediately available in their Whale account",
    icon: Lightning,
  },
];

export const WebsitePropertiesHowItWorks = () => (
  <div className="mx-8 md:mx-16 lg:mx-20 xl:mx-28 mt-32 sm:mb-32">
    <h2 className="text-5xl sm:text-7xl md:text-8xl font-light">
      How it works
    </h2>
    <div>
      {HOW_IT_WORKS_STEPS.map(({ number, description, icon: Icon }) => {
        const className =
          number === HOW_IT_WORKS_STEPS.length
            ? "text-lg flex items-center py-8 border-b-2 border-transparent"
            : "text-lg flex items-center py-8 border-b-2 border-strokeGrey";
        return (
          <div key={number} className={className}>
            <Icon size={44} weight="duotone" className="fill-griselda min-w-11" />
            <p className="ml-3 mr-1">
              <span className="font-semibold">{number}.</span> {description}
            </p>
          </div>
        );
      })}
    </div>
  </div>
);
