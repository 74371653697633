import building from "../images/building.png";
import { WebsiteAnimateIn } from "./WebsiteAnimateIn";

export const WebsitePartnerships = () => (
  <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2 lg:ml-20 xl:ml-28 my-32">
    <div className="lg:order-last">
      <img src={building} alt="Apartment building with palm trees" />
    </div>
    <WebsiteAnimateIn className="w-fit flex flex-col justify-center mx-10 md:mx-16 lg:mx-0">
      <h3 className="tracking-tight font-light text-4xl md:text-6xl">
        Partnered with properties across the United States.
      </h3>
    </WebsiteAnimateIn>
  </div>
);
