import { API_URL } from "../constants";

const FETCH_JWT_URL = `${API_URL}/investor/magicLink`;
const VALIDATE_JWT_URL = `${API_URL}/investor/confirmJwtAuth`;
const NEW_MAGIC_LINK_URL = `${API_URL}/investor/newMagicLinkEmail`;

export const fetchJwt = async (token: string) => {
  const url = `${FETCH_JWT_URL}?token=${token}`;
  const response = await fetch(url, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in fetchJwt");
  }
};

export const validateJwt = async (jwtToken: string) => {
  const response = await fetch(VALIDATE_JWT_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in validateJwt");
  }
};

export const requestMagicLinkWithEmail = async (email: string) => {
  const response = await fetch(NEW_MAGIC_LINK_URL, {
    body: JSON.stringify({ email }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in requestMagicLinkWithEmail");
  }
};
