import { useEffect, useState } from "react";
import { BaseErrorMessage, BaseLoadingScreen } from "../BaseComponents";
import { fetchInvestorMeta, updateInvestorMeta } from "./shared/onboardingApi";
import { InvestorType, PropertyType, UnitType } from "./onboardingTypes";
import { OnboardingCitizenship } from "./citizenship/OnboardingCitizenship";
import { OnboardingContext } from "./shared/OnboardingContext";
import { OnboardingFinancial } from "./financial/OnboardingFinancial";
import { OnboardingPersonal } from "./personal/OnboardingPersonal";
import { OnboardingSubmission } from "./submission/OnboardingSubmission";
import { OnboardingWelcome } from "./welcome/OnboardingWelcome";
import {
  ORGANIC_DEPOSIT,
  SECTION_CITIZENSHIP,
  SECTION_FINANCIAL,
  SECTION_PERSONAL,
  SECTION_SUBMISSION,
  SECTION_WELCOME,
} from "./shared/onboardingConstants";

export const Onboarding = () => {
  const [section, setSection] = useState(SECTION_WELCOME);
  const [showError, setShowError] = useState(false);

  // Storage for investor data
  const [investorLocal, setInvestorLocal] = useState<InvestorType>();
  const [investorServer, setInvestorServer] = useState<InvestorType>();

  // Storage for property data
  const [propertyLocal, setPropertyLocal] = useState<PropertyType>();

  // Storage for unit data
  const [unitLocal, setUnitLocal] = useState<UnitType>();

  // Flag to determine if all required data is available
  const hasRequiredData = investorLocal && propertyLocal;

  // Flag to differentiate organic flow
  const isOrganic = investorServer?.onboardingType === ORGANIC_DEPOSIT;

  useEffect(() => {
    fetchInvestorMeta()
      .then(
        (response: {
          investor: InvestorType;
          property: PropertyType;
          unit: UnitType;
        }) => {
          const investor = response?.investor;
          const property = response?.property;
          const unit = response?.unit;

          // If we have the investor data, load the correct section and save the data
          if (investor) {
            setSection(investor.onboardingPage || SECTION_WELCOME);
            setInvestorServer(investor);
            setInvestorLocal(investor);
          }

          // If we have property data, save the data
          if (property) {
            setPropertyLocal(property);
          }

          // If we have unit data, save the data
          if (unit) {
            setUnitLocal(unit);
          }

          // If we are missing any data, show an error message
          if (!investor || !property || !unit) {
            setShowError(true);
          }
        }
      )
      .catch(() => {
        setShowError(true);
      });
  }, []);

  const updateInvestor = (newData: any, onSuccess: any, onFailure: any) => {
    const payload = { ...investorLocal, ...newData }; // TODO - we can pass only the newData to the endpoint but it needs to be tested
    updateInvestorMeta(payload)
      .then(() => {
        setInvestorLocal((prev) => ({ ...prev, ...newData }));
        onSuccess();
      })
      .catch(() => {
        setShowError(true);
        onFailure();
      });
  };

  return (
    <OnboardingContext.Provider
      value={{
        investorLocal,
        investorServer,
        isOrganic,
        propertyLocal,
        section,
        setSection,
        unitLocal,
        updateInvestor,
      }}
    >
      {hasRequiredData ? (
        <>
          {section.includes(SECTION_WELCOME) && <OnboardingWelcome />}
          {section.includes(SECTION_PERSONAL) && <OnboardingPersonal />}
          {section.includes(SECTION_CITIZENSHIP) && <OnboardingCitizenship />}
          {section.includes(SECTION_FINANCIAL) && <OnboardingFinancial />}
          {section.includes(SECTION_SUBMISSION) && <OnboardingSubmission />}
        </>
      ) : (
        <BaseLoadingScreen message="Loading" />
      )}
      <BaseErrorMessage
        isOpen={showError}
        onClose={() => setShowError(false)}
      />
    </OnboardingContext.Provider>
  );
};
