import jamieSignature from '../../images/jamie-signature.png';

export const WebsiteAboutInspiration = () => (
  <div className="grid grid-rows-1 grid-cols-1 lg:grid-cols-2 mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-24 lg:my-48">
    <h2 className="font-light text-5xl lg:text-6xl mb-8">The Inspiration Behind Whale</h2>
    <div className="text-griselda">
      <p className="mb-4">
        Whale emerged from a blend of personal experiences, mistakes, and a deep
        understanding of the financial challenges many people face today. With
        over 25 years of experience at the intersection of finance and real
        estate, I appreciate how complicated investing can be for the average
        person. The realization that small, consistent investments can build
        wealth was the catalyst for creating Whale: a platform that simplifies
        the investment process and makes it easy for anyone to get started.
      </p>
      <p className="mb-4">
        The initial spark for Whale came from my own frustrations with how
        traditional security deposits were handled. I wanted to see these funds,
        often a substantial portion of someone's net worth, put to work earning
        a return for the renter. Since this money was already locked away, it
        seemed a great starting point to get new investors started. This led to
        the creation of Whale Deposit, which transforms a renter's previously
        idle deposit into a cash earning, risk-free investment.
      </p>
      <p className="mb-4">
        But this was just the first step. We will make additional investment
        products that are accessible, understandable, and advantageous for our
        customers while helping them improve their financial literacy along the
        way.
      </p>
      <p className="mb-4">
        Innovation and social responsibility are fundamental to Whale's ethos.
        We will continue to evolve and improve our offerings based on feedback
        from our community so together we can revolutionize how everyday
        families improve their financial well-being.
      </p>
      <p className="mb-8">Thank you for being part of this journey with us!</p>
      <img src={jamieSignature} width="200" alt="Jamie Petraglia signature" />
      <p className="mb-4">Jamie Petraglia <br /> Founder CEO, Whale</p>
    </div>
  </div>
);
