import { useCallback, useEffect, useState } from "react";
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";
import { BaseButton, BaseErrorMessage } from "../../BaseComponents";
import { PlaidLogo } from "../../helpers/Svgs";
import { USD_NUMBER_FORMAT } from "../../constants";
import { useOnboardingContext } from "../shared/OnboardingContext";
import {
  fetchPlaidLinkToken,
  submitPlaidPublicToken,
} from "../shared/onboardingApi";

type Props = {
  onSuccessfulLink: () => void;
};

export const OnboardingFinancialBankAccount = ({ onSuccessfulLink }: Props) => {
  const [meta, setMeta] = useState<PlaidLinkOnSuccessMetadata>();
  const [linkToken, setLinkToken] = useState("");
  const [hasError, setHasError] = useState(false);
  const {
    investorServer: {
      onboardingDepositObligation: { depositPlusFeeAmount },
    },
  } = useOnboardingContext();

  const plaidAccount = meta?.accounts[0];

  const onSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      setMeta(metadata);
      submitPlaidPublicToken({ publicToken: public_token })
        .then(() => {
          onSuccessfulLink();
        })
        .catch(() => {
          setHasError(true);
          console.log("There was an error submitting the plaid public token");
        });
    },
    [onSuccessfulLink]
  );

  const { open, ready } = usePlaidLink({ token: linkToken, onSuccess });

  useEffect(() => {
    fetchPlaidLinkToken()
      .then((data: { linkToken: string }) => {
        setLinkToken(data.linkToken);
      })
      .catch(() => {
        setHasError(true);
        console.log("There was an error fetching the plaid link token");
      });
  }, []);

  return (
    <div className="w-full sm:h-full max-w-xl m-auto p-5 flex items-center justify-center">
      <div>
        {plaidAccount ? (
          <>
            <h1 className="text-3xl mb-4">Bank Account Linked</h1>
            <p className="font-light text-griselda">
              Whale will withdraw{" "}
              <span className="font-semibold">
                {USD_NUMBER_FORMAT.format(depositPlusFeeAmount)}
              </span>{" "}
              from this account to complete your rental deposit requirement.
            </p>
            <div className="p-3 bg-warmGrey rounded-lg border-solid border border-strokeGrey mt-5">
              <p className="font-semibold text-midnight">
                {meta?.institution?.name}
              </p>
              <p className="font-light text-griselda">
                Account ending in ***{plaidAccount.mask}
              </p>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-3xl mb-4">Link Your Bank Account</h1>
            <p className="font-light text-griselda">
              Whale will withdraw{" "}
              <span className="font-semibold">
                {USD_NUMBER_FORMAT.format(depositPlusFeeAmount)}
              </span>{" "}
              to complete your rental deposit requirement.
            </p>
            <BaseButton
              className="w-full my-5"
              isLoading={!ready}
              onPress={() => open()}
            >
              Connect Account
            </BaseButton>
            <p className="font-light text-griselda mt-4">
              Please make sure to choose an account with a balance greater than{" "}
              <span className="font-semibold">
                {USD_NUMBER_FORMAT.format(depositPlusFeeAmount)}
              </span>{" "}
              to avoid delays.
            </p>
            <div className="flex items-center">
              <p className="font-light text-griselda mr-2">Supported by</p>{" "}
              <PlaidLogo />
            </div>
          </>
        )}
      </div>
      <BaseErrorMessage isOpen={hasError} onClose={() => setHasError(false)} />
    </div>
  );
};
