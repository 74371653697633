import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
} from "@nextui-org/react";
import { WhaleTail } from "./helpers/Svgs";
import { Mailbox } from "@phosphor-icons/react";
import BottomSheet from "./components/bottom-sheet/BottomSheet";

export const BaseButton = ({ ...props }) => (
  <Button
    radius="md"
    size="lg"
    {...props}
    // The className prop must be kept below where props is being spread to avoid being overwritten
    className={`disabled:bg-warmGrey disabled:text-darkGrey text-dareDevil font-semibold switzer ${
      props.color ? "" : "bg-brightGreen"
    } ${props.className}`}
  >
    {props.children}
  </Button>
);

export const BaseRadioButton = ({ ...props }) => (
  <BaseButton
    color="default"
    variant="bordered"
    {...props}
    // The className prop must be kept below where props is being spread to avoid being overwritten
    className={`w-full ${props.className}`}
  >
    {props.children}
  </BaseButton>
);

export const BaseLoadingScreen = ({ message }: { message: string }) => (
  <div className="w-full h-full max-w-xl m-auto p-5 flex items-center justify-center">
    <div className="flex flex-col items-center">
      <WhaleTail />
      <h1 className="text-center mt-4 uppercase font-semibold text-midnight tracking-wider">
        {message}
      </h1>
    </div>
  </div>
);

const BaseErrorMessageContent = () => (
  <div className="text-center p-5">
    <div className="flex justify-center mb-6">
      <Mailbox className="fill-griselda" size={68} />
    </div>
    <h2 className="text-3xl sm:text-4xl font-medium">
      Oops! We're having some trouble with your request.
    </h2>
    <p className="font-light mt-4 mb-10">
      We encountered an issue while processing your request. Please try again or
      contact us.
    </p>
    <BaseButton as={Link} className="w-full" href="mailto:support@getwhale.co">
      Email Support
    </BaseButton>
  </div>
);

export const BaseErrorMessage = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      <BottomSheet className="sm:hidden" isOpen={isOpen} onClose={onClose}>
        <BaseErrorMessageContent />
      </BottomSheet>
      <Modal className="hidden sm:flex" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <BaseErrorMessageContent />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
