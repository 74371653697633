import { useEffect, useState } from "react";
import { fetchInvestorAgreementMeta } from "../shared/onboardingApi";
import { BaseLoadingScreen } from "../../BaseComponents";
import { AgreementMetaType } from "../onboardingTypes";
import { USD_NUMBER_FORMAT } from "../../constants";

const date = new Date().toLocaleString("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
});

export const LeaseAddendum = () => {
  const [loadingMessage, setLoadingMessage] = useState("");
  const [meta, setMeta] = useState<AgreementMetaType>();

  const residentName = `${meta?.legalFirstName} ${meta?.legalLastName}`;
  const unitAddress = meta?.unitAddress;
  const unitAddressFormatted = unitAddress?.premise
    ? `${unitAddress?.thoroughfare}, ${unitAddress?.premise}, ${unitAddress?.locality}, ${unitAddress?.administrativeArea} ${unitAddress?.postalCode}`
    : `${unitAddress?.thoroughfare}, ${unitAddress?.locality}, ${unitAddress?.administrativeArea} ${unitAddress?.postalCode}`;
  const leaseStartDate = meta?.leaseStartDate
    ? new Date(meta.leaseStartDate).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : null;

  useEffect(() => {
    fetchInvestorAgreementMeta()
      .then((data: AgreementMetaType) => {
        setMeta(data);
        setLoadingMessage("");
      })
      .catch(() => {
        console.log("There was an error loading the investor agreements");
        setLoadingMessage("We are having trouble, please try again later.");
      });
  }, []);

  return loadingMessage === "" ? (
    <div className="p-5 switzer">
      <h1 className="text-center font-semibold text-2xl mb-5">
        Transfer of Security Deposit to Investable Account Addendum
      </h1>
      <p className="mt-6 mb-3">
        This Transfer of Security Deposit to Investable Account Addendum
        ("Deposit Transfer Addendum") is attached to and made a part of the
        lease agreement {leaseStartDate ? "dated" : ""}{" "}
        <span className="font-semibold">{leaseStartDate}</span> (the "Lease") by
        and between{" "}
        <span className="font-semibold">{meta?.propertyLegalName}</span>, as
        agent for Owner ("Landlord"), and{" "}
        <span className="font-semibold">{residentName}</span> (individually and
        collectively referred to herein as "Resident") for the rental of the
        premises located at{" "}
        <span className="font-semibold">{unitAddressFormatted}</span> (the
        "Premises" or "Unit") within the community commonly known{" "}
        <span className="font-semibold">{meta?.propertyMarketingName}</span>{" "}
        (the "Community" or "Property"). All terms not specifically defined
        herein shall have the same definition as found in the Lease. Resident
        and Landlord agree as follows:
      </p>
      <p className="mb-3">
        In accordance with the Lease agreement, Resident was required to provide
        a security deposit to protect us from any damage or other losses that
        may occur during the time you lease dwelling. The security deposit on
        hand and currently held as written in the terms of the Lease is{" "}
        <span className="font-semibold">
          {USD_NUMBER_FORMAT.format(meta?.amount || 0)}
        </span>
        .
      </p>
      <p className="mb-3">
        By signing this Deposit Transfer Addendum, Resident has elected to
        convert your security deposit currently being held by Landlord to an
        investable account ("Investable Account") to be held by an alternative
        entity pursuant to a separate investment advisory agreement between you
        and Build Whale, Inc. You are authorizing Landlord to transfer currently
        held funds of{" "}
        <span className="font-semibold">
          {USD_NUMBER_FORMAT.format(meta?.amount || 0)}
        </span>{" "}
        to Build Whale, Inc. on your behalf and release Landlord from further
        liability and responsibility of security deposit funds.
      </p>
      <p className="mb-3">
        The investable account will be available to us for recovery of any
        amounts outstanding, including, but not limited to: damages, unpaid
        rents, unpaid balances, fines, fees, or other loss. Your agreement
        between you and Build Whale, Inc. will not be part of this lease
        agreement.
      </p>
      <p className="mb-3">
        You will have obligations to the Investable Account that are separate
        and independent from the duties you have to us under this Lease
        Contract. YOU WILL NOT BE RELEASED FROM YOUR OBLIGATIONS TO US, EXCEPT
        TO THE EXTENT THAT WE RECEIVE PAYMENTS FROM THE INVESTABLE ACCOUNT WHICH
        SATISFY YOUR OBLIGATIONS TO US. Specifically, if the Investable Account
        does not pay the total amount of damage or other loss that we
        experience, you will be required to pay us for the remaining amount.
      </p>
      <p className="mb-5 font-semibold">Resident or Residents</p>
      <p className="homemade-apple-regular border-b-1 border-black w-fit px-10 pb-2">
        {residentName}
      </p>
      <p className="my-5 font-semibold">Owner or Owner's Representative</p>
      <p className="homemade-apple-regular border-b-1 border-black w-fit px-10 pb-2">
        {meta?.propertyLegalName}
      </p>
      <p className="my-5 font-semibold">Date</p>
      <p className="font-mono border-b-1 border-black w-fit px-10 pb-2">
        {date}
      </p>
    </div>
  ) : (
    <BaseLoadingScreen message={loadingMessage} />
  );
};
