import { Lifebuoy, Lightning, Money } from "@phosphor-icons/react";
import iphone from "../images/iphone.png";
import { WebsiteAnimateIn } from "./WebsiteAnimateIn";

export const WebsiteValueProps = () => (
  <div className="grid gap-10 md:gap-20 grid-cols-1 md:grid-cols-3 grid-rows-1 mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-32">
    <div className="flex justify-center">
      <img
        src={iphone}
        alt="Whale dashboard on iPhone"
        className="w-96 min-w-72"
      />
    </div>
    <WebsiteAnimateIn className="w-fit md:col-span-2 flex flex-col justify-center">
      <h3 className="text-3xl md:text-6xl lg:text-7xl mb-5 md:mb-10 tracking-tight font-light">
        Keep your rental deposit in your own account
      </h3>
      <div className="flex items-center pb-5 mb-5 border-solid border-b-2 border-strokeGrey">
        <Money
          size={44}
          weight="duotone"
          className="fill-griselda text-lightGreen"
        />
        <p className="ml-4 text-midnight text-lg md:text-2xl font-light">
          Earn 3-4% interest*
        </p>
      </div>
      <div className="flex items-center pb-5 mb-5 border-solid border-b-2 border-strokeGrey">
        <Lightning
          size={44}
          weight="duotone"
          className="fill-griselda text-lightGreen"
        />
        <p className="ml-4 text-midnight text-lg md:text-2xl font-light">
          Immediately available after move-out
        </p>
      </div>
      <div className="flex items-center">
        <Lifebuoy
          size={44}
          weight="duotone"
          className="fill-griselda text-lightGreen"
        />
        <p className="ml-4 text-midnight text-lg md:text-2xl font-light">
          Held in cash so it grows completely risk-free
        </p>
      </div>
      <p className="font-light mt-10 text-midnight">
        *Depending on market conditions
      </p>
    </WebsiteAnimateIn>
  </div>
);
