const PROD_HOSTS = ["getwhale.co", "www.getwhale.co"];
const DEV_API_HOST = "devgetwhale.com";
const PROD_API_HOST = "getwhale.co";

export const API_URL = PROD_HOSTS.includes(window.location.host)
  ? `https://api.${PROD_API_HOST}` // PROD
  : `https://api.${DEV_API_HOST}`; // DEV

export const LOGO_URL = PROD_HOSTS.includes(window.location.host)
  ? `https://d28hxz1qokw3tc.cloudfront.net/imgs/logos` // PROD
  : `https://d3gor8lu8bdz3z.cloudfront.net/imgs/logos`; // DEV

export const USD_NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  currency: "USD",
  minimumFractionDigits: 2,
  style: "currency",
});
