import { useState } from "react";
import { OnboardingDateOfBirth } from "./OnboardingPersonalDateOfBirth";
import { OnboardingPersonalDetailsReview } from "./OnboardingPersonalReview";
import { OnboardingPersonalResidentialAddress } from "./OnboardingPersonalResidentialAddress";
import { OnboardingSectionTitle } from "../shared/OnboardingSectionTitle";
import { InvestorAddressType, InvestorType } from "../onboardingTypes";
import { useOnboardingContext } from "../shared/OnboardingContext";
import OnboardingNavbar from "../../components/onboarding-navbar/OnboardingNavbar";
import {
  PAGE_FIRST,
  PAGE_LAST,
  SECTION_CITIZENSHIP,
  SECTION_PERSONAL,
  SECTION_WELCOME,
} from "../shared/onboardingConstants";

const PAGE_ADDR = "PAGE_RESIDENTIAL_ADDRESS";
const PAGE_DOB = "PAGE_DATE_OF_BIRTH";
const PAGE_REVIEW = "PAGE_REVIEW";
const PAGE_TITLE = "PAGE_TITLE";

const getDefaultPage = (investorServer: InvestorType, section: string) => {
  const { onboardingPage, dateOfBirth, address } = investorServer;

  if (section === `${SECTION_PERSONAL}_${PAGE_FIRST}`) {
    const pages = getPages(investorServer);
    return pages[0];
  }
  if (section === `${SECTION_PERSONAL}_${PAGE_LAST}`) {
    const pages = getPages(investorServer);
    return pages[pages.length - 1];
  }
  if (onboardingPage === `${SECTION_PERSONAL}_${PAGE_DOB}` && dateOfBirth) {
    return PAGE_REVIEW;
  }
  if (onboardingPage === `${SECTION_PERSONAL}_${PAGE_ADDR}` && address) {
    return PAGE_REVIEW;
  }
  if (onboardingPage?.includes(SECTION_PERSONAL)) {
    return onboardingPage.replace(`${SECTION_PERSONAL}_`, "");
  }

  return PAGE_TITLE;
};

const getPages = (data: any) => {
  const pages = [PAGE_TITLE, PAGE_REVIEW];
  if (!data.dateOfBirth) {
    pages.push(PAGE_DOB);
  }
  if (!data.address) {
    pages.push(PAGE_ADDR);
  }
  return pages;
};

export const OnboardingPersonal = () => {
  const { investorLocal, investorServer, updateInvestor, section, setSection } =
    useOnboardingContext();

  // Local state
  const [page, setPage] = useState(getDefaultPage(investorServer, section));
  const [dateOfBirth, setDateOfBirth] = useState(investorServer.dateOfBirth);
  const [address, setAddress] = useState<InvestorAddressType | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Pages and progress
  const pages = getPages(investorServer);
  const numberOfPages = pages.length + 1;
  const progressPercentage = ((pages.indexOf(page) + 1) / numberOfPages) * 100;

  const handlePressBack = () => {
    const prevPage = pages[pages.indexOf(page) - 1];
    if (prevPage) {
      setPage(prevPage);
    } else {
      setSection(SECTION_WELCOME);
    }
  };

  const goToNextPage = () => {
    setIsSaving(false);
    const nextPage = pages[pages.indexOf(page) + 1];
    if (nextPage) {
      setPage(nextPage);
    } else {
      setSection(`${SECTION_CITIZENSHIP}_${PAGE_FIRST}`);
    }
  };

  const handleSaveFailure = () => {
    setIsSaving(false);
  };

  const handlePressContinue = () => {
    if (page === PAGE_DOB) {
      setIsSaving(true);
      updateInvestor(
        {
          dateOfBirth,
          onboardingPage: `${SECTION_PERSONAL}_${PAGE_DOB}`,
        },
        goToNextPage,
        handleSaveFailure
      );
    }
    if (page === PAGE_ADDR) {
      setIsSaving(true);
      updateInvestor(
        { address, onboardingPage: `${SECTION_PERSONAL}_${PAGE_ADDR}` },
        goToNextPage,
        handleSaveFailure
      );
    }
    goToNextPage();
  };

  const handleSelectAddress = (address: InvestorAddressType) => {
    setAddress(address);
  };

  const getShouldDisableContinue = () => {
    if (page === PAGE_ADDR && !address && !investorLocal?.address) {
      return true;
    }
    if (page === PAGE_DOB && !dateOfBirth) {
      return true;
    }
    return false;
  };

  const handleChangeDateOfBirth = ({ isValid, date }: any) => {
    setDateOfBirth(isValid ? date : null);
  };

  return (
    <>
      <OnboardingNavbar
        disableContinue={getShouldDisableContinue()}
        isLoading={isSaving}
        onPressBack={handlePressBack}
        onPressContinue={handlePressContinue}
        progressBarValue={progressPercentage}
        showBack
        showContinue={page !== PAGE_TITLE}
        showProgressBar={page !== PAGE_TITLE}
      />
      {page === PAGE_TITLE && (
        <OnboardingSectionTitle
          onPressContinue={handlePressContinue}
          sectionName="Personal Information"
          sectionNumber={1}
        />
      )}
      {page === PAGE_REVIEW && <OnboardingPersonalDetailsReview />}
      {page === PAGE_DOB && (
        <OnboardingDateOfBirth
          isoDate={investorLocal.dateOfBirth}
          onChange={handleChangeDateOfBirth}
        />
      )}
      {page === PAGE_ADDR && (
        <OnboardingPersonalResidentialAddress
          clearSelection={() => setAddress(null)}
          onSelect={handleSelectAddress}
          selected={address || investorLocal?.address || null}
        />
      )}
    </>
  );
};
