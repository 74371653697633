export type DateObjectType = {
  month: string;
  day: string;
  year: string;
};

export const MONTHS: { [monthNumber: string]: string } = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};

export const MAX_DAYS_IN_MONTH: { [monthNumber: string]: number } = {
  "01": 31,
  "02": 29,
  "03": 31,
  "04": 30,
  "05": 31,
  "06": 30,
  "07": 31,
  "08": 31,
  "09": 30,
  "10": 31,
  "11": 30,
  "12": 31,
};

export const getFormattedDate = (
  monthValue: string,
  dayValue: string,
  yearValue: string
): string => {
  const monthName = MONTHS[monthValue] || "";
  const month = monthName.length > 0 ? monthName : "";
  const day = dayValue.length === 2 ? dayValue : "";
  const year = yearValue.length === 4 ? yearValue : "";

  if (!month && !day && !year) {
    return "";
  }
  if (!month && !day && year) {
    return `... ${year}`;
  }
  if (!month && day && year) {
    return `... ${day}, ${year}`;
  }
  if (!month && day && !year) {
    return `... ${day} ...`;
  }
  if (month && !day && !year) {
    return `${month} ...`;
  }
  if (month && !day && year) {
    return `${month} ... ${year}`;
  }
  if (month && day && !year) {
    return `${month} ${day} ...`;
  }

  return `${month} ${day}, ${year}`;
};

export const getNumberOfDaysInMonth = ({
  month,
  year,
}: {
  month: string;
  year: string;
}) => new Date(Number(year), Number(month), 0).getDate();

export const getIsDateOverProvidedDaysAway = (date: Date, days: number) => {
  const timestamp = days * 24 * 60 * 60 * 1000;
  return date.getTime() > new Date().getTime() + timestamp;
};
