export const UnlockedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="57"
    viewBox="0 0 56 57"
    fill="none"
    className="max-w-12"
  >
    <path
      d="M39.6649 6.28833C36.5035 6.36178 33.5012 7.63448 31.3148 9.82793C29.1285 12.0214 27.9362 14.957 27.9988 17.9923V24.4883H6.26663C5.38297 24.4883 4.66663 25.2047 4.66663 26.0883V48.2883C4.66663 49.0309 4.9739 49.7431 5.52085 50.2682C6.06781 50.7933 6.80964 51.0883 7.58315 51.0883H33.8318C34.6053 51.0883 35.3472 50.7933 35.8941 50.2682C36.4411 49.7431 36.7483 49.0309 36.7483 48.2883V26.0883C36.7483 25.2047 36.032 24.4883 35.1483 24.4883H30.9153V17.9923C30.8524 15.6995 31.7374 13.4759 33.3769 11.8075C35.0163 10.1391 37.277 9.16152 39.6649 9.08833C42.0528 9.16152 44.3134 10.1391 45.9529 11.8075C47.5923 13.4759 48.4773 15.6995 48.4144 17.9923V27.5543C48.4144 27.9256 48.5681 28.2817 48.8415 28.5443C49.115 28.8068 49.4859 28.9543 49.8727 28.9543C50.2594 28.9543 50.6304 28.8068 50.9038 28.5443C51.1773 28.2817 51.3309 27.9256 51.3309 27.5543V17.9923C51.3936 14.957 50.2013 12.0214 48.0149 9.82793C45.8286 7.63448 42.8262 6.36178 39.6649 6.28833ZM22.1657 38.8103V42.6883H19.2492V38.6843C18.5393 38.3336 17.9755 37.7604 17.6516 37.0598C17.3277 36.3592 17.263 35.5733 17.4684 34.8325C17.6737 34.0918 18.1367 33.4407 18.7805 32.9873C19.4243 32.534 20.2102 32.3056 21.0078 32.34C21.8054 32.3745 22.5668 32.6697 23.1656 33.1768C23.7644 33.6838 24.1646 34.3722 24.2998 35.1277C24.435 35.8831 24.297 36.6603 23.9089 37.3301C23.5207 38 22.9056 38.5223 22.1657 38.8103Z"
      fill="#878D87"
    />
  </svg>
);

export const LockedIcon = () => (
  <svg
    width="56"
    height="57"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="max-w-12"
  >
    <path
      d="M15.1836 51.0881C14.0884 51.0881 13.1511 50.7023 12.3716 49.9305C11.5905 49.1604 11.2 48.2344 11.2 47.1523V27.6462C11.2 26.5641 11.5905 25.6381 12.3716 24.868C13.1511 24.0962 14.0884 23.7104 15.1836 23.7104H18.6V18.8363C18.6 16.1214 19.557 13.8176 21.4712 11.9248C23.3853 10.0321 25.7171 9.08651 28.4666 9.08814C31.2145 9.08814 33.5463 10.0337 35.4621 11.9248C37.3779 13.816 38.3349 16.1198 38.3333 18.8363V23.7104H41.7496C42.8448 23.7104 43.7821 24.0962 44.5616 24.868C45.3427 25.6381 45.7333 26.5641 45.7333 27.6462V47.1523C45.7333 48.2344 45.3427 49.1604 44.5616 49.9305C43.7821 50.7023 42.8448 51.0881 41.7496 51.0881H15.1836ZM28.4666 41.9881C29.5076 41.9881 30.384 41.6356 31.0961 40.9305C31.8098 40.227 32.1666 39.361 32.1666 38.3326C32.1666 37.3042 31.8098 36.4382 31.0961 35.7347C30.384 35.0296 29.5076 34.677 28.4666 34.677C27.4257 34.677 26.5492 35.0296 25.8372 35.7347C25.1235 36.4382 24.7666 37.3042 24.7666 38.3326C24.7666 39.361 25.1235 40.227 25.8372 40.9305C26.5492 41.6356 27.4257 41.9881 28.4666 41.9881ZM21.0666 23.7104H35.8666V18.8363C35.8666 16.8054 35.1472 15.0792 33.7083 13.6576C32.2694 12.236 30.5222 11.5252 28.4666 11.5252C26.4111 11.5252 24.6638 12.236 23.225 13.6576C21.7861 15.0792 21.0666 16.8054 21.0666 18.8363V23.7104Z"
      fill="#878D87"
    />
    <rect
      x="24.2667"
      y="30.5549"
      width="13.0667"
      height="13.0667"
      fill="#878D87"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.015 42.7733V38.8953C30.7549 38.6073 31.37 38.085 31.7582 37.4151C32.1463 36.7453 32.2843 35.9681 32.1491 35.2127C32.0139 34.4572 31.6137 33.7688 31.0149 33.2618C30.4161 32.7547 29.6547 32.4595 28.8571 32.425C28.0595 32.3906 27.2736 32.619 26.6298 33.0723C25.986 33.5257 25.523 34.1768 25.3177 34.9176C25.1123 35.6583 25.177 36.4442 25.5009 37.1448C25.8248 37.8454 26.3886 38.4186 27.0985 38.7693V42.7733H30.015Z"
      fill="white"
    />
  </svg>
);
