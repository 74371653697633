import { useState } from "react";
import { BaseButton, BaseRadioButton } from "../../BaseComponents";
import BottomSheet from "../../components/bottom-sheet/BottomSheet";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";
import { Mailbox } from "@phosphor-icons/react";

type Props = {
  question: string;
  warning: string;
  onSelect: (value: boolean) => void;
};

export const OnboardingFinancialPep = ({
  question,
  warning,
  onSelect,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [requiresFollowUp, setRequiresFollowUp] = useState(false);

  const handlePressProceed = () => {
    setRequiresFollowUp(true);
    setIsOpen(false);
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        {requiresFollowUp ? (
          <div className="flex flex-col items-center">
            <Mailbox size={72} className="text-griselda" />
            <h1 className="text-3xl mb-4">We'll be in touch</h1>
            <p className="text-dareDevil font-light mb-8 text-center">
              Our team at Whale will reach out to you via email for additional
              information.
            </p>
            <BaseButton className="w-full" onPress={() => onSelect(true)}>
              Continue
            </BaseButton>
          </div>
        ) : (
          <div className="grid gap-10 xl:gap-20 grid-rows-1 grid-cols-1 xl:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="text-3xl mb-4">{question}</h1>
              <p className="text-dareDevil font-light mb-4 xl:mb-0">
                If you don't know what this means, it likely does not apply to
                you.
              </p>
            </div>
            <Tooltip
              classNames={{
                content: ["bg-brightGreen"],
                base: ["before:bg-brightGreen before:border-transparent"],
              }}
              content={<p className="mx-5 my-3">Most people answer "no"</p>}
              isOpen={!isOpen}
              placement="bottom"
              showArrow
            >
              <div className="flex flex-col justify-center">
                <BaseRadioButton
                  className="mb-4"
                  onPress={() => setIsOpen(true)}
                >
                  Yes
                </BaseRadioButton>
                <BaseRadioButton onPress={() => onSelect(false)}>
                  No
                </BaseRadioButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <BottomSheet
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Are you sure?"
        className="sm:hidden"
      >
        <p className="text-griselda font-light mb-6">{warning}</p>
        <BaseRadioButton className="mb-4" onPress={handlePressProceed}>
          Yes, Proceed
        </BaseRadioButton>
        <BaseRadioButton onPress={() => setIsOpen(false)}>
          Cancel
        </BaseRadioButton>
      </BottomSheet>
      <Modal
        className="hidden sm:flex"
        hideCloseButton={true}
        isDismissable={false}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="lg"
      >
        <ModalContent>
          <ModalHeader className="text-2xl">Are you sure?</ModalHeader>
          <ModalBody>
            <p className="text-griselda font-light">{warning}</p>
          </ModalBody>
          <ModalFooter>
            <BaseRadioButton className="mb-4" onPress={handlePressProceed}>
              Yes, Proceed
            </BaseRadioButton>
            <BaseRadioButton onPress={() => setIsOpen(false)}>
              Cancel
            </BaseRadioButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
