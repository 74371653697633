import { useEffect, useState } from "react";
import { WhaleLogo } from "../helpers/Svgs";
import { fetchFooterDocuments } from "./websiteApi";
import { Link, ScrollRestoration } from "react-router-dom";

interface Agreement {
  link: string;
  name: string;
  type: "LEGAL" | "FORMS";
}

export const WebsiteFooter = () => {
  const [legalLinks, setLegalLinks] = useState<Agreement[]>([]);
  const [formLinks, setFormLinks] = useState<Agreement[]>([]);

  useEffect(() => {
    fetchFooterDocuments()
      .then((data: { agreements: Agreement[] }) => {
        const { agreements } = data;
        const legal = agreements.filter((item) => item.type === "LEGAL");
        const form = agreements.filter((item) => item.type === "FORMS");
        setLegalLinks(legal);
        setFormLinks(form);
      })
      .catch(() => {
        console.log("There was an error loading the footer legal documents");
      });
  }, []);

  return (
    <div className="text-white bg-midnight p-10 lg:p-20">
      <div className="grid gap-7 grid-cols-1 lg:grid-cols-5 grid-rows-1 mt-10">
        <div className="mb-10">
          <WhaleLogo color="#ADBCAD" height={43} width={150} />
        </div>
        <div>
          <p className="font-light text-xl mb-4">Customer Support</p>
          <ul className="switzer">
            <li className="mb-1">
              <a
                className="switzer text-sage font-light text-lg"
                href="https://whale.help.usepylon.com"
                rel="noreferrer"
                target="_blank"
              >
                Help Center
              </a>
            </li>
            <li className="mb-1">
              <a
                className="switzer text-sage font-light text-lg"
                href="mailto:support@getwhale.co"
              >
                support@getwhale.co
              </a>
            </li>
          </ul>
        </div>
        <div>
          <p className="font-light text-xl mb-4">Company</p>
          <ul className="switzer">
            <li className="mb-1">
              <Link
                className="switzer text-sage font-light text-lg"
                to="/about"
              >
                About Us
              </Link>
            </li>
            <li className="mb-1">
              <Link className="switzer text-sage font-light text-lg" to="/team">
                Our Team
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="switzer text-sage font-light text-lg"
                to="/careers"
              >
                Careers
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="switzer text-sage font-light text-lg"
                to="/"
              >
                For Renters
              </Link>
            </li>
            <li className="mb-1">
              <Link
                className="switzer text-sage font-light text-lg"
                to="/properties"
              >
                For Properties
              </Link>
            </li>
          </ul>
        </div>
        {legalLinks.length > 0 && (
          <div>
            <p className="font-light text-xl mb-4">Legal</p>
            <ul className="switzer">
              {legalLinks.map(({ link, name }) => (
                <li key={name} className="mb-1">
                  <a
                    className="switzer text-sage font-light text-lg"
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {formLinks.length > 0 && (
          <div>
            <p className="font-light text-xl mb-4">Forms</p>
            <ul className="switzer">
              {formLinks.map(({ link, name }) => (
                <li key={name} className="mb-1">
                  <a
                    className="switzer text-sage font-light text-lg"
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="lg:col-span-4 font-light text-white text-xs">
          <p>© 2024 Build Whale, Inc.</p>
          <p>
            332 S Michigan Ave Suite #121-2234
            <br />
            Chicago, IL 60604
          </p>
          <p className="mt-3 text-sage">
            Build Whale, Inc. ("Whale") is a Registered Investment Adviser with
            the U.S. Securities & Exchange Commission. Registration does not
            imply a certain level of skill or training. Information presented is
            for educational purposes only and does not intend to make an offer
            or solicitation for the sale or purchase of any securities. Whale's
            website and its associated links offer news, commentary, and
            generalized research, not personalized investment advice. Nothing on
            this website should be interpreted to state or imply that past
            performance is an indication of future performance. All investments
            involve risk and unless otherwise stated, are not guaranteed. Be
            sure to consult with a tax professional before implementing any
            investment strategy.
          </p>
        </div>
      </div>
      <ScrollRestoration />
    </div>
  );
};
