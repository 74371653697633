import { Radio, RadioGroup } from "@nextui-org/react";
import { Backpack, Briefcase, Coffee, TreePalm } from "@phosphor-icons/react";

type Props = {
  onSelect: (value: string) => void;
};

const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;
  return (
    <Radio
      {...otherProps}
      classNames={{
        base: "m-0 w-full max-w-full rounded-lg border-solid border-2 border-strokeGrey mb-2",
        wrapper: "hidden",
        label: "flex items-center",
      }}
    >
      {children}
    </Radio>
  );
};

export const OnboardingFinancialEmploymentStatus = ({ onSelect }: Props) => (
  <div className="w-full sm:h-full max-w-3xl m-auto p-5 flex items-center justify-center flex-col sm:flex-row">
    <div className="sm:mr-10">
      <h1 className="text-3xl mb-4">
        What is your current employment status?
      </h1>
      <p className="hidden text-griselda sm:flex">
        If you are employed and a student, select "employed" as your answer.
      </p>
    </div>
    <RadioGroup
      aria-label="Select employment status"
      className="w-full"
      onValueChange={onSelect}
    >
      <CustomRadio value="employed">
        <Briefcase
          size={32}
          className="mr-2"
          color="#5C6D6B"
          weight="duotone"
        />{" "}
        Employed
      </CustomRadio>
      <CustomRadio value="student">
        <Backpack size={32} className="mr-2" color="#5C6D6B" weight="duotone" />{" "}
        Student
      </CustomRadio>
      <CustomRadio value="unemployed">
        <Coffee size={32} className="mr-2" color="#5C6D6B" weight="duotone" />{" "}
        Unemployed
      </CustomRadio>
      <CustomRadio value="retired">
        <TreePalm size={32} className="mr-2" color="#5C6D6B" weight="duotone" />{" "}
        Retired
      </CustomRadio>
    </RadioGroup>
  </div>
);
