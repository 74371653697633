import { NavLink } from "react-router-dom";
import { BaseButton } from "../BaseComponents";

export const WebsiteSplash = ({ heading, subheading, subtitle, cta }: any) => (
  <div className="splash bg-midnight">
    <div className="h-full flex flex-col xl:justify-center mx-8 md:mx-12 lg:mx-20 xl:mx-28 2xl:mx-44 pt-32 xl:pt-0">
      {heading}
      {subheading}
      <BaseButton
        as={NavLink}
        to={cta.to}
        className="w-full lg:w-fit mt-11 px-12"
      >
        {cta.text}
      </BaseButton>
      {subtitle}
    </div>
  </div>
);
