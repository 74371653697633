import { Accordion, AccordionItem } from "@nextui-org/react";
import { CaretDown } from "@phosphor-icons/react";

const FAQ_ITEMS = [
  {
    title: "How much does it cost?",
    key: "1",
    content: (
      <>
        <p className="mb-4">Whale is completely free for properties.</p>
        <p>
          Residents pay a one-time processing fee (2% of their deposit amount)
          for each new lease they sign using Whale. There is no charge for
          residents renewing a lease or moving an existing security deposit to
          Whale.
        </p>
      </>
    ),
  },
  {
    title: "Is the deposit fully guaranteed?",
    key: "2",
    content: (
      <p>
        Yes! Whale holds deposits in SIPC-insured (up to $250,000) cash accounts
        and locks it during the lease so it's 100% risk-free.
      </p>
    ),
  },
  {
    title: "What do residents earn?",
    key: "3",
    content: <p>Residents earn up to 4.2% interest on their deposit.</p>,
  },
  {
    title: "How does the move-out work?",
    key: "4",
    content: (
      <>
        <p className="mb-4">
          When a resident moves out, you'll send Whale a Final Move-Out
          statement (FMO) that includes the total move-out charges we should
          deduct from the rental deposit.
        </p>
        <p>
          Once received, Whale sends the move-out charge to your bank account
          and the remainder is then unlocked and becomes immediately available
          for the resident in their Whale account.
        </p>
      </>
    ),
  },
  {
    title: "What if there's a lease renewal?",
    key: "5",
    content: (
      <p>
        Whale continues to lock the deposit until the resident moves out. No
        action required by you or the resident.
      </p>
    ),
  },
];

export const WebsitePropertiesFaq = () => (
  <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-32">
    <h2 className="text-7xl md:text-8xl mb-6">FAQs</h2>
    <div className="-mx-2">
      <Accordion>
        {FAQ_ITEMS.map(({ content, key, title }) => (
          <AccordionItem
            aria-label={title}
            indicator={<CaretDown size={32} className="fill-midnight" />}
            key={key}
            title={
              <p className="text-lg md:text-2xl font-medium text-midnight my-3">
                {title}
              </p>
            }
          >
            <div className="text-griselda text-lg md:text-2xl mb-6">
              {content}
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  </div>
);
