import { useEffect, useState } from "react";
import { ArrowLeft } from "@phosphor-icons/react";
import { Button, Navbar, NavbarBrand, Progress } from "@nextui-org/react";
import { WhaleLogo } from "../../helpers/Svgs";
import { BaseButton } from "../../BaseComponents";

type Props = {
  continueButtonText?: string;
  disableBack?: boolean;
  disableContinue?: boolean;
  isLoading?: boolean;
  onPressBack?: () => void;
  onPressContinue?: () => void;
  progressBarValue?: number;
  showBack?: boolean;
  showContinue?: boolean;
  showProgressBar?: boolean;
};

const OnboardingNavbar = ({
  continueButtonText = "Continue",
  disableBack = false,
  disableContinue = false,
  isLoading = false,
  onPressBack = () => {},
  onPressContinue = () => {},
  progressBarValue = 50,
  showBack = true,
  showContinue = true,
  showProgressBar = true,
}: Props) => {
  const [hasMounted, setHasMounted] = useState(false);

  const logoClass = showBack
    ? "hidden sm:flex sm:justify-center"
    : "sm:justify-center";
  const backButtonClass = showBack
    ? "flex justify-start sm:min-w-32"
    : "hidden sm:flex justify-start sm:min-w-32";
  const progressBarClass = showProgressBar
    ? "m-auto px-6 pt-4 sm:py-1"
    : "hidden m-auto px-6 pt-4 sm:py-1";

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHasMounted(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="flex flex-col-reverse sm:flex-col border-b-1 border-[#ECECEC] sm:border-transparent">
      <Navbar position="static" isBlurred={false} maxWidth="full">
        <div className={backButtonClass}>
          {showBack && (
            <>
              <Button
                aria-label="Back"
                className="sm:hidden"
                isDisabled={isLoading || disableBack}
                isIconOnly
                onPress={onPressBack}
                radius="md"
                variant="light"
              >
                <ArrowLeft size={24} />
              </Button>
              <Button
                className="hidden sm:flex"
                isDisabled={isLoading || disableBack}
                onPress={onPressBack}
                startContent={<ArrowLeft size={24} />}
                radius="md"
                variant="light"
              >
                Back
              </Button>
            </>
          )}
        </div>
        <NavbarBrand className={logoClass}>
          <WhaleLogo />
        </NavbarBrand>
        <div className="min-w-20 sm:min-w-32 flex justify-end">
          {showContinue && (
            <BaseButton
              className={showContinue ? "flex" : "hidden"}
              isDisabled={isLoading || disableContinue}
              isLoading={isLoading}
              onPress={onPressContinue}
              size="md"
            >
              {continueButtonText}
            </BaseButton>
          )}
        </div>
      </Navbar>
      <Progress
        aria-label="Progress"
        classNames={{
          base: progressBarClass,
          indicator: "bg-sage"
        }}
        value={progressBarValue}
        disableAnimation={!hasMounted}
      />
    </div>
  );
};

export default OnboardingNavbar;
