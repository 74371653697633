import { useState } from "react";
import { OnboardingCitizenshipCountry } from "./OnboardingCitizenshipCountry";
import { OnboardingCitizenshipCountryOfBirth } from "./OnboardingCitizenshipCountryOfBirth";
import { OnboardingCitizenshipDateOfDeparture } from "./OnboardingCitizenshipDateOfDeparture";
import { OnboardingCitizenshipTaxesCountry } from "./OnboardingCitizenshipTaxesCountry";
import { OnboardingCitizenshipUsa } from "./OnboardingCitizenshipUsa";
import { OnboardingCitizenshipVisaExpiration } from "./OnboardingCitizenshipVisaExpiration";
import { OnboardingCitizenshipVisaType } from "./OnboardingCitizenshipVisaType";
import { OnboardingSectionTitle } from "../shared/OnboardingSectionTitle";
import { useOnboardingContext } from "../shared/OnboardingContext";
import OnboardingNavbar from "../../components/onboarding-navbar/OnboardingNavbar";
import {
  GREEN_CARD,
  OnboardingCitizenshipResidentCard,
} from "./OnboardingCitizenshipResidentCard";
import {
  PAGE_FIRST,
  PAGE_LAST,
  SECTION_CITIZENSHIP,
  SECTION_FINANCIAL,
  SECTION_PERSONAL,
  USA_COUNTRY_CODE,
} from "../shared/onboardingConstants";
import { DateInputType, InvestorType } from "../onboardingTypes";

const PAGE_BIRTH_COUNTRY = "PAGE_BIRTH_COUNTRY";
const PAGE_CITIZENSHIP_COUNTRY = "PAGE_CITIZENSHIP_COUNTRY";
const PAGE_IS_USA_CITIZEN = "PAGE_IS_USA_CITIZEN";
const PAGE_RESIDENT_CARD = "PAGE_RESIDENT_CARD";
const PAGE_SECTION_TITLE = "PAGE_SECTION_TITLE";
const PAGE_TAX_COUNTRY = "PAGE_TAX_COUNTRY";
const PAGE_USA_DEPARTURE = "PAGE_USA_DEPARTURE";
const PAGE_VISA_EXPIRATION = "PAGE_VISA_EXPIRATION";
const PAGE_VISA_TYPE = "PAGE_VISA_TYPE";

const HIDE_CONTINUE_BUTTON = [
  PAGE_IS_USA_CITIZEN,
  PAGE_RESIDENT_CARD,
  PAGE_SECTION_TITLE,
  PAGE_VISA_TYPE,
];

const getDefaultPage = (investorServer: InvestorType, section: string) => {
  const { countryOfCitizenship, onboardingPage } = investorServer;
  const isUsaCitizen = countryOfCitizenship === USA_COUNTRY_CODE;

  if (section === `${SECTION_CITIZENSHIP}_${PAGE_FIRST}`) {
    const pages = getPages(isUsaCitizen);
    return pages[0];
  }
  if (section === `${SECTION_CITIZENSHIP}_${PAGE_LAST}`) {
    const pages = getPages(isUsaCitizen);
    return pages[pages.length - 1];
  }
  if (onboardingPage?.includes(SECTION_CITIZENSHIP)) {
    return onboardingPage.replace(`${SECTION_CITIZENSHIP}_`, "");
  }

  return PAGE_SECTION_TITLE;
};

const getPages = (isUsaCitizen = false, isGreenCard = false) => {
  if (isUsaCitizen) {
    return [PAGE_SECTION_TITLE, PAGE_IS_USA_CITIZEN];
  }
  if (isGreenCard) {
    return [
      PAGE_SECTION_TITLE,
      PAGE_IS_USA_CITIZEN,
      PAGE_RESIDENT_CARD,
      PAGE_BIRTH_COUNTRY,
      PAGE_CITIZENSHIP_COUNTRY,
    ];
  }
  return [
    PAGE_SECTION_TITLE,
    PAGE_IS_USA_CITIZEN,
    PAGE_RESIDENT_CARD,
    PAGE_VISA_TYPE,
    PAGE_USA_DEPARTURE,
    PAGE_VISA_EXPIRATION,
    PAGE_CITIZENSHIP_COUNTRY,
    PAGE_BIRTH_COUNTRY,
    PAGE_TAX_COUNTRY,
  ];
};

export const OnboardingCitizenship = () => {
  const {
    investorLocal: {
      countryOfBirth,
      countryOfCitizenship,
      countryOfTaxResidence,
      dateOfUSDeparture,
      visaExpirationDate,
      visaType,
    },
    section,
    investorServer,
    setSection,
    updateInvestor,
  } = useOnboardingContext();

  const getDefaultTaxCountry = () => {
    if (countryOfTaxResidence) {
      return countryOfTaxResidence;
    }
    if (isUsaCitizen) {
      return USA_COUNTRY_CODE;
    }
    return "";
  };

  // Form Fields
  const [citizenCountry, setCitizenCountry] = useState(countryOfCitizenship);
  const isUsaCitizen = citizenCountry === USA_COUNTRY_CODE;
  const isGreenCard = visaType === GREEN_CARD;
  const [taxCountry, setTaxCountry] = useState(getDefaultTaxCountry());
  const [birthCountry, setBirthCountry] = useState(countryOfBirth);
  const [dateOfDeparture, setDateOfDeparture] = useState(dateOfUSDeparture);
  const [visaExpiration, setVisaExpiration] = useState(visaExpirationDate);
  const [otherVisaType, setOtherVisaType] = useState("");

  // Pages
  const defaultPage = getDefaultPage(investorServer, section);
  const defaultPages = getPages(isUsaCitizen, isGreenCard);
  const [page, setPage] = useState(defaultPage);
  const [pages, setPages] = useState(defaultPages);
  const numberOfPages = pages.length + 1;
  const progressPercentage = ((pages.indexOf(page) + 1) / numberOfPages) * 100;

  // Interactions
  const [isSaving, setIsSaving] = useState(false);

  const goToNextPage = () => {
    setIsSaving(false);
    const nextPage = pages[pages.indexOf(page) + 1];
    if (nextPage) {
      setPage(nextPage);
    } else {
      setSection(`${SECTION_FINANCIAL}_${PAGE_FIRST}`);
    }
  };

  const handleSaveFailure = () => {
    setIsSaving(false);
  };

  const handlePressBack = () => {
    const prevPage = pages[pages.indexOf(page) - 1];
    if (prevPage) {
      setPage(prevPage);
      if (prevPage === PAGE_IS_USA_CITIZEN) {
        setPages(getPages(isUsaCitizen));
      }
    } else {
      setSection(`${SECTION_PERSONAL}_${PAGE_LAST}`);
    }
  };

  const handleUpdateInvestor = ({
    name,
    value,
    page,
  }: {
    name: string;
    value: string;
    page: string;
  }) => {
    setIsSaving(true);
    updateInvestor(
      { [name]: value, onboardingPage: `${SECTION_CITIZENSHIP}_${page}` },
      goToNextPage,
      handleSaveFailure
    );
  };

  const handlePressContinue = () => {
    if (page === PAGE_SECTION_TITLE) {
      goToNextPage();
    }
    if (page === PAGE_TAX_COUNTRY) {
      handleUpdateInvestor({
        name: "countryOfTaxResidence",
        page: PAGE_TAX_COUNTRY,
        value: taxCountry,
      });
    }
    if (page === PAGE_USA_DEPARTURE) {
      handleUpdateInvestor({
        name: "dateOfUSDeparture",
        page: PAGE_USA_DEPARTURE,
        value: dateOfDeparture,
      });
    }
    if (page === PAGE_VISA_EXPIRATION) {
      handleUpdateInvestor({
        name: "visaExpirationDate",
        page: PAGE_VISA_EXPIRATION,
        value: visaExpiration,
      });
    }
    if (page === PAGE_CITIZENSHIP_COUNTRY) {
      handleUpdateInvestor({
        name: "countryOfCitizenship",
        page: PAGE_CITIZENSHIP_COUNTRY,
        value: citizenCountry,
      });
    }
    if (page === PAGE_BIRTH_COUNTRY) {
      handleUpdateInvestor({
        name: "countryOfBirth",
        page: PAGE_BIRTH_COUNTRY,
        value: birthCountry,
      });
    }
    if (page === PAGE_VISA_TYPE) {
      handleUpdateInvestor({
        name: "visaType",
        page: PAGE_VISA_TYPE,
        value: otherVisaType,
      });
      setOtherVisaType("");
    }
  };

  const getShouldDisableContinue = () => {
    if (page === PAGE_TAX_COUNTRY && !taxCountry) {
      return true;
    }
    if (page === PAGE_USA_DEPARTURE && !dateOfDeparture) {
      return true;
    }
    if (page === PAGE_VISA_EXPIRATION && !visaExpiration) {
      return true;
    }
    if (page === PAGE_CITIZENSHIP_COUNTRY && !citizenCountry) {
      return true;
    }
    if (page === PAGE_BIRTH_COUNTRY && !birthCountry) {
      return true;
    }
    return false;
  };

  const handleChangeDateOfDeparture = ({ isValid, date }: DateInputType) => {
    setDateOfDeparture(isValid ? date : null);
  };

  const handleChangeVisaExpiration = ({ isValid, date }: DateInputType) => {
    setVisaExpiration(isValid ? date : null);
  };

  const handleSelectIsUsaCitizen = (isUsaCitizen: boolean) => {
    if (isUsaCitizen) {
      setIsSaving(true);
      setCitizenCountry(USA_COUNTRY_CODE);
      setTaxCountry(USA_COUNTRY_CODE);
      updateInvestor(
        {
          countryOfCitizenship: USA_COUNTRY_CODE,
          countryOfTaxResidence: USA_COUNTRY_CODE,
          onboardingPage: `${SECTION_CITIZENSHIP}_${PAGE_IS_USA_CITIZEN}`,
        },
        () => {
          setIsSaving(false);
          setSection(`${SECTION_FINANCIAL}_${PAGE_FIRST}`);
        },
        handleSaveFailure
      );
    } else {
      setPage(PAGE_RESIDENT_CARD);
    }
    setPages(getPages(isUsaCitizen));
  };

  const handleSelectResidentCard = (value: "GREEN_CARD" | "VISA") => {
    if (value === GREEN_CARD) {
      setIsSaving(true);
      updateInvestor(
        {
          isPermanentResident: true,
          countryOfTaxResidence: USA_COUNTRY_CODE,
          onboardingPage: `${SECTION_CITIZENSHIP}_${PAGE_RESIDENT_CARD}`,
        },
        () => {
          setIsSaving(false);
          setPages(getPages(false, true));
          setPage(PAGE_BIRTH_COUNTRY);
        },
        handleSaveFailure
      );
    } else {
      setPage(PAGE_VISA_TYPE);
    }
  };

  const handleSelectVisaType = (selectedVisaType: string) => {
    handleUpdateInvestor({
      name: "visaType",
      page: PAGE_VISA_TYPE,
      value: selectedVisaType,
    });
  };

  return (
    <>
      <OnboardingNavbar
        disableContinue={getShouldDisableContinue()}
        isLoading={isSaving}
        onPressBack={handlePressBack}
        onPressContinue={handlePressContinue}
        progressBarValue={progressPercentage}
        showBack
        showContinue={
          !HIDE_CONTINUE_BUTTON.includes(page) ||
          (page === PAGE_VISA_TYPE && otherVisaType.length > 0)
        }
        showProgressBar={page !== PAGE_SECTION_TITLE}
      />
      <div className="w-full h-full max-w-5xl m-auto p-5">
        {page === PAGE_SECTION_TITLE && (
          <OnboardingSectionTitle
            onPressContinue={handlePressContinue}
            sectionName="Citizenship"
            sectionNumber={2}
          />
        )}
        {page === PAGE_IS_USA_CITIZEN && (
          <OnboardingCitizenshipUsa onSelect={handleSelectIsUsaCitizen} />
        )}
        {page === PAGE_RESIDENT_CARD && (
          <OnboardingCitizenshipResidentCard
            onSelect={handleSelectResidentCard}
          />
        )}
        {page === PAGE_VISA_TYPE && (
          <OnboardingCitizenshipVisaType
            onChangeOtherValue={setOtherVisaType}
            onSelect={handleSelectVisaType}
          />
        )}
        {page === PAGE_USA_DEPARTURE && (
          <OnboardingCitizenshipDateOfDeparture
            isoDate={dateOfUSDeparture}
            onChange={handleChangeDateOfDeparture}
          />
        )}
        {page === PAGE_VISA_EXPIRATION && (
          <OnboardingCitizenshipVisaExpiration
            isoDate={visaExpirationDate}
            onChange={handleChangeVisaExpiration}
          />
        )}
        {page === PAGE_CITIZENSHIP_COUNTRY && (
          <OnboardingCitizenshipCountry onSelect={setCitizenCountry} />
        )}
        {page === PAGE_BIRTH_COUNTRY && (
          <OnboardingCitizenshipCountryOfBirth onSelect={setBirthCountry} />
        )}
        {page === PAGE_TAX_COUNTRY && (
          <OnboardingCitizenshipTaxesCountry
            isUsaCitizen={isUsaCitizen}
            onSelect={setTaxCountry}
          />
        )}
      </div>
    </>
  );
};
