import { API_URL } from "../constants";

const FETCH_OVERVIEW_URL = `${API_URL}/investor/dashboard`;
const FETCH_DEPOSITS_URL = `${API_URL}/investor/deposits`;
const FETCH_TRANSACTIONS_URL = `${API_URL}/investor/transactions`;
const FETCH_BANK_ACCOUNT_INFO_URL = `${API_URL}/investor/getBankAccountInfo`;
const INITIATE_SECURE_ACTION_URL = `${API_URL}/investor/initiateSecureActionMFA`;
const VERIFY_SECURE_ACTION_URL = `${API_URL}/investor/verifySecureActionMFA`;
const INITIATE_WITHDRAWAL_URL = `${API_URL}/investor/withdrawFunds`;

export const fetchOverview = async () => {
  const response = await fetch(`${FETCH_OVERVIEW_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchOverview");
  }
};

export const fetchDeposits = async () => {
  const response = await fetch(`${FETCH_DEPOSITS_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchDeposits");
  }
};

export const fetchTransactions = async () => {
  const response = await fetch(FETCH_TRANSACTIONS_URL, {
    body: JSON.stringify({ pageSize: 1000 }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchTransactions");
  }
};

export const fetchBankAccountInfo = async () => {
  const response = await fetch(`${FETCH_BANK_ACCOUNT_INFO_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error in fetchBankAccountInfo");
  }
};

export const initiateSecureAction = async () => {
  const response = await fetch(INITIATE_SECURE_ACTION_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 202) {
    return await response.json();
  } else {
    throw new Error("Error in initiateSecureAction");
  }
};

export const verifySecureAction = async (data: any) => {
  const response = await fetch(VERIFY_SECURE_ACTION_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 202) {
    return await response.json();
  } else {
    throw new Error("Error in verifySecureAction");
  }
};

export const initiateWithdrawal = async (data: any) => {
  const response = await fetch(INITIATE_WITHDRAWAL_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("whaleAuth")}`,
    },
    method: "POST",
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error("Error in initiateWithdrawal");
  }
};
