import { useState } from "react";
import { PencilSimple } from "@phosphor-icons/react";
import CountryList from "../../components/country-list/CountryList";
import { useOnboardingContext } from "../shared/OnboardingContext";
import {
  ALL_COUNTRIES,
  getFlagEmoji,
} from "../../components/country-list/CountryListHelpers";

type Props = {
  onSelect: (countryCode: string | null) => void;
};

export const OnboardingCitizenshipCountry = ({ onSelect }: Props) => {
  const {
    investorLocal: { countryOfCitizenship },
  } = useOnboardingContext();

  const [countryCode, setCountryCode] = useState(countryOfCitizenship);

  const handleClickEdit = () => {
    setCountryCode(null);
    onSelect(null);
  };

  const handleSelect = (code: string) => {
    setCountryCode(code);
    onSelect(code);
  };

  const countryData = ALL_COUNTRIES[countryCode] || null;

  return (
    <div className="w-full h-full max-w-xl m-auto flex">
      <div className="w-full">
        <h1 className="text-3xl mb-4 sm:text-4xl sm:mt-10">
          Country of Citizenship
        </h1>
        {countryData ? (
          <div className="flex justify-between items-center p-3 rounded-lg border-solid border border-strokeGrey mt-10">
            <p className="text-xl">
              {getFlagEmoji(countryData.code)} {countryData.name}
            </p>
            <button
              className="flex size-11 items-center justify-center rounded-full ml-4 bg-warmGrey text-griselda hover:bg-taupe"
              onClick={handleClickEdit}
              type="button"
            >
              <PencilSimple size={25} />
            </button>
          </div>
        ) : (
          <CountryList excludeCountries={["US"]} onChange={handleSelect} />
        )}
      </div>
    </div>
  );
};
