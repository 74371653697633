import { CheckCircle, Circle } from "@phosphor-icons/react";
import { BaseButton } from "../../BaseComponents";

type Props = {
  onPressContinue: () => void;
  sectionName: string;
  sectionNumber: 1 | 2 | 3;
};

const NUMBER_TO_ROMAN: { [sectionNumber: number]: string } = {
  1: "I",
  2: "II",
  3: "III",
};

export const OnboardingSectionTitle = ({
  onPressContinue,
  sectionName,
  sectionNumber,
}: Props) => {
  return (
    <div className="h-full flex flex-col justify-between sm:justify-center items-center p-5">
      <div></div>
      <div className="text-center">
        <div className="flex justify-center mb-6">
          <div className="flex">
            {[1, 2, 3].map((index) => (
              <SectionCircle
                key={index}
                index={index}
                sectionNumber={sectionNumber}
              />
            ))}
          </div>
        </div>
        <h1 className="text-sm uppercase font-semibold text-midnight tracking-widest">
          Step {NUMBER_TO_ROMAN[sectionNumber]}
        </h1>
        <h2 className="text-3xl sm:text-4xl font-medium mt-3">{sectionName}</h2>
      </div>
      <BaseButton className="w-full sm:w-72 sm:mt-10" onPress={onPressContinue}>
        Continue
      </BaseButton>
    </div>
  );
};

const SectionCircle = ({
  index,
  sectionNumber,
}: {
  index: number;
  sectionNumber: number;
}) => {
  if (index < sectionNumber) {
    return <CheckCircle size={32} weight="fill" className="fill-sage" />;
  }
  if (index === sectionNumber) {
    return <Circle size={32} className="text-griselda" />;
  }
  if (index > sectionNumber) {
    return <Circle size={32} weight="fill" className="fill-lightGreen" />;
  }
  return null;
};
