import { RadioGroup, Radio, Input } from "@nextui-org/react";
import {
  Archive,
  Backpack,
  Briefcase,
  BriefcaseMetal,
  Camera,
  Globe,
  Handshake,
} from "@phosphor-icons/react";

type Props = {
  onChangeOtherValue: (value: string) => void;
  onSelect: (value: string) => void;
};

const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;
  return (
    <Radio
      {...otherProps}
      classNames={{
        base: "m-0 w-full max-w-full rounded-lg border-solid border-2 border-strokeGrey mb-2",
        wrapper: "hidden",
        label: "flex items-center",
      }}
    >
      {children}
    </Radio>
  );
};

export const OnboardingCitizenshipVisaType = ({
  onChangeOtherValue,
  onSelect,
}: Props) => (
  <div className="w-full max-w-xl m-auto p-5 flex items-center justify-center">
    <div>
      <h1 className="text-3xl mb-8 sm:text-center">What is your Visa type?</h1>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <Briefcase
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            Work
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="H1B">H1-B Visa</CustomRadio>
        <CustomRadio value="TN1">TN1 Visa</CustomRadio>
        <CustomRadio value="E3">E3 Visa</CustomRadio>
        <CustomRadio value="L1">L1 Visa</CustomRadio>
        <CustomRadio value="O1">O1 Visa</CustomRadio>
        <CustomRadio value="G4">G-4 Visa</CustomRadio>
      </RadioGroup>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <Backpack
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            Student
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="F1">F1 Visa</CustomRadio>
      </RadioGroup>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <Globe
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            DACA Visa
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="DACA">DACA Visa</CustomRadio>
      </RadioGroup>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <BriefcaseMetal
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            Business
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="E2">E2 Visa</CustomRadio>
        <CustomRadio value="E1">E1 Visa</CustomRadio>
        <CustomRadio value="B1">B1 Visa</CustomRadio>
      </RadioGroup>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <Handshake
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            Exchange Visitor
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="J1">J1 Visa</CustomRadio>
      </RadioGroup>
      <RadioGroup
        className="w-full mb-10"
        label={
          <p className="flex text-midnight items-center mb-1">
            <Camera
              color="#5C6D6B"
              weight="duotone"
              size={32}
              className="mr-2"
            />{" "}
            Tourist
          </p>
        }
        onValueChange={onSelect}
      >
        <CustomRadio value="B2">B2 Visa</CustomRadio>
      </RadioGroup>
      <div>
        <p className="flex text-midnight items-center">
          <Archive
            color="#5C6D6B"
            weight="duotone"
            size={32}
            className="mr-2"
          />{" "}
          Other
        </p>
        <Input
          aria-label="other"
          classNames={{ input: "text-sage font-light text-md" }}
          onValueChange={onChangeOtherValue}
          placeholder="Type here"
          variant="underlined"
        />
      </div>
    </div>
  </div>
);
