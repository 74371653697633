import { useEffect, useState } from "react";
import { fetchInvestorAgreementMeta } from "../shared/onboardingApi";
import { BaseLoadingScreen } from "../../BaseComponents";
import { USD_NUMBER_FORMAT } from "../../constants";

export const InvestmentAdvisoryAgreement = () => {
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [address, setAddress] = useState("");
  const [landlord, setLandlord] = useState("");
  const [deposit, setDeposit] = useState("");

  useEffect(() => {
    fetchInvestorAgreementMeta()
      .then((data: any) => {
        const {
          unitAddress: {
            administrativeArea,
            locality,
            postalCode,
            premise,
            thoroughfare,
          },
        } = data;
        setAddress(
          premise
            ? `${thoroughfare}, ${premise}, ${locality}, ${administrativeArea} ${postalCode}`
            : `${thoroughfare}, ${locality}, ${administrativeArea} ${postalCode}`
        );
        setLandlord(data.propertyLegalName);
        setDeposit(USD_NUMBER_FORMAT.format(data.amount));
        setLoadingMessage("");
      })
      .catch(() => {
        console.log("There was an error loading the investor agreements");
        setLoadingMessage("We are having trouble, please try again later.");
      });
  }, []);

  return loadingMessage === "" ? (
    <div className="p-5 switzer">
      <h1 className="text-center font-semibold mb-3">Build Whale, Inc.</h1>
      <h2 className="text-center font-semibold text-2xl mb-5">
        Investment Advisory Agreement
      </h2>
      <p className="mb-3">
        This Investment Advisory Agreement ("Agreement") sets forth the
        understanding between Build Whale, Inc. (the "Adviser", "we", or "us"),
        an investment adviser that has submitted an application to register with
        the U.S. Securities and Exchange Commission ("SEC") and you ("Client" or
        "you"), accessing and / or making use of the investment feature within
        the Build Whale, Inc. website at https://www.getwhale.co/ or application
        (collectively, the "Whale Application"). This Agreement shall become
        effective whenever you accept by clicking through the online advisory
        agreement made available during the Whale Application registration
        process which will signify your consent to the terms described in this
        Agreement.
      </p>
      <p className="mb-3">
        WHEREAS, Client is a tenant of {address}, owned and / or managed by{" "}
        {landlord} ("Landlord") pursuant to that certain lease agreement by and
        between Client and Landlord ("Lease Agreement"); and
      </p>
      <p className="mb-3">
        WHEREAS, to the extent Client did not execute the Lease Agreement as of
        executing this Agreement, the Client has executed that certain lease
        addendum by and between Client and Landlord; and
      </p>
      <p className="mb-3">
        WHEREAS, Client has remitted in the amount of {deposit} as an investable
        account ("Investable Account"); and
      </p>
      <p className="mb-3">
        WHEREAS, Client is a registered user of the Whale Application; and
      </p>
      <p className="mb-3">
        WHEREAS, Adviser wishes to provide discretionary investment advisory
        services to Client; and
      </p>
      <p className="mb-3">
        WHEREAS, Client desires to transfer the Investable Account held by the
        Client's personal bank account ("Client Checking Account") to Alpaca
        Securities LLC ("Broker" and "Custodian") by way of an automated
        customer account transfer service ("ACATS"); and
      </p>
      <p className="mb-3">
        WHEREAS, Adviser has provided, and Client has completed and submitted, a
        transfer initiation form ("TIF") to Broker;
      </p>
      <p className="mb-3">
        WHEREAS, Adviser, Broker/Custodian, Client, and Landlord have executed a
        Security Account and Deposit Control Agreement ("Deposit Control
        Agreement"); and
      </p>
      <p className="mb-3">
        NOW, THEREFORE, in consideration of the promises and mutual
        considerations provided herein, and intending to be legally bound
        hereby, Client and Adviser agree as follows:
      </p>
      <h3 className="mb-3 text-xl">1. Appointment of Adviser</h3>
      <p className="mb-3">
        By this Agreement, Client retains Adviser to provide investment advisory
        services to Client for the securities, cash, and cash equivalents
        transferred by way of ACATs (the "Account Assets") in Client's advisory
        account with the Custodian on a discretionary basis.
      </p>
      <p className="mb-3">
        Adviser will provide Client with an investment recommendation via its
        online or mobile interactive questionnaire as described in its Form ADV
        Part 2 ("Brochure"). The recommended portfolio will be based upon
        Client's stated objectives and risk profile at the time of the
        engagement as determined by your responses to the client questionnaire
        included in the Whale Application ("Client Questionnaire"). The
        recommended model portfolios ("Model Portfolio(s)") will be made up of
        exchange-traded funds ("ETFs") managed by unaffiliated third-party SEC
        registered investment advisers and cash or cash equivalents
        (individually or collectively, the "Investment(s)"). Client will select
        from such recommended Investment(s) attached hereto as{" "}
        <span className="underline">Schedule A</span>. Client understands that
        information regarding Client's specific circumstances that has not been
        communicated to or analyzed by the Adviser may impact the suitability of
        the recommendations provided to Client.
      </p>
      <h3 className="mb-3 text-xl">2. Scope of Engagement</h3>
      <p className="mb-3">
        Client hereby appoints Adviser as a discretionary investment adviser to
        perform the services hereinafter described, and Adviser accepts such
        appointment.
      </p>
      <ol className="list-[upper-alpha]">
        <li className="ml-14 mb-3">
          Adviser shall provide administrative assistance to Client by
          transferring Account Assets to Broker / Custodian.
        </li>
        <li className="ml-14 mb-3">
          Adviser shall be responsible for reviewing and providing an assessment
          of your present level of financial objectives, goals, and risk
          tolerance based on Client's responses to the Client Questionnaire and
          shall provide you with investment advice based on such responses in
          the form of recommended Investment(s). Pursuant to the Client's
          selection, the investment and reinvestment of those Client assets are
          subject to Adviser's management (which assets, together with all
          additions, substitutions and / or alterations thereto are hereinafter
          referred to as the "Assets" or "Account").
        </li>
        <li className="ml-14 mb-3">
          Client delegates to Adviser all of its powers with regard to the
          investment and reinvestment of the Assets consistent with the
          Investment(s) selected by Client, and appoints Adviser with limited
          power of attorney to buy, sell, or otherwise effect investment
          transactions involving the Assets in Client's name and for Client's
          Account consistent with such Investment(s).
        </li>
        <li className="ml-14 mb-3">
          Adviser is authorized, without prior consultation with Client, to buy,
          sell, and trade in the Investments, and to give instructions in
          furtherance of such authority to Broker and / or Custodian of the
          Assets consistent with the Investment(s) selected on behalf of Client.
        </li>
        <li className="ml-14 mb-3">
          Periodically, but not less than annually, Adviser shall review, and if
          appropriate, recommend Investments (each an "Update," and
          collectively, the "Updates") with respect to Client's Account Assets.
          Adviser shall provide each Update to Client in an equitable manner
          that allows Client to make reasonable changes to his / her Account,
          consistent with the Client's level of financial objectives, goals, and
          risk tolerance.
        </li>
        <li className="ml-14 mb-3">
          Client delegates to the Adviser authority to retain one or more third
          party investment advisers to provide all or a portion of the
          discretionary management services with respect to the Investments.
          Adviser shall have sole discretion to hire and fire any third-party
          investment advisers that are providing such services without Client
          consent.
        </li>
        <li className="ml-14 mb-3">
          Client agrees to provide information and / or documentation requested
          by Adviser in furtherance of this Agreement as it pertains to Client's
          investment objectives, needs and goals, and to keep Adviser duly
          informed of any changes regarding the same. Client acknowledges that
          Adviser cannot adequately perform its services for Client unless
          Client diligently performs Client's responsibilities under this
          Agreement. Adviser shall not be required to verify any information
          obtained from Client, Client's attorney, accountant or other
          professionals, and is expressly authorized to rely thereon.
        </li>
        <li className="ml-14 mb-3">
          You acknowledge and agree that Adviser will provide only limited
          investment advice described in this Agreement. You acknowledge and
          agree that, at this time, Adviser provides only limited advice and
          discrete investment options and delivers the services pursuant to the
          use of your Whale Application. You acknowledge and agree that while
          some personnel of Adviser who are knowledgeable about Client's Account
          and its management will be available to Client for consultation,
          Adviser will generally not provide investment advice in person or over
          the phone, but will only provide investment advice through the Whale
          Application in accordance with this Agreement. You further acknowledge
          and agree that Adviser may in the future provide you with information
          regarding additional third-party services which you, in your sole
          discretion, may elect to participate in independently from the
          advisory services and you understand that Adviser does not endorse or
          warrant such third-party services by providing you with information
          related to such third-party service providers.
        </li>
      </ol>
      <h3 className="mb-3 text-xl">3. Account Opening</h3>
      <p className="mb-3">
        Concurrent with the execution of this Agreement, Client has appointed
        Broker as its broker and custodian ("Broker" and "Custodian") of the
        Account Assets pursuant to a separate Alpaca Customer Agreement. Client
        agrees to authorize the Custodian to send duplicate statements, notices
        or other information (in hard or electronic copy) to Adviser.
      </p>
      <p className="mb-3">
        Pursuant to this Agreement and the Deposit Control Agreement, Client
        assets and securities will be delivered to the Client by the Adviser
        only, upon the Adviser's determinations regarding the satisfaction of
        all Client obligations under the Lease Agreement and/or pursuant to the
        termination of services provision below.
      </p>
      <p className="mb-3">
        The Account will be held at Broker's carrying and clearing firm, on a
        fully disclosed basis. Upon Broker's approval of the Account and the
        execution of this Agreement, Adviser will transfer the Investable
        Account plus an amount equal to 2% of the Investable Account from the
        Checking Account into the Account by way of ACATs in order to receive
        the discretionary investment advisory services by Adviser.
      </p>
      <h3 className="mb-3 text-xl">4. Limited Account Authority</h3>
      <p className="mb-3">
        Generally, you will be responsible for the Account through the Whale
        Application by: (i) carefully reviewing the information about the
        investment options recommended by Adviser; (ii) carefully considering
        such recommendations that Adviser generates for you based on your
        investment objectives; and (iii) providing Adviser with accurate
        personal financial information, goals and instructions through the
        questionnaire. Moreover, it is your responsibility to continually
        monitor the transfer of funds between your Checking Account and Account
        by using the Whale Application to initiate, schedule, automate, and
        monitor such funds transfers. However, Adviser shall have discretion
        over assets in your Account to the limited extent necessary for Adviser
        to exercise securities trades according to your Client profile based on
        your responses to the questionnaire or pursuant to your request, subject
        to Section 6 below, or for Adviser to manage deposits, withdrawals, and
        transfers between your Account and your Checking Account strictly
        pursuant to, and contingent upon, your direction, or pursuant to the
        Deposit Control Agreement.
      </p>
      <p className="mb-3">
        Given the possibility of liquidation, Client should consult a tax
        professional prior to depositing any securities in the Account. Client
        is solely responsible for any tax liabilities, fees, or third-party
        commissions (e.g., surrender fees, contingent deferred sales charges,
        etc.) triggered by such sales. Adviser will not charge any commission on
        any such sale.
      </p>
      <p className="mb-3">
        Client may impose reasonable restrictions upon the management of the
        Account by requesting that Adviser reallocate to an alternative Model
        Portfolio in place of the current one. Adviser will not accept Client
        requests for restrictions that are inconsistent with Adviser's stated
        investment strategy or philosophy or that are inconsistent with the
        nature or operation of Adviser's investment advisory program.
      </p>
      <h3 className="mb-3 text-xl">5. Account maintenance</h3>
      <p className="mb-3">
        The Account must be funded once before advisory services will begin.
        Once initial funding is received, Adviser will advise, and execute on
        the Account, as applicable, pursuant to this Agreement until a full
        withdrawal request is made by Client to liquidate and close the Account
        ("Termination of Services"). Any disbursement (Client-initiated
        withdrawal or liquidation, Landlord-requested withdrawal or liquidation,
        account statement/trade confirmation fee or other service provider fee)
        that would bring the Account to $0 will be processed as a full
        withdrawal. [Adviser and the Broker hold full discretion to initiate the
        Termination of Services should the account balance be $0 for 2
        consecutive months or more. Client is required to contact Adviser to
        reactivate the Account for investing.]
      </p>
      <p className="mb-3">
        Adviser holds full discretion to place an investing account on hold due
        to failure of an ACH payment. Account status changes may block the
        ability for funds to be invested whilst the account status is being
        reviewed by Adviser.
      </p>
      <p className="mb-3">
        Withdrawal requests requiring the liquidation of securities will be
        processed after being received in good order and will be fulfilled after
        the deduction of any applicable Client obligations under the Lease
        Agreement and the settlement of such liquidating transactions.
      </p>
      <h3 className="mb-3 text-xl">6. Broker and Custodian</h3>
      <p className="mb-3">
        Orders are routed for execution by Broker. Custodian will be the
        exclusive providers of custody services for all Account Assets. Build
        Whale, Inc. will have the authority to deposit, withdraw, and disburse
        funds as necessary to provide the services strictly elected or
        instructed by Client. Adviser will be deemed to have custody over
        Client's funds by way of an intermediary account owned by Build Whale
        ("Whale Intermediary Account"), by which certain Client funds will
        transfer between the Landlord and Broker in order to fulfill certain
        obligations under this Agreement, the Deposit Control Agreement, and the
        Lease Agreement. As such, Adviser will engage an independent auditor
        that is registered with, and is subject to examination by, the Public
        Company Accounting Oversight Board (PCAOB) to conduct surprise annual
        audits of the Whale Intermediary Account. Fees associated with the
        surprise annual audit, as well as all other fees imposed on Client by
        Adviser that are in addition to Adviser fees are described in more
        detail below under this Agreement. By executing this Agreement, Client
        consents to, and authorizes, such additional fees. In addition to the
        surprise annual audit, Client will have access to real time reporting on
        the transactions in the Whale Intermediary Account via the Whale
        Application and will receive at least quarterly statements from Adviser
        and Custodians.
      </p>
      <p className="mb-3">
        You may also direct the Adviser to make transfers between your Checking
        Account and your Account, subject to any maintenance requirements of
        Custodian. You should allow up to 5 business days for Adviser to process
        the instructions you have provided and for the funds transfers to be
        processed.
      </p>
      <h3 className="mb-3 text-xl">7. Account Connections</h3>
      <p className="mb-3">
        In order to make use of Adviser's investment advisory services, you will
        be required to connect your Account to your Checking Account by
        providing true, accurate, current, and complete Checking Account
        credentials. You acknowledge and agree that your Account will not be
        connected to your Checking Account unless and until Adviser receives
        confirmation through the Whale Application that you have successfully
        connected your Account and Checking Account. You further agree that, if
        your Checking Account is temporarily closed or restricted after it is
        successfully connected to your Account, you will have no right under
        this Agreement or the Terms of Service to transfer funds between your
        Checking Account or Account through the Whale Application until: (i)
        your Checking Account is reopened or unrestricted; or (ii) you have
        successfully connected an alternative checking account to your Account.
      </p>
      <h3 className="mb-3 text-xl">8. Prospectuses</h3>
      <p className="mb-3">
        All investments that comprise the Account are subject to the terms of
        the relevant prospectus, including any associated fees charged directly
        by such fund and / or ETFs. An electronic notice will be sent to you via
        the email address you have provided when prospectuses for any such
        Account are available for your review. You acknowledge that it is your
        responsibility to read all prospectuses upon receipt of such notice and
        to notify us immediately of any terms of the prospectuses that are not
        acceptable to you.
      </p>
      <h3 className="mb-3 text-xl">9. Fees</h3>
      <p className="mb-3">
        Adviser will charge Client a fee ("Advisory Fee") of 1% of the yield
        earned in the Account in connection with its investment advisory
        services. To the extent the Account yields less than 2%, the Advisory
        Fee shall consist of 0.5% of such yield. The Advisory Fee is exclusive
        of all brokerage, transaction, custodial, and / or fund fees associated
        with any trades related to the Account. The Advisory Fee will be charged
        monthly in arrears. Adviser will directly debit the Advisory Fee from
        Client's Account.
      </p>
      <p className="mb-3">
        Client will also incur all fees and expenses charged by Broker /
        Custodian related to the establishment, maintenance, and termination of
        the Account, including any returned check and wire fees, telephone
        assisted trading fees, fees for hard copy trade confirmation and account
        statements, tax document fees, all charges associated with electronic
        asset transfers into or out of the Account. Client should review the
        Carrying Agreement to view the full schedule of Broker / Custodian fees.
      </p>
      <h3 className="mb-3 text-xl">10. Directions to Adviser</h3>
      <p className="mb-3">
        All directions by Client to Adviser (including notices, instructions,
        directions relating to changes in Client's investment objectives) shall
        be in writing, via email, or through the Whale Application. Adviser
        shall be fully protected in relying upon any such direction, notice, or
        instruction until it has been duly advised in writing of changes
        therein.
      </p>
      <h3 className="mb-3 text-xl">11. Tax; No ERISA Plan Assets</h3>
      <p className="mb-3">
        Adviser will provide the services under this Agreement without regard to
        any tax consequences that may result from any action taken or omitted by
        Adviser on behalf of the Account. Adviser does not provide tax advice in
        connection with the Account Assets, and Client is solely responsible for
        determining and paying any taxes owed with respect to the activities of
        the Account. Client understands and acknowledges that it is granting
        discretion to Adviser to provide liquidation instructions to the
        Custodian to liquidate at their current market value any securities
        deposited into the Account and that the liquidation of securities in the
        Account may result in a taxable event for Client.
      </p>
      <p className="mb-3">
        Client represents and warrants to Adviser that it is not (and is not
        acting on behalf of or using the assets of): (i) an "employee benefit
        plan" within the meaning of the Employee Retirement Income Security Act
        of 1974, as amended ("ERISA"), whether or not subject to Title I of
        ERISA; (ii) an individual retirement account subject to Section 4975 of
        the Internal Revenue Code of 1986, as amended ("Code"); or (iii) any
        other "plan" subject to Section 4975 of the Code.
      </p>
      <h3 className="mb-3 text-xl">12. Termination of Services</h3>
      <p className="mb-3">
        Either party may terminate this Agreement, in part or in whole, as
        applicable, when notification that the Lease Agreement is terminated by
        Client or Landlord is provided to the Adviser by way of a written
        termination notice. Upon delivery of a termination notice to Adviser,
        Adviser may liquidate Account positions, including but not limited to
        all Account positions that amount to Client obligations to the Landlord
        in connection with the Lease Agreement and ceasing to provide all
        services contemplated by this Agreement. Adviser will inform Broker of
        the termination. Upon termination, Adviser may disburse the assets of
        the Account minus any prorated amount of Advisory Fee earned and any
        Client obligations to the Landlord pursuant to the Lease Agreement.
      </p>
      <p className="mb-3">
        If at any time Adviser receives or develops information indicating that
        Client no longer resides in the United States, Broker may restrict, or
        Adviser may request that Broker restrict the Account. Client may
        provide, or be asked by Adviser to provide, documentation in support of
        a claim of continued permanent residency in the United States for the
        purpose of lifting such restriction. However, if the restriction is not
        resolved within 60 days, Adviser may liquidate all Account positions,
        terminate this Agreement and notify the Broker.
      </p>
      <h3 className="mb-3 text-xl">13. Assignment and Modification</h3>
      <p className="mb-3">
        This Agreement may not be assigned (within the meaning of the Investment
        Advisers Act of 1940 (the "Advisers Act") by either Client or Adviser
        without the prior written consent of the other party. Client
        acknowledges and agrees that transactions that do not result in a change
        of actual control or management of Adviser shall not be considered an
        assignment pursuant to Rule 202(a)(1)-1 under the Advisers Act.
      </p>
      <p className="mb-3">
        Unless expressly stated otherwise, no provision of this Agreement or any
        of the documents referred to herein may be amended, modified,
        supplemented, changed, waived, discharged or terminated, except by a
        writing signed by each party hereto. No failure by Adviser or Client to
        exercise any right, power, or privilege that Adviser or Client may have
        under this Agreement shall operate as a waiver thereof.
      </p>
      <h3 className="mb-3 text-xl">14. Non-Exclusive Management</h3>
      <p className="mb-3">
        Adviser, its officers, employees, and agents, may have or take the same
        or similar positions in specific investments for their own accounts, or
        for the accounts of other clients, as Adviser does for the Account.
        Client expressly acknowledges and understands that Adviser shall be free
        to render investment advice to others and that Adviser does not make its
        portfolio management services available exclusively to Client. Nothing
        in this Agreement shall impose upon Adviser any obligation to purchase
        or sell, or to recommend for purchase or sale, for the Account any
        security which Adviser, its principals, affiliates or employees, may
        purchase or sell for their own accounts or for the account of any other
        Client.
      </p>
      <h3 className="mb-3 text-xl">15. Adviser Representations</h3>
      <p className="mb-3">
        Adviser warrants, represents, and agrees to each of the following:
      </p>
      <ul className="list-disc">
        <li className="ml-14 mb-3">
          Adviser is registered with the U.S. Securities and Exchange Commission
          as an investment adviser under the Advisers Act. Adviser is also in
          compliance with the notice-filing requirements of each jurisdiction in
          which it conducts investment advisory business.
        </li>
        <li className="ml-14 mb-3">
          Adviser will provide investment advisory services in a manner
          consistent with its fiduciary duties and the provisions of all
          applicable laws, including the Advisers Act.
        </li>
      </ul>
      <h3 className="mb-3 text-xl">16. Client Representations</h3>
      <p className="mb-3">
        Client warrants, represents, and agrees to each of the following:
      </p>
      <ul className="list-disc">
        <li className="ml-14 mb-3">
          If Client is a natural person, Client is a legal permanent resident
          of, and resides full-time in, the United States and has the capacity
          to enter into and perform this Agreement, provided, that Client may
          reside outside the United States if Client is active military
          stationed abroad.
        </li>
        <li className="ml-14 mb-3">
          If Client is not a natural person, Client certifies that: (i) it is
          validly organized under the laws of an applicable jurisdiction within
          the United States; and (ii) the person signing this Agreement has full
          and complete authority to execute this Agreement on behalf of Client.
        </li>
        <li className="ml-14 mb-3">
          The terms of this Agreement do not violate any obligations of Client,
          whether arising by contract, operation of law, or otherwise.
        </li>
        <li className="ml-14 mb-3">
          Client owns all property deposited at any time in the Account free and
          clear of any lien or encumbrance and no restriction exists as to any
          disposition of such property.
        </li>
        <li className="ml-14 mb-3">
          Client has received and agreed to receive information electronically
          and use electronic signatures.
        </li>
        <li className="ml-14 mb-3">
          Client will maintain a current, function e-mail address and will
          notify Adviser immediately if an e-mail address or any other contact
          information changes.
        </li>
        <li className="ml-14 mb-3">
          Regardless of whether Adviser sends Client a separate notification via
          e-mail, Client is responsible for regularly reviewing the Whale
          Application for Account-related communications, including but not
          limited to time-sensitive communications, and Client agrees to be
          bound by the terms of such communications.
        </li>
        <li className="ml-14 mb-3">
          Client has received Adviser's Brochure, which describes the roles and
          capacities of Adviser and its representatives and discloses any
          material conflicts that may exist. Client acknowledges that: Adviser
          (i) performs services for other clients; (ii) may make recommendations
          to other clients that differ from recommendations made to Client; and
          (iii) is not obligated to recommend to Client for purchase or sale any
          security or other asset recommended to any other client.
        </li>
        <li className="ml-14 mb-3">
          Client has received Adviser's Form ADV Part 3.
        </li>
        <li className="ml-14 mb-3">
          Client has received Adviser privacy notice.
        </li>
      </ul>
      <h3 className="mb-3 text-xl">17. Confidentiality of Information</h3>
      <p className="mb-3">
        Except as otherwise instructed by Client or as required or permitted by
        law or requested by any governmental or regulatory authority that may
        have jurisdiction over Adviser, Adviser shall keep confidential all
        information concerning Client's identity, financial affairs, and
        investments in accordance with Adviser's privacy policy, as may be
        amended from time to time, with notice to Client. Client permits Adviser
        to provide a copy of this Agreement to the Custodian, any broker, dealer
        or other named interested party of the Account, as such may be required,
        to be used solely as evidence of Adviser's authority to act on behalf of
        Client. In addition, Client understands and acknowledges that Adviser
        may disclose information relating to its investment activities in the
        normal course of business, including information relating to the
        Account, provided that no information identifying Client may be shared.
      </p>
      <p className="mb-3">
        Client agrees that: (a) the Model Portfolio(s) shall remain the
        exclusive property of Adviser; and (b) he / she shall maintain, and
        shall use prudent methods to cause his / her affiliates to maintain, the
        confidentiality and secrecy of the Model Portfolios. Client agrees not
        to make use of the investment recommendations of the Adviser, other than
        with respect to the Account, without the prior written consent of
        Adviser, and will not otherwise disclose the composition of any Model
        Portfolio to a third party; provided, however, that Client may disclose
        such information if: (i) it is required to be disclosed pursuant to a
        requirement of a court order, subpoena, governmental or regulatory
        authority or agency, law, or binding discovery request in pending
        litigation (provided the receiving party will provide the other party
        written notice of such requirement, to the extent such notice is
        permitted); or (ii) it is requested to be disclosed by a governmental or
        regulatory authority or agency
      </p>
      <h3 className="mb-3 text-xl">18. Proxy Voting</h3>
      <p className="mb-3">
        Client shall retain the right and obligation to vote all proxies and
        respond to all corporate actions for securities held in the Account
        unless otherwise mutually agreed upon by the parties in writing.
      </p>
      <h3 className="mb-3 text-xl">19. Death or Disability</h3>
      <p className="mb-3">
        The death or incapacity of Client shall not terminate the authority of
        Adviser granted herein until we receive actual notice of such death or
        incapacity. Upon such notice, your executor, guardian, attorney-in-fact
        or other authorized representative must engage Adviser in order for us
        to continue to service or terminate your Account.
      </p>
      <h3 className="mb-3 text-xl">20. Client Risk Acknowledgment</h3>
      <p className="mb-3">
        Adviser does not guarantee the future performance of Client Account or
        any specific level of performance, the success of any investment
        recommendations that Adviser may make, or the success of Adviser's
        overall advice with respect to the Account. Client understands that
        Client is responsible for any investment decisions made.
      </p>
      <h3 className="mb-3 text-xl">21. Arbitration</h3>
      <p className="mb-3">
        Subject to the conditions and exceptions noted below, and to the extent
        not inconsistent with applicable law, in the event of any dispute
        pertaining to Adviser's services under this Agreement, both Adviser and
        Client agree to submit the dispute to arbitration in accordance with the
        auspices and rules of the American Arbitration Association ("AAA"),
        provided that the AAA accepts jurisdiction. Adviser and Client
        understand that such arbitration shall be final and binding, and that by
        agreeing to arbitration, both Adviser and Client are waiving their
        respective rights to seek remedies in court, including the right to a
        jury trial. Client acknowledges and agrees that in the specific event of
        non-payment of any portion of Adviser compensation, Adviser, in addition
        to the aforementioned arbitration remedy, shall be free to pursue all
        other legal remedies available to it under law, and shall be entitled to
        reimbursement of reasonable attorneys fees and other costs of
        collection.
      </p>
      <p className="mb-3">
        Client understands that this Agreement to arbitrate does not constitute
        a waiver of Client's right to seek a judicial forum where such waiver
        would be void under federal or applicable state securities laws.
      </p>
      <h3 className="mb-3 text-xl">22. Limitation of Liability</h3>
      <p className="mb-3">Client understands and agrees with the following:</p>
      <ul className="list-disc">
        <li className="ml-14 mb-3">
          Adviser gives no warranty as to the performance or profitability of
          any advice provided with respect to the Account or any part thereof,
          nor any guarantee that the investment objectives, expectations or
          targets described in connection with any Model Portfolio will be
          achieved, including without limitation any risk control, risk
          management or return objectives, expectations or targets, or that a
          Model Portfolio will perform comparably with any standard, index or
          benchmark. Client is solely responsible for any decisions made with
          respect to the Account.
        </li>
        <li className="ml-14 mb-3">
          Adviser does not and will not practice law or offer tax or accounting
          services to Client. None of the services offered under this Agreement
          relate to such services and Client must obtain such advice from a
          third party if desired. Any tax information provided by Adviser is not
          a substitute for the advice of a qualified tax advisor.
        </li>
        <li className="ml-14 mb-3">
          Adviser's communications that are not investment recommendations are
          intended to provide Client with general information that may be useful
          to Client's own investment decisions. This general information does
          not address the details of Client's personal situation, and it is not
          intended to be an individualized recommendation that Client take any
          particular action;
        </li>
        <li className="ml-14 mb-3">
          Adviser, any person controlled by Adviser, and their respective
          directors, officers, shareholders, employees and agents in their
          capacities as such (each an "Adviser Party" and collectively, the
          "Adviser Parties") are not liable for any expenses, losses, damages,
          taxes, liabilities, demands, charges or claims of any kind or nature
          whatsoever (including without limitation any legal expenses and costs
          and expenses relating to investigating or defending any demands,
          charges or claims) (collectively, "Losses") arising out of or in
          connection with the performance by Adviser of services hereunder,
          except to the extent that such Losses are actual (and not speculative)
          losses of the Account proven with reasonable certainty and are the
          result of an act or omission taken or omitted by Adviser during the
          term of this Agreement which has been finally determined by a court of
          competent jurisdiction to constitute willful misconduct, negligence or
          fraud. Without limiting the foregoing, Client agrees that the Adviser
          Parties are not liable for Losses arising out of or in connection
          with: (i) any investment decision, recommendation, or other action
          taken or omitted in good faith and in accordance with the standard of
          care described above; (ii) general risks of investing, including those
          set forth in Adviser's Form ADV Part 2 or any materials pursuant to
          which Account Assets are invested; (iii) Adviser's adherence to the
          Investment Objectives; (iv) any act or failure to act by the Custodian
          or by any other independent third party; (v) any action of Client or
          its previous advisers or its other agents; (vi) acts or omissions by
          Adviser in conformity with instructions from any person reasonably
          believed by Adviser to be an authorized person of Client; (vii) force
          majeure or other events beyond the control of Adviser, including
          without limitation any failure, default or delay in performance
          resulting from computer or other electronic or mechanical equipment
          failure, unauthorized access to non-public information, strikes,
          failure of common carrier or utility systems, severe weather,
          pandemics, epidemics, acts of war or terrorism, insurrection,
          revolution, nuclear fusion, fission or radiation, or other causes
          commonly known as "acts of God," whether or not any such cause was
          reasonably foreseeable, provided that Adviser shall notify Client of
          such circumstances as soon as reasonably practicable, and shall
          promptly undertake all reasonable efforts necessary to cure such
          circumstances; (viii) general market conditions, including
          fluctuations in the value of markets and currencies; or (ix) Adviser's
          reliance on asset valuations or market or other data provided by other
          third parties, unless Adviser had actual knowledge that it was not
          reasonable to rely on such valuations or market or other data.
          Additionally, no Adviser Party will be liable for any loss incurred as
          a result of the services provided by Broker or Custodian to Client via
          Client's instructions;
        </li>
        <li className="ml-14 mb-3">
          All investments involve risk, and some investment decisions may result
          in losses, including loss of principal. Adviser does not warrant or
          guarantee that Client's investment objectives will be achieved;
        </li>
        <li className="ml-14 mb-3">
          No Adviser Party will be liable for any loss incurred in the Account
          unless such loss results from such Adviser Party's negligence or
          misconduct. Notwithstanding the foregoing, nothing contained in this
          Agreement shall constitute a waiver by Client of any rights under
          applicable federal or state securities laws including, without
          limitation, the Advisers Act;
        </li>
        <li className="ml-14 mb-3">
          Adviser shall indemnify and hold harmless Client and its affiliates,
          directors, officers, members, managers, employees and agents for any
          direct (as opposed to consequential) actual (as opposed to
          speculative) Losses incurred as a result of Adviser's services
          hereunder, only to the extent that such Losses are directly
          attributable to Adviser's negligence, willful misconduct, fraud or
          criminal wrong-doing or material breach of this Agreement. Client
          shall indemnify and hold harmless the Adviser Parties for any direct
          (as opposed to consequential) actual (as opposed to speculative)
          Losses incurred in connection with providing Adviser's advisory
          services and for any Losses attributable to Client's breach of this
          Agreement, except to the extent that such Losses are directly
          attributable to the negligence, willful misconduct, fraud or criminal
          wrong-doing of Adviser; and
        </li>
        <li className="ml-14 mb-3">
          Client agrees that any disputes relating to the Landlord and/or any
          obligations, including Assets pertaining to the Lease Agreement, shall
          be strictly governed by such Lease Agreement and solely between Client
          and the Landlord. Client further agrees that no Adviser Party,
          Broker/Custodian shall be liable for any disputes relating to the
          Lease Agreement.
        </li>
      </ul>
      <h3 className="mb-3 text-xl">
        23. Consent to Electronic Delivery of Account Information and Documents
        and Use of Electronic Signatures
      </h3>
      <p className="mb-3">
        Client consents to the receipt of all Account-related information and
        documents in electronic form and to the use of electronic signatures in
        connection with any transaction with Adviser. Client has received and
        read, understands, and agrees to the terms. Client understands that if
        Client withdraws such consent, the Account may be terminated. By
        electronically signing an application for an account, Client
        acknowledges and agrees that such electronic signature is valid evidence
        of Client's consent to be legally bound by this Agreement and such
        subsequent terms as may govern the use of Adviser's services. Client
        accepts notice by electronic means as reasonable and proper notice, for
        the purpose of any and all laws, rules and regulations. The
        electronically stored copy of this Agreement is considered to be the
        true, complete, valid, authentic, and enforceable record of the
        Agreement, admissible in judicial or administrative proceedings to the
        same extent as if the documents and records were originally generated
        and maintained in printed form. Client agrees to not contest the
        admissibility or enforceability of Adviser's electronically stored copy
        of the Agreement. Unless otherwise required by law, Adviser reserves the
        right to post Account-related information and documents on its website
        without providing notice to Client. Further, Adviser reserves the right
        to send Account-related information and documents to Client's postal or
        e-mail address of record.
      </p>
      <p className="mb-3">
        Client agrees that delivery by any of the foregoing methods is
        considered personal delivery when sent or posted by Adviser, whether
        Client receives it or not.
      </p>
      <p className="mb-3">
        All e-mail notifications regarding the Account will be sent to Client's
        e-mail address of record. Regardless of whether Client receives an
        e-mail notification, Client agrees that Client is responsible for
        regularly reviewing Adviser website for information related to the
        Account including, without limitation, time-sensitive or otherwise
        important communications. Additionally, Client acknowledges that the
        Internet is not a secure network and agrees that Client will not send
        any confidential information including, without limitation, account
        numbers or passwords, in any unencrypted e-mails. Client also
        understands that communications transmitted over the Internet may be
        accessed by unauthorized or unintended third parties and agrees to hold
        Adviser and its affiliates harmless for any such access, subject to any
        rights Client may have under applicable law.
      </p>
      <p className="mb-3">
        Client agrees to carefully review all documents upon receipt and notify
        Adviser in writing of any objections within 15 business days of receipt.
      </p>
      <ol className="list-[lower-alpha]">
        <li className="ml-14 mb-3">
          Costs. Potential costs associated with electronic delivery of
          Account-related information and documents may include charges from
          internet access providers and telephone companies, and Client agrees
          to bear these costs. Client acknowledges that Client may be charged
          for paper communications if Client's e-mail address is invalid or
          otherwise inoperable.
        </li>
        <li className="ml-14 mb-3">
          Hardware and Software Requirements. Client understands that in order
          to receive electronic disclosures and communications, Client must have
          access to a computer or mobile device with Internet access, a valid
          e-mail address, and the ability to download such applications as
          Adviser may specify and to which Client has access. Client also
          understands that if Client wishes to download, print, or save any
          information Client wishes to retain, Client must have access to a
          printer or other device in order to do so. Client understands that the
          foregoing provisions will be effective when Client creates an online
          account on the Whale Application and consents to do business
          electronically with Adviser.
        </li>
        <li className="ml-14 mb-3">
          Archiving Upon Client's request, or via the Whale Application, Client
          may obtain copies of earlier documents for up to 6 years for account
          statements and 6 years for trade confirmations.
        </li>
      </ol>
      <h3 className="mb-3 text-xl">24. Use of Name</h3>
      <p className="mb-3">
        Neither party shall use the name of the other party without the other
        party's written consent; provided, however, that the Advisor may
        identify Client as a representative client in marketing material without
        obtaining any further consent from Client.
      </p>
      <h3 className="mb-3 text-xl">25. Authority</h3>
      <p className="mb-3">
        Each of the parties to this Agreement represents that it is duly
        authorized and empowered to execute, deliver and perform this Agreement,
        that such action does not materially conflict with or violate any
        provision of law, rule or regulation, contract, deed of trust, or other
        instrument to which it is a party or to which any of its property is
        subject, and that this Agreement is a valid and binding obligation,
        enforceable against such party in accordance with its terms.
      </p>
      <h3 className="mb-3 text-xl">26. Severability</h3>
      <p className="mb-3">
        Any term or provision of this Agreement that is held invalid or
        unenforceable in any jurisdiction shall, as to such jurisdiction, be
        ineffective to the extent of such invalidity or unenforceability without
        rendering invalid or unenforceable the remaining terms or provisions of
        this Agreement or affecting the validity or enforceability of any of the
        terms or provisions of this Agreement in any other jurisdiction.
      </p>
      <h3 className="mb-3 text-xl">27. Governing Law</h3>
      <p className="mb-3">
        To the extent not inconsistent with applicable law, this Agreement shall
        be governed by and construed in accordance with the laws of the State of
        Delaware.
      </p>
      <h3 className="mb-3 text-xl">28. Receipt of Disclosures</h3>
      <p className="mb-3">
        Client hereby acknowledges receipt of Adviser's Privacy Policy Notice
        and a copy of Adviser's written disclosure statement as set forth on
        Part 2 and 3 of Form ADV (Uniform Application for Investment Adviser
        Registration) or otherwise meeting the requirements of Rule 204-3 of the
        Advisers Act, and/or applicable state law.
      </p>
    </div>
  ) : (
    <BaseLoadingScreen message={loadingMessage} />
  );
};
