import { useState } from "react";
import { OnboardingDateOfBirth } from "./OnboardingPersonalDateOfBirth";
import { OnboardingPersonalResidentialAddress } from "./OnboardingPersonalResidentialAddress";
import { PAGE_REVIEW, SECTION_PERSONAL } from "../shared/onboardingConstants";
import { useOnboardingContext } from "../shared/OnboardingContext";
import BottomSheet from "../../components/bottom-sheet/BottomSheet";
import ConfirmInfoField from "../../components/confirm-info-field/ConfirmInfoField";
import { BaseButton } from "../../BaseComponents";
import {
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

type NameType = {
  firstName: string;
  lastName: string;
};

export const OnboardingPersonalDetailsReview = () => {
  const { investorLocal, updateInvestor } = useOnboardingContext();

  const [isSaving, setIsSaving] = useState(false);

  // Legal name
  const [isEditingName, setIsEditingName] = useState(false);
  const [firstName, setFirstName] = useState(
    investorLocal?.legalFirstName || ""
  );
  const [lastName, setLastName] = useState(investorLocal?.legalLastName || "");

  const handleSaveLegalName = ({ firstName, lastName }: NameType) => {
    setIsSaving(true);
    updateInvestor(
      {
        legalFirstName: firstName,
        legalLastName: lastName,
        onboardingPage: `${SECTION_PERSONAL}_${PAGE_REVIEW}`,
      },
      () => {
        setIsSaving(false);
        setFirstName(firstName);
        setLastName(lastName);
        setIsEditingName(false);
      },
      () => {
        setIsSaving(false);
        setIsEditingName(false);
      }
    );
  };

  // Date of Birth
  const [isEditingDateOfBirth, setIsEditingDateOfBirth] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(investorLocal?.dateOfBirth);

  const handleSaveDateOfBirth = ({ dateOfBirth: { date } }: any) => {
    setIsSaving(true);
    updateInvestor(
      {
        dateOfBirth: date,
        onboardingPage: `${SECTION_PERSONAL}_${PAGE_REVIEW}`,
      },
      () => {
        setIsSaving(false);
        setDateOfBirth(date);
        setIsEditingDateOfBirth(false);
      },
      () => {
        setIsSaving(false);
        setIsEditingDateOfBirth(false);
      }
    );
  };

  // Address
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [address, setAddress] = useState(investorLocal?.address);

  const handleSaveAddress = ({ address }: any) => {
    setIsSaving(true);
    updateInvestor(
      { address, onboardingPage: `${SECTION_PERSONAL}_${PAGE_REVIEW}` },
      () => {
        setIsSaving(false);
        setAddress(address);
        setIsEditingAddress(false);
      },
      () => {
        setIsSaving(false);
        setIsEditingAddress(false);
      }
    );
  };

  return (
    <div className="w-full h-full sm:flex sm:items-center sm:justify-center">
      <div className="w-full max-w-xl p-5">
        <div>
          <h1 className="text-3xl mb-4 sm:text-4xl sm:mb-10">
            Personal Information
          </h1>
          {firstName && (
            <ConfirmInfoField
              name="Legal Name"
              onClick={() => setIsEditingName(true)}
              value={`${firstName} ${lastName}`}
              infoPopover="Must match legal name on your tax forms."
            />
          )}
          {dateOfBirth && (
            <>
              <Divider className="my-6" />
              <ConfirmInfoField
                name="Date of Birth"
                onClick={() => setIsEditingDateOfBirth(true)}
                value={new Date(dateOfBirth).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              />
            </>
          )}
          {address && (
            <>
              <Divider className="my-6" />
              <ConfirmInfoField
                name="Permanent Residential Address"
                onClick={() => setIsEditingAddress(true)}
                value={
                  <address className="not-italic">
                    {address.thoroughfare}
                    {address.premise && `, ${address.premise}`}
                    <br />
                    {address.locality}, {address.administrativeArea}{" "}
                    {address.postalCode}
                  </address>
                }
              />
            </>
          )}
          {isEditingName && (
            <EditLegalName
              initial={{ firstName, lastName }}
              isOpen={isEditingName}
              isSaving={isSaving}
              onClose={() => setIsEditingName(false)}
              onSave={handleSaveLegalName}
            />
          )}
          {isEditingDateOfBirth && (
            <EditDateOfBirth
              initial={{ dateOfBirth }}
              isOpen={isEditingDateOfBirth}
              isSaving={isSaving}
              onClose={() => setIsEditingDateOfBirth(false)}
              onSave={handleSaveDateOfBirth}
            />
          )}
          {isEditingAddress && (
            <EditAddress
              isOpen={isEditingAddress}
              isSaving={isSaving}
              onClose={() => setIsEditingAddress(false)}
              onSave={handleSaveAddress}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const EditLegalName = ({ isOpen, isSaving, onClose, onSave, initial }: any) => {
  const [firstName, setFirstName] = useState(initial.firstName);
  const [lastName, setLastName] = useState(initial.lastName);

  return (
    <>
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}
        title="Legal Name"
        className="sm:hidden"
      >
        <p className="text-griselda font-light">
          Must match legal name on your tax forms.
        </p>
        <Input
          classNames={{
            base: "mt-5",
            label: "tracking-widest text-griselda font-semibold mb-2",
            input: "text-sage font-light text-md",
          }}
          variant="underlined"
          label="FIRST NAME"
          onValueChange={setFirstName}
          value={firstName}
        />
        <Input
          classNames={{
            base: "mt-5",
            label: "tracking-widest text-griselda font-semibold mb-2",
            input: "text-sage font-light text-md",
          }}
          variant="underlined"
          label="LAST NAME"
          onValueChange={setLastName}
          value={lastName}
        />
        <BaseButton
          className="w-full mt-7"
          isDisabled={!firstName || !lastName}
          isLoading={isSaving}
          onPress={() => onSave({ firstName, lastName })}
        >
          Save
        </BaseButton>
      </BottomSheet>
      <Modal
        className="hidden sm:flex"
        hideCloseButton={isSaving}
        isDismissable={false}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ModalContent>
          <ModalHeader className="text-3xl">Legal Name</ModalHeader>
          <ModalBody>
            <p className="text-griselda font-light">
              Must match legal name on your tax forms.
            </p>
            <Input
              classNames={{
                base: "mt-5",
                label: "tracking-widest text-griselda font-semibold mb-2",
                input: "text-sage font-light text-md",
              }}
              variant="underlined"
              label="FIRST NAME"
              onValueChange={setFirstName}
              value={firstName}
            />
            <Input
              classNames={{
                base: "mt-5",
                label: "tracking-widest text-griselda font-semibold mb-2",
                input: "text-sage font-light text-md",
              }}
              variant="underlined"
              label="LAST NAME"
              onValueChange={setLastName}
              value={lastName}
            />
          </ModalBody>
          <ModalFooter>
            <BaseButton
              className="w-full"
              isDisabled={!firstName || !lastName}
              isLoading={isSaving}
              onPress={() => onSave({ firstName, lastName })}
            >
              Save
            </BaseButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditDateOfBirth = ({ isOpen, isSaving, onClose, onSave }: any) => {
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);

  return (
    <>
      <BottomSheet isOpen={isOpen} onClose={onClose} className="sm:hidden">
        <OnboardingDateOfBirth onChange={setDateOfBirth} />
        <BaseButton
          className="w-full mt-7"
          isDisabled={dateOfBirth ? !dateOfBirth.isValid : true}
          isLoading={isSaving}
          onPress={() => onSave({ dateOfBirth })}
        >
          Save
        </BaseButton>
      </BottomSheet>
      <Modal
        className="hidden sm:flex"
        hideCloseButton={isSaving}
        isDismissable={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalContent>
          <ModalBody>
            <OnboardingDateOfBirth onChange={setDateOfBirth} />
          </ModalBody>
          <ModalFooter>
            <BaseButton
              className="w-full mt-7"
              isDisabled={dateOfBirth ? !dateOfBirth.isValid : true}
              isLoading={isSaving}
              onPress={() => onSave({ dateOfBirth })}
            >
              Save
            </BaseButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditAddress = ({ isOpen, isSaving, onClose, onSave }: any) => {
  const [address, setAddress] = useState<any>(null);

  return (
    <>
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}
        title="Residential Address"
        className="sm:hidden"
      >
        <div className="min-h-96">
          <OnboardingPersonalResidentialAddress
            clearSelection={() => setAddress(null)}
            onSelect={setAddress}
            selected={address}
            forModal={true}
          />
        </div>
        <BaseButton
          className="w-full mt-7"
          isDisabled={!address}
          isLoading={isSaving}
          onPress={() => onSave({ address })}
        >
          Save
        </BaseButton>
      </BottomSheet>
      <Modal
        className="hidden sm:flex"
        hideCloseButton={isSaving}
        isDismissable={false}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ModalContent>
          <ModalHeader className="text-3xl">Residential Address</ModalHeader>
          <ModalBody>
            <div className="min-h-96">
              <OnboardingPersonalResidentialAddress
                clearSelection={() => setAddress(null)}
                onSelect={setAddress}
                selected={address}
                forModal={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <BaseButton
              className="w-full mt-7"
              isDisabled={!address}
              isLoading={isSaving}
              onPress={() => onSave({ address })}
            >
              Save
            </BaseButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
