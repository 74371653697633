import { useState } from "react";
import {
  Button,
  Navbar,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import { WhaleLogo } from "../helpers/Svgs";
import { NavLink } from "react-router-dom";
import { BaseButton } from "../BaseComponents";

export const WebsiteNavbar = ({
  primaryCta,
  secondaryCta,
  forceWhiteNavbar,
}: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [useWhiteNavbar, setUseWhiteNavbar] = useState(
    window.scrollY > window.innerHeight
  );

  const isWhiteNavbar = forceWhiteNavbar || useWhiteNavbar;

  const handleScrollNavbar = (position: number) => {
    setUseWhiteNavbar(position > window.innerHeight);
  };

  const navbarClass = isWhiteNavbar
    ? "z-[100001] transition-colors bg-white border-sage text-black"
    : "z-[100001] transition-colors bg-midnight border-griselda text-white";

  const activeClassName = isWhiteNavbar
    ? "pt-8 sm:pt-0 font-['Outfit'] sm:font-['Switzer'] w-fit text-4xl sm:text-base flex relative h-full items-center after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:rounded-[2px] after:bg-midnight text-midnight"
    : "pt-8 sm:pt-0 font-['Outfit'] sm:font-['Switzer'] w-fit text-4xl sm:text-base flex relative h-full items-center after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:rounded-[2px] after:bg-white text-white";

  const inactiveClassName = isWhiteNavbar
    ? "pt-8 sm:pt-0 font-['Outfit'] sm:font-['Switzer'] w-fit text-4xl sm:text-base flex relative h-full items-center text-griselda"
    : "pt-8 sm:pt-0 font-['Outfit'] sm:font-['Switzer'] w-fit text-4xl sm:text-base flex relative h-full items-center text-sage";

  const navmenuClass = isWhiteNavbar
    ? "flex justify-between border-t-1 bg-white border-sage text-black"
    : "flex justify-between border-t-1 bg-midnight border-griselda text-white";

  return (
    <Navbar
      className={navbarClass}
      height="80px"
      isBordered
      isMenuOpen={isMenuOpen}
      maxWidth="full"
      onMenuOpenChange={setIsMenuOpen}
      onScrollPositionChange={handleScrollNavbar}
    >
      <NavbarMenuToggle
        aria-label={true ? "Close menu" : "Open menu"}
        className="sm:hidden"
      />
      {!isMenuOpen && (
        <NavbarBrand>
          <NavLink to="/">
            <WhaleLogo color={isWhiteNavbar ? "#1F2B2B" : "#ADBCAD"} />
          </NavLink>
        </NavbarBrand>
      )}
      <NavbarContent className="switzer hidden sm:flex gap-11" justify="center">
        <NavLink
          className={({ isActive }) =>
            isActive ? activeClassName : inactiveClassName
          }
          to="/"
        >
          Renters
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? activeClassName : inactiveClassName
          }
          to="/properties"
        >
          Properties
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? activeClassName : inactiveClassName
          }
          to="/about"
        >
          About
        </NavLink>
      </NavbarContent>
      {!isMenuOpen && (
        <NavbarContent justify="end">
          {isWhiteNavbar && primaryCta && (
            <NavbarItem>
              <BaseButton
                className="text-lg rounded-lg"
                size="md"
                as={NavLink}
                to={primaryCta.to}
              >
                {primaryCta.text}
              </BaseButton>
            </NavbarItem>
          )}
          {secondaryCta && (
            <NavbarItem>
              <Button
                className={
                  isWhiteNavbar
                    ? "switzer text-lg font-semibold text-midnight rounded-lg border-1 border-strokeGrey"
                    : "switzer text-lg font-semibold text-white rounded-lg border-1 border-sage"
                }
                size="md"
                as={NavLink}
                to={secondaryCta.to}
                variant="bordered"
              >
                {secondaryCta.text}
              </Button>
            </NavbarItem>
          )}
        </NavbarContent>
      )}
      <NavbarMenu className={navmenuClass}>
        <div className="grow flex flex-col justify-center">
          <NavbarMenuItem>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
              to="/"
            >
              Renters
            </NavLink>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
              to="/properties"
            >
              Properties
            </NavLink>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : inactiveClassName
              }
              to="/about"
            >
              About
            </NavLink>
          </NavbarMenuItem>
        </div>
        <div className="py-6">
          <div className="grid gap-5 grid-rows-1 grid-cols-2 mb-4">
            <Button
              className="w-full lg:w-fit switzer text-lg font-semibold text-midnight rounded-lg bg-brightGreen border-0"
              size="lg"
              as={NavLink}
              to="/login"
              variant="bordered"
            >
              Sign Up
            </Button>
            <Button
              className={
                isWhiteNavbar
                  ? "w-full lg:w-fit switzer text-lg font-semibold text-midnight rounded-lg border-1 border-sage"
                  : "w-full lg:w-fit switzer text-lg font-semibold text-white rounded-lg border-1 border-sage"
              }
              size="lg"
              as={NavLink}
              to="/login"
              variant="bordered"
            >
              Login
            </Button>
          </div>
          <p className={isWhiteNavbar ? "text-midnight" : "text-white"}>
            Are you a property?{" "}
            <NavLink
              to="https://cal.com/whale/demo"
              target="_blank"
              rel="noreferrer"
              className="font-semibold underline"
            >
              Request Demo
            </NavLink>
          </p>
        </div>
      </NavbarMenu>
    </Navbar>
  );
};
