import { useEffect } from "react";
import { WebsiteFooter } from "../WebsiteFooter";
import { WebsiteNavbar } from "../WebsiteNavbar";
import { WebsiteTeamBios } from "./WebsiteTeamBios";
import { WebsiteTeamSplash } from "./WebsiteTeamSplash";

export const WebsiteTeam = () => {
  useEffect(() => {
    document.title = "Whale | Team";
  }, []);

  return (
    <div>
      <WebsiteNavbar forceWhiteNavbar />
      <WebsiteTeamSplash />
      <WebsiteTeamBios />
      <WebsiteFooter />
    </div>
  );
};
