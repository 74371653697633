import { useEffect } from "react";
import { WebsiteFooter } from "../WebsiteFooter";
import { WebsiteNavbar } from "../WebsiteNavbar";
import { WebsiteCareersBenefits } from "./WebsiteCareersBenefits";
import { WebsiteCareersOpenRoles } from "./WebsiteCareersOpenRoles";
import { WebsiteCareersSplash } from "./WebsiteCareersSplash";

export const WebsiteCareers = () => {
  useEffect(() => {
    document.title = "Whale | Careers";
  }, []);
  return (
    <div>
      <WebsiteNavbar forceWhiteNavbar />
      <WebsiteCareersSplash />
      <WebsiteCareersBenefits />
      <WebsiteCareersOpenRoles />
      <WebsiteFooter />
    </div>
  );
};
