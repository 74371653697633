import { Accordion, AccordionItem } from "@nextui-org/react";
import { CaretDown } from "@phosphor-icons/react";

const FAQ_ITEMS = [
  {
    title: "What is Whale Deposit?",
    key: "1",
    content: (
      <>
        <p className="mb-4">
          Whale Deposit offers renters the ability to earn interest on their
          rental deposit.
        </p>
        <p className="mb-4">
          The deposit is held in cash so it grows completely risk-free. You can
          earn around 3-4% on your deposit amount, depending on the market
          conditions.
        </p>
        <p>
          The deposit is made available immediately after the landlord notifies
          Whale of any move-out charges. No more waiting for checks in the mail!
        </p>
      </>
    ),
  },
  {
    title: "How do I know I can trust Whale?",
    key: "2",
    content: (
      <>
        <p>
          Whale is fully governed by the Securities Exchange Commission (
          <a
            className="underline"
            href="https://www.sec.gov"
            rel="noreferrer"
            target="_blank"
          >
            SEC
          </a>
          ) and Whale Deposit accounts are protected by the Securities Investor
          Protection Corporation (
          <a
            className="underline"
            href="https://www.sipc.org"
            rel="noreferrer"
            target="_blank"
          >
            SIPC
          </a>
          ), which insures each account up to $250,000.
        </p>
        <p className="mt-4">
          Because Whale has to guarantee the rental deposit to your landlord in
          case there are any move-out charges, it cannot be invested. Your
          rental deposit is held in cash, which earns interest risk-free.
        </p>
        <p className="mt-4">
          Still have questions? Reach out to your landlord to confirm Whale is a
          trusted and approved partner.
        </p>
      </>
    ),
  },
  {
    title: "What happens when I move out?",
    key: "3",
    content: (
      <>
        <p className="mb-4">
          Your landlord will notify Whale of any move-out charges, such as
          damages or missed rent, which will be deducted from your deposit.
        </p>
        <p>
          The remaining balance will be instantly available - no more waiting
          for checks in the mail!
        </p>
      </>
    ),
  },
  {
    title: "How does Whale make money?",
    key: "4",
    content: (
      <p>
        For new leases only, there's a one-time processing fee to use Whale.
        Renewing a lease or moving your deposit to Whale for an existing lease
        is completely free.
      </p>
    ),
  },
  {
    title: "Are there any fees to use Whale?",
    key: "5",
    content: (
      <>
        <p className="mb-4">
          There is a one-time processing fee for every new lease you sign using
          Whale.
        </p>
        <p className="mb-4">
          There are <span className="font-semibold">no fees</span> for renewing
          a lease or moving your deposit to Whale for an existing lease.
        </p>
        <p>
          There are <span className="font-semibold">no other fees</span> to use
          Whale. That means no ongoing management fees, no bank transfer fees,
          and no other surprise fees.
        </p>
      </>
    ),
  },
];

export const WebsiteFaq = () => (
  <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-32">
    <h2 className="text-7xl md:text-8xl">FAQs</h2>
    <p className="text-griselda my-5 text-lg md:text-2xl">
      Below are common questions about Whale, but if you need additional help
      please email{" "}
      <a href="mailto:support@getwhale.co" className="font-bold underline">
        support@getwhale.co
      </a>
    </p>
    <div className="-mx-2">
      <Accordion>
        {FAQ_ITEMS.map(({ content, key, title }) => (
          <AccordionItem
            aria-label={title}
            indicator={<CaretDown size={32} className="fill-midnight" />}
            key={key}
            title={
              <p className="text-lg md:text-2xl font-medium text-midnight my-3">
                {title}
              </p>
            }
          >
            <div className="text-griselda text-lg md:text-2xl mb-6">
              {content}
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  </div>
);
