import { WebsiteFaq } from "./WebsiteFaq";
import { WebsiteFooter } from "./WebsiteFooter";
import { WebsitePartnerships } from "./WebsitePartnerships";
import { WebsiteNavbar } from "./WebsiteNavbar";
import { WebsiteSplash } from "./WebsiteSplash";
import { WebsiteValueProps } from "./WebsiteValueProps";
import { WebsiteComparison } from "./WebsiteComparison";
import { WebsiteHowItWorks } from "./WebsiteHowItWorks";
import { WebsiteTestimonials } from "./WebsiteTestimonials";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export const Website = () => {
  useEffect(() => {
    document.title = "Whale";
  }, []);

  return (
    <div>
      <WebsiteNavbar
        primaryCta={{ text: "Sign Up", to: "/login" }} // TODO - navigation to /signup when we split flows
        secondaryCta={{ text: "Log In", to: "/login" }}
      />
      <WebsiteSplash
        cta={{ text: "Sign Up", to: "/login" }} // TODO - navigation to /signup when we split flows
        heading={
          <h1 className="text-5xl sm:text-7xl lg:text-8xl 2xl:text-9xl text-white">
            Earn cash on <br className="inline lg:hidden" /> your{" "}
            <br className="hidden lg:inline" /> rental deposit
          </h1>
        }
        subheading={
          <h2 className="text-lg sm:text-2xl md:text-3xl 2xl:text-[46px] text-sage font-light mt-3 sm:mt-6">
            Grow your money risk-free with Whale Deposit
          </h2>
        }
        subtitle={
          <h2 className="text-base sm:hidden text-sage font-light mt-3">
            Are you a property?{" "}
            <NavLink to="/properties" className="underline">
              Learn More
            </NavLink>
          </h2>
        }
      />
      <WebsiteValueProps />
      <WebsiteHowItWorks />
      <WebsiteComparison />
      <WebsitePartnerships />
      <WebsiteFaq />
      <WebsiteFooter />
    </div>
  );
};
