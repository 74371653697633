import { Button } from "@nextui-org/react";
import buildingCircle from "../../images/building-circle.png";
import { NavLink } from "react-router-dom";

export const WebsiteAboutPropertyBenefits = () => (
  <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2 mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-48">
    <div className="flex items-center">
      <div>
        <h2 className="font-light text-4xl lg:text-6xl mb-8">Property Benefits</h2>
        <p className="mb-4 text-lg text-griselda">
          Landlords offload the work of handling security deposits to Whale
          while still having the deposit amount guaranteed to cover any move-out
          charges.
        </p>
        <Button
          className="w-full lg:w-fit switzer text-lg font-semibold text-midnight rounded-lg border-1 border-midnight"
          size="lg"
          as={NavLink}
          to="/properties"
          variant="bordered"
        >
          Learn More
        </Button>
      </div>
    </div>
    <div className="flex justify-center order-first lg:order-last">
      <img src={buildingCircle} alt="iPhone" width="90%" className="max-w-xl" />
    </div>
  </div>
);
