import { useCallback, useEffect, useState } from "react";
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";
import {
  fetchPlaidLinkToken,
  submitPlaidPublicToken,
} from "../onboarding/shared/onboardingApi";
import { USD_NUMBER_FORMAT } from "../constants";
import { BaseButton, BaseErrorMessage } from "../BaseComponents";
import { PlaidLogo } from "../helpers/Svgs";
import { fetchBankAccountInfo } from "./accountApi";

type Props = {
  onSuccessfulLink: () => void;
  withdrawAmount: number;
  onSubmit: () => void;
};

export const AccountPlaid = ({
  onSuccessfulLink,
  onSubmit,
  withdrawAmount,
}: Props) => {
  const [plaidAccount, setPlaidAccount] = useState<any>();
  const [linkToken, setLinkToken] = useState("");
  const [hasError, setHasError] = useState(false);

  const onSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      setPlaidAccount({
        institutionName: metadata?.institution?.name,
        accountMaskNumber: metadata.accounts[0].mask,
      });
      submitPlaidPublicToken({ publicToken: public_token })
        .then(() => {
          onSuccessfulLink();
        })
        .catch(() => {
          setHasError(true);
          console.log("There was an error submitting the plaid public token");
        });
    },
    [onSuccessfulLink]
  );

  const { open, ready } = usePlaidLink({ token: linkToken, onSuccess });

  useEffect(() => {
    fetchBankAccountInfo().then((data) => {
      if (data) {
        setPlaidAccount(data);
      } else {
        fetchPlaidLinkToken()
          .then((data: { linkToken: string }) => {
            setLinkToken(data.linkToken);
          })
          .catch(() => {
            setHasError(true);
            console.log("There was an error fetching the plaid link token");
          });
      }
    });
  }, []);

  return (
    <div className="w-full sm:h-full max-w-xl m-auto p-5 flex items-center justify-center">
      <div>
        {plaidAccount ? (
          <>
            <h1 className="text-3xl mb-4">Bank Account</h1>
            <p className="font-light text-griselda">
              Whale will transfer{" "}
              <span className="font-semibold">
                {USD_NUMBER_FORMAT.format(withdrawAmount)}
              </span>{" "}
              to the bank account you connect.
            </p>
            <div className="p-3 bg-warmGrey rounded-lg border-solid border border-strokeGrey mt-5">
              <p className="font-semibold text-midnight">
                {plaidAccount.institutionName}
              </p>
              <p className="font-light text-griselda">
                Account ending in ***{plaidAccount.accountMaskNumber}
              </p>
            </div>
            <BaseButton className="mt-8 w-full" onPress={onSubmit}>
              Transfer {USD_NUMBER_FORMAT.format(withdrawAmount)}
            </BaseButton>
            <p className="text-center text-griselda font-light mt-4">
              Expected in 1-3 business days
            </p>
          </>
        ) : (
          <>
            <h1 className="text-3xl mb-4">Link Your Bank Account</h1>
            <p className="font-light text-griselda">
              Whale will transfer{" "}
              <span className="font-semibold">
                {USD_NUMBER_FORMAT.format(withdrawAmount)}
              </span>{" "}
              to the bank account you connect.
            </p>
            <BaseButton
              className="w-full my-5"
              isLoading={!ready}
              onPress={() => open()}
            >
              Connect Account
            </BaseButton>
            <div className="flex items-center">
              <p className="font-light text-griselda mr-2">Supported by</p>{" "}
              <PlaidLogo />
            </div>
          </>
        )}
      </div>
      <BaseErrorMessage isOpen={hasError} onClose={() => setHasError(false)} />
    </div>
  );
};
