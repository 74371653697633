import jamie from "../../images/team/jamie.png";
import casey from "../../images/team/casey.png";
import zack from "../../images/team/zack.png";
import emi from "../../images/team/emi.png";
import raymond from "../../images/team/raymond.png";
import admas from "../../images/team/admas.png";
import adam from "../../images/team/adam.png";
import chris from "../../images/team/chris.png";
import zac from "../../images/team/zac.png";
import james from "../../images/team/james.png";
import evan from "../../images/team/evan.png";
import { ArrowUpRight } from "@phosphor-icons/react";

const PEOPLE = [
  {
    imgSrc: jamie,
    name: "Jamie Petraglia",
    title: "Founder and CEO",
    linkedin: "https://www.linkedin.com/in/jamie-petraglia-673b074b/",
  },
  {
    imgSrc: casey,
    name: "Casey Hingtgen",
    title: "VP, Technology & Operations",
    linkedin: "https://www.linkedin.com/in/hingtgen/",
  },
  {
    imgSrc: zack,
    name: "Zack White",
    title: "Product Design Lead",
    linkedin: "https://www.linkedin.com/in/linkzackwhite/",
  },
  {
    imgSrc: emi,
    name: "Emi Knight",
    title: "Product Design Lead",
    linkedin: "https://www.linkedin.com/in/emiknight/",
  },
  {
    imgSrc: raymond,
    name: "Raymond O'Connor",
    title: "Senior Software Engineer",
    linkedin: "https://www.linkedin.com/in/roconnorc/",
  },
  {
    imgSrc: admas,
    name: "Admas Kebede",
    title: "Software Engineer",
    linkedin: "https://www.linkedin.com/in/admas-kebede/",
  },
  {
    imgSrc: adam,
    name: "Adam Chaput",
    title: "VP, Product",
    linkedin: "jttps://www.linkedin.com/in/adamjchaput/",
  },
  {
    imgSrc: chris,
    name: "Chris Traver",
    title: "Lead Frontend Software Engineer",
    linkedin: "https://www.linkedin.com/in/chrisjtraver/",
  },
  {
    imgSrc: zac,
    name: "Zac Saunders",
    title: "Lead Software Engineer",
    linkedin: "https://www.linkedin.com/in/zrsaunders/",
  },
  {
    imgSrc: james,
    name: "James Wyman",
    title: "VP, Finance",
    linkedin: "https://www.linkedin.com/in/jameswyman/",
  },
  {
    imgSrc: evan,
    name: "Evan Cooper",
    title: "Principal Engineer",
    linkedin: "https://www.linkedin.com/in/builtbycooper/",
  },
];

export const WebsiteTeamBios = () => {
  return (
    <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-8 lg:my-48">
      <div className="grid gap-5 lg:gap-10 grid-rows-1 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {PEOPLE.map(({ name, imgSrc, title, linkedin }) => (
          <a href={linkedin} target="_blank" rel="noreferrer" key={name}>
            <img src={imgSrc} alt={`${name} headshot`} />
            <div className="flex flex-col lg:flex-row justify-between mt-3">
              <div>
                <p className="font-semibold">{name}</p>
                <p className="text-griselda font-light">{title}</p>
              </div>
              <ArrowUpRight size={32} />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
