export const SECTION_WELCOME = "SECTION_WELCOME";
export const SECTION_PERSONAL = "SECTION_PERSONAL";
export const SECTION_CITIZENSHIP = "SECTION_CITIZENSHIP";
export const SECTION_FINANCIAL = "SECTION_FINANCIAL";
export const SECTION_SUBMISSION = "SECTION_SUBMISSION";

export const PAGE_FIRST = "PAGE_FIRST";
export const PAGE_LAST = "PAGE_LAST";
export const PAGE_REVIEW = "PAGE_REVIEW";

export const USA_COUNTRY_CODE = "USA";

export const ORGANIC_DEPOSIT = "ORGANIC_DEPOSIT";
