import { useLoaderData } from "react-router-dom";
import {
  AccountRentalDepositsTable,
  DepositType,
} from "./AccountRentalDepositsTable";

type LoaderDataType = {
  deposits: DepositType[];
};

export const AccountRentalDeposits = () => {
  const { deposits } = useLoaderData() as LoaderDataType;
  return (
    <div className="p-5 md:p-10">
      <h1 className="text-3xl md:text-5xl mb-4 md:mb-8">Rental Deposits</h1>
      <AccountRentalDepositsTable deposits={deposits} />
    </div>
  );
};
