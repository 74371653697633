import "./index.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Account } from "./account/Account";
import { AccountOverview } from "./account/AccountOverview";
// import { AccountProfile } from "./account/AccountProfile";
import { AccountRentalDeposits } from "./account/AccountRentalDeposits";
import { AccountTransactions } from "./account/AccountTransactions";
import { InvestmentAdvisoryAgreement } from "./onboarding/legal/InvestmentAdvisoryAgreement";
import { LeaseAddendum } from "./onboarding/legal/LeaseAddendum";
import { LoginInputEmail } from "./login/LoginInputEmail";
import { LoginVerifyMagicLink } from "./login/LoginVerifyMagicLink";
import { Onboarding } from "./onboarding";
import { SecuritiesAccountControlAgreement } from "./onboarding/legal/SecuritiesAccountControlAgreement";
import { Website } from "./website";
import {
  fetchDeposits,
  fetchOverview,
  fetchTransactions,
} from "./account/accountApi";
import { WebsiteAbout } from "./website/about";
import { WebsiteCareers } from "./website/careers";
import { WebsiteProperties } from "./website/properties";
import { WebsiteTeam } from "./website/team";
import { AccountWithdraw } from "./account/AccountWithdraw";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Website />,
  },
  {
    path: "properties",
    element: <WebsiteProperties />,
  },
  {
    path: "about",
    element: <WebsiteAbout />,
  },
  {
    path: "careers",
    element: <WebsiteCareers />,
  },
  {
    path: "team",
    element: <WebsiteTeam />,
  },
  {
    path: "verify",
    element: <LoginVerifyMagicLink />,
  },
  {
    path: "join",
    element: <Onboarding />,
  },
  {
    path: "account",
    element: <Account />,
    children: [
      {
        path: "overview",
        element: <AccountOverview />,
        loader: fetchOverview,
      },
      {
        path: "deposits",
        element: <AccountRentalDeposits />,
        loader: fetchDeposits,
      },
      {
        path: "transactions",
        element: <AccountTransactions />,
        loader: fetchTransactions,
      },
      // {
      //   path: "profile",
      //   element: <AccountProfile />,
      // },
    ],
  },
  {
    path: "withdraw",
    element: <AccountWithdraw />,
    loader: fetchOverview,
  },
  {
    path: "login",
    element: <LoginInputEmail />,
  },
  {
    path: "join/investment-advisory-agreement",
    element: <InvestmentAdvisoryAgreement />,
  },
  {
    path: "join/securities-account-control-agreement",
    element: <SecuritiesAccountControlAgreement />,
  },
  {
    path: "join/lease-addendum",
    element: <LeaseAddendum />,
  },
]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
