import { PencilSimple } from "@phosphor-icons/react";
import { Input } from "@nextui-org/react";
import { InvestorAddressType } from "../onboardingTypes";
import AddressAutocomplete, {
  AddressAutocompleteType,
} from "../../components/address-autocomplete/AddressAutocomplete";
import { convertCodeToCode3 } from "../../components/country-list/CountryListHelpers";

type Props = {
  clearSelection: () => void;
  onSelect: (address: InvestorAddressType) => void;
  selected: InvestorAddressType | null;
  forModal?: boolean;
};

export const OnboardingPersonalResidentialAddress = ({
  selected,
  onSelect,
  clearSelection,
  forModal = false,
}: Props) => {
  const className = forModal
    ? "w-full h-full max-w-xl m-auto flex min-h-80"
    : "w-full h-full max-w-xl m-auto p-5 flex";

  const handleSelect = (address: AddressAutocompleteType) => {
    onSelect({
      administrativeArea: address.stateCode,
      country: convertCodeToCode3(address.countryCode),
      locality: address.city,
      postalCode: address.postalCode,
      thoroughfare: `${address.number} ${address.street}`,
      premise: "",
    });
  };

  return (
    <div className={className}>
      <div className="w-full">
        {!forModal && (
          <h1 className="text-3xl mb-2 sm:text-4xl sm:mt-10 text-midnight">
            Residential Address
          </h1>
        )}
        <p className="mb-5 font-light text-griselda">
          Most recent United States address
        </p>
        {selected ? (
          <>
            <div className="flex justify-between items-center p-3 rounded-lg border-solid border border-strokeGrey mb-5">
              <address className="not-italic font-light">
                {selected.thoroughfare}
                <br />
                {selected.locality}, {selected.administrativeArea}{" "}
                {selected.postalCode}
              </address>
              <button
                className="flex size-11 items-center justify-center rounded-full ml-4 bg-warmGrey text-griselda hover:bg-taupe"
                onClick={() => clearSelection()}
                type="button"
              >
                <PencilSimple size={25} />
              </button>
            </div>
            <Input
              classNames={{
                input: "text-md",
                label: "tracking-widest text-griselda font-semibold",
              }}
              defaultValue={selected.premise}
              variant="underlined"
              label="UNIT OR APT NUMBER"
              placeholder=" "
              onValueChange={(value) =>
                onSelect({ ...selected, premise: value })
              }
            />
          </>
        ) : (
          <AddressAutocomplete onSelect={handleSelect} />
        )}
      </div>
    </div>
  );
};
