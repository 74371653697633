import { Player } from "@lottiefiles/react-lottie-player";
import { useOnboardingContext } from "../shared/OnboardingContext";
import coffeeCupAnimation from "../../images/coffeeCupAnimation.json";
import OnboardingNavbar from "../../components/onboarding-navbar/OnboardingNavbar";

export const OnboardingSubmission = () => {
  const {
    isOrganic,
    propertyLocal: { propertyMarketingName },
  } = useOnboardingContext();
  return (
    <>
      <OnboardingNavbar
        showBack={false}
        showContinue={false}
        showProgressBar={false}
      />
      <div className="w-full h-full max-w-5xl m-auto p-5 md:p-10">
        <div className="flex flex-col md:flex-row-reverse items-center md:h-full">
          <div className="w-full flex justify-center items-center">
            <Player autoplay loop src={coffeeCupAnimation} />
          </div>
          <div className="w-full md:pr-10">
            <h1 className="text-3xl md:text-5xl font-medium mt-6 text-midnight">
              Sit back and relax, we'll be in touch
            </h1>
            <p className="mt-5 text-griselda font-light">
              {isOrganic
                ? "Whale is opening your account and working with your bank to move your deposit."
                : `Whale is opening your account and working with ${propertyMarketingName} to move your deposit. This usually takes 7-10 days.`}{" "}
              You'll immediately start earning interest as soon as the transfer
              is complete.
            </p>
            <div className="switzer mt-5 pl-5 border-l-2 border-midSage">
              <p className="text-midnight font-medium">Customer Support</p>
              <a
                href="mailto:support@getwhale.co"
                className="text-midnight font-light"
              >
                support@getwhale.co
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
