import { Tooltip } from "@nextui-org/react";
import integration from "../../images/integration.png";

export const WebsitePropertiesEasyIntegration = () => {
  return (
    <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2 mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-48">
      <div className="text-center lg:text-left">
        <h2 className="text-4xl sm:text-7xl md:text-8xl font-light mb-8">
          Easy Integration
        </h2>
        <Tooltip
          content={
            <div className="p-5 text-lg max-w-96">
              <p className="font-semibold">Don't use a PMS?</p>
              <p className="text-griselda">No problem. Whale provides an easy way to set up and manage your properties.</p>
            </div>
          }
          classNames={{
            content: ["bg-warmGrey shadow-none"],
            base: ["before:bg-warmGrey before:scale-150 before:shadow-none hidden lg:block"],
          }}
          offset={20}
          placement="bottom-start"
          showArrow
          isOpen
          shouldFlip={false}
        >
          <p className="text-xl text-griselda hidden lg:block">
            Whale connects seamlessly with most property management systems and
            also offers custom integrations.
          </p>
        </Tooltip>
        <Tooltip
          content={
            <div className="p-5 text-lg max-w-xs">
              <p className="font-semibold">Don't use a PMS?</p>
              <p className="text-griselda">No problem. Whale provides an easy way to set up and manage your properties.</p>
            </div>
          }
          classNames={{
            content: ["bg-warmGrey shadow-none"],
            base: ["before:bg-warmGrey before:scale-150 before:shadow-none lg:hidden"],
          }}
          offset={20}
          placement="bottom"
          showArrow
          isOpen
          shouldFlip={false}
        >
          <p className="text-xl text-griselda lg:hidden">
            Whale connects seamlessly with most property management systems and
            also offers custom integrations.
          </p>
        </Tooltip>
      </div>
      <div className="order-first lg:order-last">
        <img src={integration} alt="Integration diagram" />
      </div>
    </div>
  );
};
