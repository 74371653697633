import { CheckCircle, XCircle } from "@phosphor-icons/react";

const XCircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    className="flex sm:hidden"
  >
    <rect width="44" height="44" rx="22" fill="#F4F3EF" />
    <path
      d="M29.7656 28.6336C29.8399 28.708 29.8988 28.7962 29.9391 28.8933C29.9793 28.9904 30 29.0945 30 29.1996C30 29.3047 29.9793 29.4088 29.9391 29.5059C29.8988 29.603 29.8399 29.6912 29.7656 29.7656C29.6912 29.8399 29.603 29.8988 29.5059 29.9391C29.4088 29.9793 29.3047 30 29.1996 30C29.0945 30 28.9904 29.9793 28.8933 29.9391C28.7962 29.8988 28.708 29.8399 28.6336 29.7656L22 23.1309L15.3664 29.7656C15.2163 29.9157 15.0127 30 14.8004 30C14.5881 30 14.3845 29.9157 14.2344 29.7656C14.0843 29.6155 14 29.4119 14 29.1996C14 28.9873 14.0843 28.7837 14.2344 28.6336L20.8691 22L14.2344 15.3664C14.0843 15.2163 14 15.0127 14 14.8004C14 14.5881 14.0843 14.3845 14.2344 14.2344C14.3845 14.0843 14.5881 14 14.8004 14C15.0127 14 15.2163 14.0843 15.3664 14.2344L22 20.8691L28.6336 14.2344C28.7837 14.0843 28.9873 14 29.1996 14C29.4119 14 29.6155 14.0843 29.7656 14.2344C29.9157 14.3845 30 14.5881 30 14.8004C30 15.0127 29.9157 15.2163 29.7656 15.3664L23.1309 22L29.7656 28.6336Z"
      fill="#5C6D6B"
    />
  </svg>
);

const CheckCircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    className="flex sm:hidden"
  >
    <rect width="44" height="44" rx="22" fill="#D3F883" />
    <path
      d="M31.9703 16.1576L18.846 29.6286C18.7317 29.7463 18.5958 29.8397 18.4463 29.9034C18.2967 29.9672 18.1364 30 17.9744 30C17.8125 30 17.6521 29.9672 17.5026 29.9034C17.353 29.8397 17.2172 29.7463 17.1029 29.6286L11.361 23.735C11.2466 23.6175 11.1558 23.478 11.0938 23.3246C11.0319 23.1711 11 23.0066 11 22.8404C11 22.6743 11.0319 22.5098 11.0938 22.3563C11.1558 22.2028 11.2466 22.0633 11.361 21.9459C11.4755 21.8284 11.6113 21.7352 11.7609 21.6716C11.9104 21.6081 12.0707 21.5753 12.2325 21.5753C12.3944 21.5753 12.5547 21.6081 12.7042 21.6716C12.8537 21.7352 12.9896 21.8284 13.1041 21.9459L17.9755 26.9459L30.2293 14.3705C30.4604 14.1333 30.7739 14 31.1008 14C31.4277 14 31.7412 14.1333 31.9723 14.3705C32.2035 14.6078 32.3333 14.9296 32.3333 15.2651C32.3333 15.6006 32.2035 15.9224 31.9723 16.1597L31.9703 16.1576Z"
      fill="#5C6D6B"
    />
  </svg>
);

const TABLE_ROWS = [
  "Earn interest on your rental deposit",
  "Your deposit never leaves your sight",
  "Get your balance immediately after move out",
];

export const WebsiteCompareTable = () => (
  <table className="w-full border-separate border-spacing-y-0 border-griselda">
    <thead>
      <tr>
        <th></th>
        <th className="text-left font-medium">
          <p className="leading-5 md:text-2xl mr-4">Whale</p>
        </th>
        <th className="text-left font-medium">
          <p className="leading-5 md:text-2xl">Alternatives</p>
        </th>
      </tr>
    </thead>
    <tbody>
      {TABLE_ROWS.map((row, index) => {
        const border =
          index === TABLE_ROWS.length - 1
            ? "border-transparent"
            : "border-strokeGrey";
        const className = `py-6 border-b-1 ${border}`;
        return (
          <tr key={row}>
            <td className={`switzer leading-5 md:text-2xl pr-4 ${className}`}>
              {row}
            </td>
            <td className={className}>
              <CheckCircle
                className="fill-brightGreen hidden sm:flex"
                size={44}
                weight="fill"
              />
              <CheckCircleSvg />
            </td>
            <td className={className}>
              <XCircle size={44} weight="fill" className="hidden sm:flex" />
              <XCircleSvg />
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);
