export const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const ALL_COUNTRIES: {
  [countryCode: string]: {
    code: string;
    code3: string;
    name: string;
    number: string;
  };
} = {
  AFG: { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
  ALB: { code: "AL", code3: "ALB", name: "Albania", number: "008" },
  DZA: { code: "DZ", code3: "DZA", name: "Algeria", number: "012" },
  ASM: { code: "AS", code3: "ASM", name: "American Samoa", number: "016" },
  AND: { code: "AD", code3: "AND", name: "Andorra", number: "020" },
  AGO: { code: "AO", code3: "AGO", name: "Angola", number: "024" },
  AIA: { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
  ATA: { code: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
  ATG: { code: "AG", code3: "ATG", name: "Antigua & Barbuda", number: "028" },
  ARG: { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
  ARM: { code: "AM", code3: "ARM", name: "Armenia", number: "051" },
  ABW: { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
  AUS: { code: "AU", code3: "AUS", name: "Australia", number: "036" },
  AUT: { code: "AT", code3: "AUT", name: "Austria", number: "040" },
  AZE: { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
  BHS: { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
  BHR: { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
  BGD: { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
  BRB: { code: "BB", code3: "BRB", name: "Barbados", number: "052" },
  BLR: { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
  BEL: { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
  BLZ: { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
  BEN: { code: "BJ", code3: "BEN", name: "Benin", number: "204" },
  BMU: { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
  BTN: { code: "BT", code3: "BTN", name: "Bhutan", number: "064" },
  BOL: { code: "BO", code3: "BOL", name: "Bolivia", number: "068" },
  BES: {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius & Saba",
    number: "535",
  },
  BIH: {
    code: "BA",
    code3: "BIH",
    name: "Bosnia & Herzegovina",
    number: "070",
  },
  BWA: { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
  BVT: { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
  BRA: { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
  IOT: {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    number: "086",
  },
  BRN: { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
  BGR: { code: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
  BFA: { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
  BDI: { code: "BI", code3: "BDI", name: "Burundi", number: "108" },
  CPV: { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
  KHM: { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
  CMR: { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
  CAN: { code: "CA", code3: "CAN", name: "Canada", number: "124" },
  CYM: { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
  CAF: {
    code: "CF",
    code3: "CAF",
    name: "Central African Republic",
    number: "140",
  },
  TCD: { code: "TD", code3: "TCD", name: "Chad", number: "148" },
  CHL: { code: "CL", code3: "CHL", name: "Chile", number: "152" },
  CHN: { code: "CN", code3: "CHN", name: "China", number: "156" },
  CXR: { code: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
  CCK: {
    code: "CC",
    code3: "CCK",
    name: "Cocos Keeling Islands",
    number: "166",
  },
  COL: { code: "CO", code3: "COL", name: "Colombia", number: "170" },
  COM: { code: "KM", code3: "COM", name: "Comoros", number: "174" },
  COD: {
    code: "CD",
    code3: "COD",
    name: "Democratic Republic of the Congo",
    number: "180",
  },
  COG: { code: "CG", code3: "COG", name: "Congo", number: "178" },
  COK: { code: "CK", code3: "COK", name: "Cook Islands", number: "184" },
  CRI: { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
  HRV: { code: "HR", code3: "HRV", name: "Croatia", number: "191" },
  CUB: { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
  CUW: { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
  CYP: { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
  CZE: { code: "CZ", code3: "CZE", name: "Czechia", number: "203" },
  CIV: { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384" },
  DNK: { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
  DJI: { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
  DMA: { code: "DM", code3: "DMA", name: "Dominica", number: "212" },
  DOM: { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214" },
  ECU: { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
  EGY: { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
  SLV: { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
  GNQ: { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
  ERI: { code: "ER", code3: "ERI", name: "Eritrea", number: "232" },
  EST: { code: "EE", code3: "EST", name: "Estonia", number: "233" },
  SWZ: { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
  ETH: { code: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
  FLK: { code: "FK", code3: "FLK", name: "Falkland Islands", number: "238" },
  FRO: { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234" },
  FJI: { code: "FJ", code3: "FJI", name: "Fiji", number: "242" },
  FIN: { code: "FI", code3: "FIN", name: "Finland", number: "246" },
  FRA: { code: "FR", code3: "FRA", name: "France", number: "250" },
  GUF: { code: "GF", code3: "GUF", name: "French Guiana", number: "254" },
  PYF: { code: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
  ATF: {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories",
    number: "260",
  },
  GAB: { code: "GA", code3: "GAB", name: "Gabon", number: "266" },
  GMB: { code: "GM", code3: "GMB", name: "Gambia", number: "270" },
  GEO: { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
  DEU: { code: "DE", code3: "DEU", name: "Germany", number: "276" },
  GHA: { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
  GIB: { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
  GRC: { code: "GR", code3: "GRC", name: "Greece", number: "300" },
  GRL: { code: "GL", code3: "GRL", name: "Greenland", number: "304" },
  GRD: { code: "GD", code3: "GRD", name: "Grenada", number: "308" },
  GLP: { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
  GUM: { code: "GU", code3: "GUM", name: "Guam", number: "316" },
  GTM: { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
  GGY: { code: "GG", code3: "GGY", name: "Guernsey", number: "831" },
  GIN: { code: "GN", code3: "GIN", name: "Guinea", number: "324" },
  GNB: { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
  GUY: { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
  HTI: { code: "HT", code3: "HTI", name: "Haiti", number: "332" },
  HMD: {
    code: "HM",
    code3: "HMD",
    name: "Heard Island & McDonald Islands",
    number: "334",
  },
  VAT: { code: "VA", code3: "VAT", name: "Holy See", number: "336" },
  HND: { code: "HN", code3: "HND", name: "Honduras", number: "340" },
  HKG: { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
  HUN: { code: "HU", code3: "HUN", name: "Hungary", number: "348" },
  ISL: { code: "IS", code3: "ISL", name: "Iceland", number: "352" },
  IND: { code: "IN", code3: "IND", name: "India", number: "356" },
  IDN: { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
  IRN: { code: "IR", code3: "IRN", name: "Iran", number: "364" },
  IRQ: { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
  IRL: { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
  IMN: { code: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
  ISR: { code: "IL", code3: "ISR", name: "Israel", number: "376" },
  ITA: { code: "IT", code3: "ITA", name: "Italy", number: "380" },
  JAM: { code: "JM", code3: "JAM", name: "Jamaica", number: "388" },
  JPN: { code: "JP", code3: "JPN", name: "Japan", number: "392" },
  JEY: { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
  JOR: { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
  KAZ: { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
  KEN: { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
  KIR: { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
  PRK: {
    code: "KP",
    code3: "PRK",
    name: "Democratic People's Republic of Korea",
    number: "408",
  },
  KOR: { code: "KR", code3: "KOR", name: "Republic of Korea", number: "410" },
  KWT: { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
  KGZ: { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
  LAO: {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic",
    number: "418",
  },
  LVA: { code: "LV", code3: "LVA", name: "Latvia", number: "428" },
  LBN: { code: "LB", code3: "LBN", name: "Lebanon", number: "422" },
  LSO: { code: "LS", code3: "LSO", name: "Lesotho", number: "426" },
  LBR: { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
  LBY: { code: "LY", code3: "LBY", name: "Libya", number: "434" },
  LIE: { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
  LTU: { code: "LT", code3: "LTU", name: "Lithuania", number: "440" },
  LUX: { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
  MAC: { code: "MO", code3: "MAC", name: "Macao", number: "446" },
  MDG: { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
  MWI: { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
  MYS: { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
  MDV: { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
  MLI: { code: "ML", code3: "MLI", name: "Mali", number: "466" },
  MLT: { code: "MT", code3: "MLT", name: "Malta", number: "470" },
  MHL: { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584" },
  MTQ: { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
  MRT: { code: "MR", code3: "MRT", name: "Mauritania", number: "478" },
  MUS: { code: "MU", code3: "MUS", name: "Mauritius", number: "480" },
  MYT: { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
  MEX: { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
  FSM: { code: "FM", code3: "FSM", name: "Micronesia", number: "583" },
  MDA: { code: "MD", code3: "MDA", name: "Moldova", number: "498" },
  MCO: { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
  MNG: { code: "MN", code3: "MNG", name: "Mongolia", number: "496" },
  MNE: { code: "ME", code3: "MNE", name: "Montenegro", number: "499" },
  MSR: { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
  MAR: { code: "MA", code3: "MAR", name: "Morocco", number: "504" },
  MOZ: { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
  MMR: { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
  NAM: { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
  NRU: { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
  NPL: { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
  NLD: { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
  NCL: { code: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
  NZL: { code: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
  NIC: { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
  NER: { code: "NE", code3: "NER", name: "Niger", number: "562" },
  NGA: { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
  NIU: { code: "NU", code3: "NIU", name: "Niue", number: "570" },
  NFK: { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
  MNP: {
    code: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands",
    number: "580",
  },
  NOR: { code: "NO", code3: "NOR", name: "Norway", number: "578" },
  OMN: { code: "OM", code3: "OMN", name: "Oman", number: "512" },
  PAK: { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
  PLW: { code: "PW", code3: "PLW", name: "Palau", number: "585" },
  PSE: { code: "PS", code3: "PSE", name: "Palestine", number: "275" },
  PAN: { code: "PA", code3: "PAN", name: "Panama", number: "591" },
  PNG: { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
  PRY: { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
  PER: { code: "PE", code3: "PER", name: "Peru", number: "604" },
  PHL: { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
  PCN: { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
  POL: { code: "PL", code3: "POL", name: "Poland", number: "616" },
  PRT: { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
  PRI: { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
  QAT: { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
  MKD: {
    code: "MK",
    code3: "MKD",
    name: "Republic of North Macedonia",
    number: "807",
  },
  ROU: { code: "RO", code3: "ROU", name: "Romania", number: "642" },
  RUS: { code: "RU", code3: "RUS", name: "Russian Federation", number: "643" },
  RWA: { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
  REU: { code: "RE", code3: "REU", name: "Réunion", number: "638" },
  BLM: { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
  SHN: {
    code: "SH",
    code3: "SHN",
    name: "Saint Helena, Ascension & Tristan da Cunha",
    number: "654",
  },
  KNA: { code: "KN", code3: "KNA", name: "Saint Kitts & Nevis", number: "659" },
  LCA: { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
  MAF: { code: "MF", code3: "MAF", name: "Saint Martin", number: "663" },
  SPM: {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre & Miquelon",
    number: "666",
  },
  VCT: {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent & the Grenadines",
    number: "670",
  },
  WSM: { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
  SMR: { code: "SM", code3: "SMR", name: "San Marino", number: "674" },
  STP: {
    code: "ST",
    code3: "STP",
    name: "Sao Tome and Principe",
    number: "678",
  },
  SAU: { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
  SEN: { code: "SN", code3: "SEN", name: "Senegal", number: "686" },
  SRB: { code: "RS", code3: "SRB", name: "Serbia", number: "688" },
  SYC: { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
  SLE: { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
  SGP: { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
  SXM: { code: "SX", code3: "SXM", name: "Sint Maarten", number: "534" },
  SVK: { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
  SVN: { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
  SLB: { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
  SOM: { code: "SO", code3: "SOM", name: "Somalia", number: "706" },
  ZAF: { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
  SGS: {
    code: "GS",
    code3: "SGS",
    name: "South Georgia & the South Sandwich Islands",
    number: "239",
  },
  SSD: { code: "SS", code3: "SSD", name: "South Sudan", number: "728" },
  ESP: { code: "ES", code3: "ESP", name: "Spain", number: "724" },
  LKA: { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
  SDN: { code: "SD", code3: "SDN", name: "Sudan", number: "729" },
  SUR: { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
  SJM: {
    code: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    number: "744",
  },
  SWE: { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
  CHE: { code: "CH", code3: "CHE", name: "Switzerland", number: "756" },
  SYR: {
    code: "SY",
    code3: "SYR",
    name: "Syrian Arab Republic",
    number: "760",
  },
  TWN: { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
  TJK: { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
  TZA: { code: "TZ", code3: "TZA", name: "Tanzania", number: "834" },
  THA: { code: "TH", code3: "THA", name: "Thailand", number: "764" },
  TLS: { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
  TGO: { code: "TG", code3: "TGO", name: "Togo", number: "768" },
  TKL: { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
  TON: { code: "TO", code3: "TON", name: "Tonga", number: "776" },
  TTO: { code: "TT", code3: "TTO", name: "Trinidad & Tobago", number: "780" },
  TUN: { code: "TN", code3: "TUN", name: "Tunisia", number: "788" },
  TUR: { code: "TR", code3: "TUR", name: "Turkey", number: "792" },
  TKM: { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
  TCA: {
    code: "TC",
    code3: "TCA",
    name: "Turks & Caicos Islands",
    number: "796",
  },
  TUV: { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
  UGA: { code: "UG", code3: "UGA", name: "Uganda", number: "800" },
  UKR: { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
  ARE: {
    code: "AE",
    code3: "ARE",
    name: "United Arab Emirates",
    number: "784",
  },
  GBR: { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
  UMI: {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    number: "581",
  },
  USA: {
    code: "US",
    code3: "USA",
    name: "United States of America",
    number: "840",
  },
  URY: { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
  UZB: { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
  VUT: { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
  VEN: { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
  VNM: { code: "VN", code3: "VNM", name: "Viet Nam", number: "704" },
  VGB: {
    code: "VG",
    code3: "VGB",
    name: "British Virgin Islands",
    number: "092",
  },
  WLF: { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
  ESH: { code: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
  YEM: { code: "YE", code3: "YEM", name: "Yemen", number: "887" },
  ZMB: { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
  ZWE: { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
  ALA: { code: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
};

export const convertCodeToCode3 = (code: string) => {
  const countries = Object.values(ALL_COUNTRIES);
  const country = countries.find(country => country.code === code);
  return country?.code3 || "";
}