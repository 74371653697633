import { OnboardingFinancialPep } from "./OnboardingFinancialPep";

type Props = {
  onSelect: (isPublicShareholder: boolean) => void;
};

const QUESTION =
  "Are you or a family member a senior executive or 10% shareholder at a publicly traded company?";

const WARNING = `By selecting "yes", you will be required to email Whale a letter
written and signed by a compliance officer on company letterhead
explicitly granting permission for you to own the account.`;

export const OnboardingFinancialPublicShareholder = ({ onSelect }: Props) => {
  return (
    <OnboardingFinancialPep
      question={QUESTION}
      warning={WARNING}
      onSelect={onSelect}
    />
  );
};
