import { BaseRadioButton } from "../../BaseComponents";

type Props = {
  onSelect: (isUsaCitizen: boolean) => void;
};

export const OnboardingCitizenshipUsa = ({ onSelect }: Props) => (
  <div className="w-full h-full max-w-xl m-auto p-5 flex items-center justify-center">
    <div>
      <h1 className="text-3xl mb-8">
        Are you a citizen of the United States?
      </h1>
      <BaseRadioButton className="mb-4" onPress={() => onSelect(true)}>
        Yes
      </BaseRadioButton>
      <BaseRadioButton onPress={() => onSelect(false)}>No</BaseRadioButton>
    </div>
  </div>
);
