import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";

const STATUSES: { [status: string]: string } = {
  APPROVAL_PENDING: "Pending",
  APPROVED: "Pending",
  CANCELED: "Canceled",
  COMPLETE: "Completed",
  PENDING: "Pending",
  QUEUED: "Pending",
  REJECTED: "Canceled",
  RETURNED: "Returned",
  SENT_TO_CLEARING: "Pending",
};

const NUMBER_FORMAT = new Intl.NumberFormat("en-US", {
  currency: "USD",
  minimumFractionDigits: 2,
  signDisplay: "exceptZero",
  style: "currency",
});

export type TransactionType = {
  amount: number;
  date: string;
  status: string;
};

type Props = {
  limit?: number;
  transactions: TransactionType[];
};

export const AccountTransactionsTable = ({
  limit = 10000,
  transactions,
}: Props) => {
  const transactionsToDisplay = transactions.slice(0, limit);
  return (
    <Table
      aria-label="Transactions"
      classNames={{ td: "py-4" }}
      hideHeader
      removeWrapper
    >
      <TableHeader>
        <TableColumn>Amount</TableColumn>
        <TableColumn>Date</TableColumn>
        <TableColumn>Status</TableColumn>
      </TableHeader>
      <TableBody emptyContent="No transactions to display">
        {transactionsToDisplay.map(({ amount, date, status }, index) => (
          <TableRow
            className={
              index === transactionsToDisplay.length - 1
                ? "border-b-0"
                : "border-b-1"
            }
            key={index}
          >
            <TableCell>{NUMBER_FORMAT.format(amount)}</TableCell>
            <TableCell>
              {new Date(date).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })}
            </TableCell>
            <TableCell>{STATUSES[status] || ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
