import { Button } from "@nextui-org/react";
import iphoneGreen from "../../images/iphone-green.png";
import { NavLink } from "react-router-dom";

export const WebsiteAboutRenterBenefits = () => (
  <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2 mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-48">
    <div className="flex justify-center">
      <img src={iphoneGreen} alt="iPhone" width="90%" className="max-w-xl" />
    </div>
    <div className="flex items-center">
      <div>
        <h2 className="font-light text-4xl lg:text-6xl mb-8">
          Renter Benefits
        </h2>
        <p className="mb-4 text-lg text-griselda">
          Whale renters earn interest on their security deposits, viewable
          anytime in their account. Renters can easily withdraw their money at
          the end of their lease.
        </p>
        <Button
          className="w-full lg:w-fit switzer text-lg font-semibold text-midnight rounded-lg border-1 border-midnight"
          size="lg"
          as={NavLink}
          to="/"
          variant="bordered"
        >
          Learn More
        </Button>
      </div>
    </div>
  </div>
);
