import { Link } from "@phosphor-icons/react";
import stackpoint from "../../images/stackpoint.png";
import twentyNineStreetCapital from "../../images/29sc.png";

export const WebsiteAboutInvestors = () => {
  return (
    <div>
      <div className="mx-8 md:mx-16 lg:mx-20 xl:mx-28 mt-16 mb-48">
        <h2 className="text-5xl sm:text-7xl md:text-8xl font-light mb-16">
          Investors
        </h2>
        <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2">
          <div>
            <div className="flex items-center">
              <img
                width="32"
                height="32"
                src={stackpoint}
                alt="Stackpoint logo"
              />
              <h3 className="ml-3 text-3xl font-semibold">
                Stackpoint Ventures
              </h3>
            </div>
            <p className="text-lg text-griselda mt-3">
              Stackpoint is a venture studio dedicated to building and investing
              in real estate technology companies. The founders have created
              over $2b of combined equity value.
            </p>
            <a
              href="https://stackpoint.com"
              target="_blank"
              className="switzer flex items-center mt-3 text-lg font-semibold underline"
              rel="noreferrer"
            >
              <Link size={24} className="mr-3" />
              stackpoint.com
            </a>
          </div>
          <div>
            <div className="flex items-center">
              <img
                width="32"
                height="32"
                src={twentyNineStreetCapital}
                alt="29th Street Capital logo"
              />
              <h3 className="ml-3 text-3xl font-semibold">
                29th Street Capital
              </h3>
            </div>
            <p className="text-lg text-griselda mt-3">
              29th Street Capital has managed, invested, and acquired over $5.1
              billion of multifamily assets across the United States with a
              focus on the residential real estate market.
            </p>
            <a
              href="https://29sc.com"
              target="_blank"
              className="switzer flex items-center mt-3 text-lg font-semibold underline"
              rel="noreferrer"
            >
              <Link size={24} className="mr-3" />
              29sc.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
