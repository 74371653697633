import { BaseButton } from "../../BaseComponents";
import { LOGO_URL, USD_NUMBER_FORMAT } from "../../constants";
import { Money, Wrench } from "@phosphor-icons/react";
import { useOnboardingContext } from "../shared/OnboardingContext";
import OnboardingNavbar from "../../components/onboarding-navbar/OnboardingNavbar";
import {
  ORGANIC_DEPOSIT,
  PAGE_FIRST,
  SECTION_PERSONAL,
} from "../shared/onboardingConstants";

export const OnboardingWelcome = () => {
  const {
    investorServer: {
      onboardingDepositObligation: { depositAmount, feeAmount },
      onboardingType,
    },
    propertyLocal: { propertyMarketingName, propertyId },
    setSection,
    unitLocal: {
      address: { premise },
    },
  } = useOnboardingContext();

  const isOrganic = onboardingType === ORGANIC_DEPOSIT;

  return (
    <>
      <OnboardingNavbar
        showBack={false}
        showContinue={false}
        showProgressBar={false}
      />
      <div className="w-full h-full max-w-7xl m-auto p-5 lg:p-10">
        <div className="lg:h-full grid grid-cols-1 lg:grid-cols-5 grid-rows-1">
          <div className="col-span-3 flex flex-col justify-center order-last lg:order-first my-5">
            <h1 className="text-3xl lg:text-5xl text-midnight mb-5">
              {isOrganic
                ? `Meet your rental deposit requirement for ${
                    premise || propertyMarketingName
                  }.`
                : "Create your account to earn interest on your deposit"}
            </h1>
            {isOrganic ? (
              <div>
                <p className="flex items-center border-b border-strokeGrey py-3">
                  <Money size={44} weight="duotone" className="mr-3" />
                  {USD_NUMBER_FORMAT.format(depositAmount)} rental deposit
                </p>
                <p className="flex items-center py-3">
                  <Wrench size={44} weight="duotone" className="mr-3" />
                  {USD_NUMBER_FORMAT.format(feeAmount)} one-time charge
                </p>
              </div>
            ) : (
              <p className="text-griselda font-light">
                {propertyMarketingName} will then transfer your security deposit
                to Whale
              </p>
            )}
            <BaseButton
              className="w-full lg:w-2/5 mt-6"
              onPress={() => setSection(`${SECTION_PERSONAL}_${PAGE_FIRST}`)}
            >
              {isOrganic ? "Create Account" : "Get Started"}
            </BaseButton>
          </div>
          <div className="col-span-2 flex flex-col justify-center items-center text-center my-5">
            <img
              alt={`${propertyMarketingName} logo`}
              className="mb-10"
              src={`${LOGO_URL}/${propertyId}-overlap.png`}
              width={300}
            />
            <p className="text-griselda font-light">In partnership with</p>
            <p className="text-midnight font-light text-2xl">
              {propertyMarketingName}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
