export const WebsiteCareersSplash = () => (
  <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-28">
    <h1 className="text-7xl lg:text-9xl font-light mb-14">Careers</h1>
    <h2 className="switzer text-2xl text-griselda max-w-3xl">
      We are a fun, dynamic, and fast-paced organization where you can have a
      transformative impact on the financial lives of millions. We're forming a
      high-caliber, high-horsepower team that thrives on working together to
      solve tough problems. Come jump on this rocket ship.
    </h2>
  </div>
);
