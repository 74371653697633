import { useState } from "react";
import { OnboardingSectionTitle } from "../shared/OnboardingSectionTitle";
import { useOnboardingContext } from "../shared/OnboardingContext";
import OnboardingNavbar from "../../components/onboarding-navbar/OnboardingNavbar";
import {
  PAGE_FIRST,
  PAGE_LAST,
  SECTION_CITIZENSHIP,
  SECTION_FINANCIAL,
  SECTION_SUBMISSION,
} from "../shared/onboardingConstants";
import {
  createAchRelationshipAndTransferFunds,
  createInvestmentAccount,
} from "../shared/onboardingApi";
import { OnboardingFinancialBankAccount } from "./OnboardingFinancialBankAccount";
import { OnboardingFinancialEmploymentStatus } from "./OnboardingFinancialEmploymentStatus";
import { OnboardingFinancialPrimaryEmployer } from "./OnboardingFinancialPrimaryEmployer";
import { OnboardingFinancialSocialSecurity } from "./OnboardingFinancialSocialSecurity";
import { OnboardingFinancialTerms } from "./OnboardingFinancialTerms";
import { OnboardingFinancialPublicShareholder } from "./OnboardingFinancialPublicShareholder";
import { OnboardingFinancialStockExchangeEmployee } from "./OnboardingFinancialStockExchangeEmployee";
import { OnboardingFinancialPoliticalFigure } from "./OnbordingFinancialPoliticalFigure";
import { AgreementType, InvestorType } from "../onboardingTypes";
import { BaseErrorMessage } from "../../BaseComponents";

const PAGE_SECTION_TITLE = "PAGE_SECTION_TITLE";
const PAGE_EMPLOYMENT_STATUS = "PAGE_EMPLOYMENT_STATUS";
const PAGE_PRIMARY_EMPLOYER = "PAGE_PRIMARY_EMPLOYER";
const PAGE_BANK_ACCOUNT = "PAGE_BANK_ACCOUNT";
const PAGE_PUBLIC_SHAREHOLDER = "PAGE_PUBLIC_SHAREHOLDER";
const PAGE_STOCK_EXCHANGE_EMPLOYEE = "PAGE_STOCK_EXCHANGE_EMPLOYEE";
const PAGE_POLITICAL_FIGURE = "PAGE_POLITICAL_FIGURE";
const PAGE_SSN = "PAGE_SSN";
const PAGE_TERMS = "PAGE_TERMS";

const HIDE_CONTINUE_BUTTON = [
  PAGE_SECTION_TITLE,
  PAGE_EMPLOYMENT_STATUS,
  PAGE_PUBLIC_SHAREHOLDER,
  PAGE_STOCK_EXCHANGE_EMPLOYEE,
  PAGE_POLITICAL_FIGURE,
  PAGE_TERMS,
];

const getDefaultPage = (
  investorServer: InvestorType,
  section: string,
  isOrganic: boolean
) => {
  const { onboardingPage, employmentStatus, isPlaidAccountLinked } =
    investorServer;
  const isEmployed = employmentStatus === "employed";

  if (section === `${SECTION_FINANCIAL}_${PAGE_FIRST}`) {
    const pages = getPages({ isEmployed, isOrganic, isPlaidAccountLinked });
    return pages[0];
  }
  if (section === `${SECTION_FINANCIAL}_${PAGE_LAST}`) {
    const pages = getPages({ isEmployed, isOrganic, isPlaidAccountLinked });
    return pages[pages.length - 1];
  }
  if (onboardingPage?.includes(SECTION_FINANCIAL)) {
    return onboardingPage.replace(`${SECTION_FINANCIAL}_`, "");
  }

  return PAGE_SECTION_TITLE;
};

const getPages = ({
  isEmployed,
  isOrganic,
  isPlaidAccountLinked,
}: {
  isEmployed: boolean;
  isOrganic: boolean;
  isPlaidAccountLinked: boolean | null;
}) => {
  let pagesToSkip: string[] = [];

  // If not employed, skip the primary employer page
  if (!isEmployed) {
    pagesToSkip.push(PAGE_PRIMARY_EMPLOYER);
  }

  // If a bank account was already linked with Plaid or not organic, skip bank account page
  if (isPlaidAccountLinked || !isOrganic) {
    pagesToSkip.push(PAGE_BANK_ACCOUNT);
  }

  const pages = [
    PAGE_SECTION_TITLE,
    PAGE_EMPLOYMENT_STATUS,
    PAGE_PRIMARY_EMPLOYER,
    PAGE_PUBLIC_SHAREHOLDER,
    PAGE_STOCK_EXCHANGE_EMPLOYEE,
    PAGE_POLITICAL_FIGURE,
    PAGE_SSN,
    PAGE_BANK_ACCOUNT,
    PAGE_TERMS,
  ];

  return pages.filter((page) => !pagesToSkip.includes(page));
};

export const OnboardingFinancial = () => {
  const {
    investorLocal: {
      employerAddress,
      employmentStatus,
      jobTitle,
      nameOfEmployer,
      isPlaidAccountLinked,
      onboardingDepositObligation: { id: depositObligationId },
    },
    investorServer,
    isOrganic,
    section,
    setSection,
    updateInvestor,
  } = useOnboardingContext();

  const isEmployed = employmentStatus === "employed";

  // Form Fields
  const [addressOfEmployer, setAddressOfEmployer] = useState(employerAddress);
  const [employerName, setEmployerName] = useState(nameOfEmployer);
  const [occupation, setOccupation] = useState(jobTitle);
  const [socialSecurity, setSocialSecurity] = useState("");
  const [confirmSocialSecurity, setConfirmSocialSecurity] = useState("");
  const [isControlPerson, setIsControlPerson] = useState(false);
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState(false);
  const [immediateFamilyExposed, setImmediateFamilyExposed] = useState(false);
  const [hasLinkedBankAccount, setHasLinkedBankAccount] = useState(false);
  const [isAffiliatedExchangeOrFinra, setIsAffiliatedExchangeOrFinra] =
    useState(false);

  // Pages
  const defaultPage = getDefaultPage(investorServer, section, isOrganic);
  const defaultPages = getPages({
    isEmployed,
    isOrganic,
    isPlaidAccountLinked,
  });
  const [page, setPage] = useState(defaultPage);
  const [pages, setPages] = useState(defaultPages);
  const numberOfPages = pages.length + 1;
  const progressPercentage = ((pages.indexOf(page) + 1) / numberOfPages) * 100;

  // Interactions
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);

  const goToNextPage = () => {
    setIsSaving(false);
    const nextPage = pages[pages.indexOf(page) + 1];
    if (nextPage) {
      setPage(nextPage);
    } else {
      setSection(`${SECTION_FINANCIAL}_${PAGE_FIRST}`);
    }
  };

  const handleSaveFailure = () => {
    setIsSaving(false);
  };

  const handlePressBack = () => {
    const prevPage = pages[pages.indexOf(page) - 1];
    if (prevPage) {
      setPage(prevPage);
    } else {
      setSection(`${SECTION_CITIZENSHIP}_${PAGE_LAST}`);
    }
  };

  const handlePressContinue = () => {
    if (page === PAGE_SECTION_TITLE) {
      goToNextPage();
    }
    if (page === PAGE_PRIMARY_EMPLOYER) {
      if (addressOfEmployer || employerName || occupation) {
        updateInvestor(
          {
            employerAddress: addressOfEmployer || null,
            jobTitle: occupation || null,
            nameOfEmployer: employerName || null,
            onboardingPage: `${SECTION_FINANCIAL}_${page}`,
          },
          goToNextPage,
          handleSaveFailure
        );
      } else {
        goToNextPage();
      }
    }
    if (page === PAGE_SSN) {
      goToNextPage();
    }
    if (page === PAGE_BANK_ACCOUNT) {
      goToNextPage();
      setPages(getPages({ isEmployed, isOrganic, isPlaidAccountLinked: true }));
    }
  };

  const getShouldDisableContinue = () => {
    if (page === PAGE_SSN) {
      return (
        socialSecurity.length !== 9 || socialSecurity !== confirmSocialSecurity
      );
    }
    if (page === PAGE_BANK_ACCOUNT) {
      return !hasLinkedBankAccount;
    }
    return false;
  };

  const handleSelectEmploymentStatus = (employmentStatus: string) => {
    const isEmployed = employmentStatus === "employed";
    setPages(getPages({ isEmployed, isOrganic, isPlaidAccountLinked }));
    setIsSaving(true);
    updateInvestor(
      {
        employmentStatus,
        onboardingPage: `${SECTION_FINANCIAL}_${PAGE_EMPLOYMENT_STATUS}`,
      },
      () => {
        setIsSaving(false);
        setPage(isEmployed ? PAGE_PRIMARY_EMPLOYER : PAGE_PUBLIC_SHAREHOLDER);
      },
      handleSaveFailure
    );
  };

  const handleSelectPublicShareholder = (isPublicShareholder: boolean) => {
    setIsControlPerson(isPublicShareholder);
    setPage(PAGE_STOCK_EXCHANGE_EMPLOYEE);
  };

  const handleSelectStockExchangeEmployee = (
    isStockExchangeEmployee: boolean
  ) => {
    setIsAffiliatedExchangeOrFinra(isStockExchangeEmployee);
    setPage(PAGE_POLITICAL_FIGURE);
  };

  const handleSelectPoliticalFigure = (isPoliticalFigure: boolean) => {
    setIsPoliticallyExposed(isPoliticalFigure);
    setImmediateFamilyExposed(isPoliticalFigure);
    setPage(PAGE_SSN);
  };

  const handleSuccessfulLink = () => {
    setHasLinkedBankAccount(true);
    updateInvestor({ isPlaidAccountLinked: true }, () => {}, handleSaveFailure);
  };

  const handleAgree = (signedAgreements: AgreementType[]) => {
    const signedAt = new Date().toISOString();

    const payload = {
      depositObligationId,
      ssn: socialSecurity,
      isControlPerson,
      isAffiliatedExchangeOrFinra,
      isPoliticallyExposed,
      immediateFamilyExposed,
      accountType: "DEPOSIT",
      signedAt,
      signedAgreements,
      agreements: [
        {
          agreementName: "customer_agreement",
          signedAt,
        },
        {
          agreementName: "account_agreement",
          signedAt,
        },
      ],
    };

    createInvestmentAccount(payload)
      .then(() => {
        updateInvestor(
          { onboardingPage: SECTION_SUBMISSION },
          () => {
            setIsSaving(false);
            setSection(SECTION_SUBMISSION);
          },
          handleSaveFailure
        );
      })
      .catch(() => {
        setHasError(true);
        console.log("There was an error creating the investment account");
      })
      .finally(() => {
        if (isOrganic) {
          setTimeout(() => {
            createAchRelationshipAndTransferFunds().catch(() => {
              // Failing silently here since the status of createAchRelationshipAndTransferFunds is tracked and
              // communicated to a slack channel.
              console.log("There was an error creating ACH and fund transfer.");
            });
          }, 5000);
        }
      });
  };

  const continueButtonText =
    page === PAGE_PRIMARY_EMPLOYER &&
    !employerAddress &&
    !employerName &&
    !occupation
      ? "Skip for now"
      : "Continue";

  return (
    <>
      <OnboardingNavbar
        continueButtonText={continueButtonText}
        disableContinue={getShouldDisableContinue()}
        isLoading={isSaving}
        onPressBack={handlePressBack}
        onPressContinue={handlePressContinue}
        progressBarValue={progressPercentage}
        showBack
        showContinue={!HIDE_CONTINUE_BUTTON.includes(page)}
        showProgressBar={page !== PAGE_SECTION_TITLE}
      />
      <div className="w-full h-full max-w-7xl m-auto p-5">
        {page === PAGE_SECTION_TITLE && (
          <OnboardingSectionTitle
            onPressContinue={handlePressContinue}
            sectionName="Financial Info"
            sectionNumber={3}
          />
        )}
        {page === PAGE_EMPLOYMENT_STATUS && (
          <OnboardingFinancialEmploymentStatus
            onSelect={handleSelectEmploymentStatus}
          />
        )}
        {page === PAGE_PRIMARY_EMPLOYER && (
          <OnboardingFinancialPrimaryEmployer
            onChangeEmployerAddress={setAddressOfEmployer}
            onChangeEmployerName={setEmployerName}
            onChangeOccupation={setOccupation}
          />
        )}
        {page === PAGE_BANK_ACCOUNT && (
          <OnboardingFinancialBankAccount
            onSuccessfulLink={handleSuccessfulLink}
          />
        )}
        {page === PAGE_SSN && (
          <OnboardingFinancialSocialSecurity
            initialConfirmationValue={confirmSocialSecurity}
            initialValue={socialSecurity}
            onChange={setSocialSecurity}
            onChangeConfirm={setConfirmSocialSecurity}
            showConfirm={socialSecurity.length === 9}
          />
        )}
        {page === PAGE_PUBLIC_SHAREHOLDER && (
          <OnboardingFinancialPublicShareholder
            onSelect={handleSelectPublicShareholder}
          />
        )}
        {page === PAGE_STOCK_EXCHANGE_EMPLOYEE && (
          <OnboardingFinancialStockExchangeEmployee
            onSelect={handleSelectStockExchangeEmployee}
          />
        )}
        {page === PAGE_POLITICAL_FIGURE && (
          <OnboardingFinancialPoliticalFigure
            onSelect={handleSelectPoliticalFigure}
          />
        )}
        {page === PAGE_TERMS && (
          <OnboardingFinancialTerms isSaving={isSaving} onAgree={handleAgree} />
        )}
        <BaseErrorMessage
          isOpen={hasError}
          onClose={() => setHasError(false)}
        />
      </div>
    </>
  );
};
