import { forwardRef } from "react";
import "./InputWithLabel.css";

const InputWithLabel = forwardRef(
  ({ className = "", label, ...props }: any, ref) => {
    return (
      <label className={`InputWithLabel ${className}`}>
        <input {...props} ref={ref} />
        {label}
      </label>
    );
  }
);

export default InputWithLabel;
