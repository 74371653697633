import { useEffect } from "react";
import { WebsiteFooter } from "../WebsiteFooter";
import { WebsiteNavbar } from "../WebsiteNavbar";
import { WebsiteSplash } from "../WebsiteSplash";
import { WebsitePropertiesEasyIntegration } from "./WebsitePropertiesEasyIntegration";
import { WebsitePropertiesFaq } from "./WebsitePropertiesFaq";
import { WebsitePropertiesHowItWorks } from "./WebsitePropertiesHowItWorks";
import { WebsitePropertiesInvestors } from "./WebsitePropertiesInvestors";
import { WebsitePropertiesTestimonials } from "./WebsitePropertiesTestimonials";
import { WebsitePropertiesValueProps } from "./WebsitePropertiesValueProps";

export const WebsiteProperties = () => {
  useEffect(() => {
    document.title = "Whale | Properties";
  }, []);

  return (
    <div>
      <WebsiteNavbar
        primaryCta={{ text: "Schedule Demo", to: "https://cal.com/whale/demo" }}
      />
      <WebsiteSplash
        cta={{ text: "Schedule Demo", to: "https://cal.com/whale/demo" }}
        heading={
          <h1 className="text-4xl sm:text-7xl lg:text-8xl 2xl:text-9xl text-white tracking-wide">
            We take security <br /> deposits off your <br /> plate for{" "}
            <span className="outfit underline">free</span>.
          </h1>
        }
        subheading={
          <h2 className="text-base sm:text-2xl md:text-3xl 2xl:text-[46px] text-sage font-light mt-3 sm:mt-6">
            And your residents will love it.
          </h2>
        }
      />
      <WebsitePropertiesValueProps />
      <WebsitePropertiesHowItWorks />
      <WebsitePropertiesEasyIntegration />
      <WebsitePropertiesTestimonials />
      <WebsitePropertiesInvestors />
      <WebsitePropertiesFaq />
      <WebsiteFooter />
    </div>
  );
};
