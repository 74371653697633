import {
  DateObjectType,
  getIsDateOverProvidedDaysAway,
  getNumberOfDaysInMonth,
  MAX_DAYS_IN_MONTH,
  MONTHS,
} from "../../components/date-input/DateInputHelpers";

export const getVisaDateError = ({ day, month, year }: DateObjectType) => {
  const hasDay = day.length === 2;
  const hasMonth = month.length === 2;
  const hasYear = year.length === 4;

  if (hasDay && hasMonth && Number(day) > MAX_DAYS_IN_MONTH[month]) {
    return `${MONTHS[month]} does not have ${day} days`;
  }

  if (hasDay && hasMonth && hasYear) {
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const numberOfDaysInMonth = getNumberOfDaysInMonth({ month, year });
    if (Number(day) > numberOfDaysInMonth) {
      return `Day must be between 1-${numberOfDaysInMonth}`;
    }
    if (!getIsDateOverProvidedDaysAway(date, 91)) {
      return "Date must be more than 90 days in the future.";
    }
  }

  return "";
};

export const getBirthDateError = ({ day, month, year }: DateObjectType) => {
  const hasDay = day.length === 2;
  const hasMonth = month.length === 2;
  const hasYear = year.length === 4;

  if (hasDay && hasMonth && Number(day) > MAX_DAYS_IN_MONTH[month]) {
    return `${MONTHS[month]} does not have ${day} days`;
  }

  if (hasDay && hasMonth && hasYear) {
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const numberOfDaysInMonth = getNumberOfDaysInMonth({ month, year });
    if (Number(day) > numberOfDaysInMonth) {
      return `Day must be between 1-${numberOfDaysInMonth}`;
    }
    if (getBirthAge(date) < 18) {
      return "You must be 18 years or older.";
    }
  }

  return "";
};

export const getBirthAge = (date: Date) => {
  const today = new Date();
  const m = today.getMonth() - date.getMonth();

  let age = today.getFullYear() - date.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return age;
};
