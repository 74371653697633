import { OnboardingFinancialPep } from "./OnboardingFinancialPep";

type Props = {
  onSelect: (isStockExchangeEmployee: boolean) => void;
};

const QUESTION = "Are you or a family member a senior political figure?";

const WARNING = `By selecting "yes", your information will be reviewed by an Anti-Money Laundering (AML) committee to evaluate your application before approving or rejecting it.`;

export const OnboardingFinancialPoliticalFigure = ({ onSelect }: Props) => {
  return (
    <OnboardingFinancialPep
      question={QUESTION}
      warning={WARNING}
      onSelect={onSelect}
    />
  );
};
