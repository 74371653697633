import { useEffect } from "react";
import { WebsiteFooter } from "../WebsiteFooter";
import { WebsiteNavbar } from "../WebsiteNavbar";
import { WebsiteAboutInspiration } from "./WebsiteAboutInspiration";
import { WebsiteAboutMeetTeam } from "./WebsiteAboutMeetTeam";
import { WebsiteAboutPropertyBenefits } from "./WebsiteAboutPropertyBenefits";
import { WebsiteAboutRenterBenefits } from "./WebsiteAboutRenterBenefits";
import { WebsiteAboutSplash } from "./WebsiteAboutSplash";
import { WebsiteAboutTestimonials } from "./WebsiteAboutTestimonials";
import { WebsiteAboutInvestors } from "./WebsiteAboutInvestors";

export const WebsiteAbout = () => {
  useEffect(() => {
    document.title = "Whale | About";
  }, []);

  return (
    <div>
      <WebsiteNavbar forceWhiteNavbar />
      <WebsiteAboutSplash />
      <WebsiteAboutInspiration />
      <WebsiteAboutRenterBenefits />
      <WebsiteAboutPropertyBenefits />
      <WebsiteAboutInvestors />
      <WebsiteAboutMeetTeam />
      <WebsiteFooter />
    </div>
  );
};
