import { Button } from "@nextui-org/react";
import { ArrowUpRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

export const WebsiteCareersOpenRoles = () => (
  <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-32">
    <h2 className="text-5xl mb-6 font-light">Open Roles</h2>
    <div className="flex flex-col lg:flex-row justify-between border-b-1 border-griselda py-5">
      <div>
        <p className="text-lg font-semibold">
          Software Engineer Technical Lead
        </p>
        <p className="text-lg text-griselda mb-3">Remote (U.S.)</p>
      </div>
      <Button
        className="switzer text-lg font-semibold text-midnight rounded-lg border-1 border-midnight"
        size="lg"
        as={Link}
        to="https://drive.google.com/file/d/1vQobWu9f-Qu_T8Z5LPk54Hhn9FJoNi_Z/view?usp=drive_link"
        target="_blank"
        variant="bordered"
      >
        View Role
        <ArrowUpRight size={32} />
      </Button>
    </div>
  </div>
);
