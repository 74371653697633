import { useInView } from "framer-motion";
import { ReactNode, useRef } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  direction?: string;
  distance?: string;
  duration?: string;
};

export const WebsiteAnimateIn = ({
  children,
  className = "",
  direction = "Y",
  distance = "100px",
  duration = "1.5s",
}: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      className={className}
      ref={ref}
      style={{
        transform: isInView ? "none" : `translate${direction}(${distance})`,
        opacity: isInView ? 1 : 0,
        transition: `all ${duration} cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s`,
      }}
    >
      {children}
    </div>
  );
};
