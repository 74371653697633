import { useLoaderData } from "react-router-dom";
import {
  AccountTransactionsTable,
  TransactionType,
} from "./AccountTransactionsTable";

type LoaderDataType = {
  transactions: TransactionType[];
};

export const AccountTransactions = () => {
  const { transactions } = useLoaderData() as LoaderDataType;
  return (
    <div className="p-5 md:p-10">
      <h1 className="text-3xl md:text-5xl mb-4 md:mb-8">Transactions</h1>
      <AccountTransactionsTable transactions={transactions} />
    </div>
  );
};
