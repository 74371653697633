import { Divider, Input, Radio, RadioGroup } from "@nextui-org/react";
import { ALL_COUNTRIES, getFlagEmoji } from "./CountryListHelpers";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { Fragment, useEffect, useState } from "react";

export const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;
  return (
    <Radio
      {...otherProps}
      classNames={{
        base: "m-0 w-full max-w-full rounded-lg hover:bg-lightGreen data-[selected=true]:bg-lightGreen",
        wrapper: "hidden",
      }}
    >
      {children}
    </Radio>
  );
};

type Props = {
  excludeCountries?: Array<string>;
  onChange: (country: string) => void;
};

const CountryList = ({ excludeCountries, onChange }: Props) => {
  const defaultCountries = excludeCountries
    ? Object.values(ALL_COUNTRIES).filter((country) =>
        !excludeCountries.includes(country.code)
      )
    : Object.values(ALL_COUNTRIES);
  const [countries, setCountries] = useState(defaultCountries);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    setCountries(
      defaultCountries.filter((country) =>
        country.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
      )
    );
  }, [searchQuery, setCountries]);

  const handleSelectCountry = (country: string) => {
    setSelectedCountry(country);
    onChange(country);
  };

  return (
    <div>
      <Input
        aria-label="Search"
        classNames={{ base: "mb-5", input: "text-md" }}
        isClearable
        onValueChange={setSearchQuery}
        radius="lg"
        startContent={<MagnifyingGlass size={28} />}
        value={searchQuery}
        variant="bordered"
      />
      <RadioGroup
        aria-label="Select a country"
        className="w-full"
        onValueChange={handleSelectCountry}
        value={selectedCountry}
      >
        {countries.map((country, index) => (
          <Fragment key={country.code3}>
            <CustomRadio value={country.code3}>
              <div className="text-2xl font-medium text-midnight">
                {`${getFlagEmoji(country.code)} ${country.name}`}
              </div>
            </CustomRadio>
            {index < countries.length - 1 && <Divider className="my-px" />}
          </Fragment>
        ))}
      </RadioGroup>
    </div>
  );
};

export default CountryList;
