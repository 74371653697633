import { MutableRefObject, useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useNavigation } from "react-router-dom";
import { WhaleLogo } from "../helpers/Svgs";
import {
  ArrowSquareOut,
  Buildings,
  ChatCircleText,
  ClipboardText,
  House,
} from "@phosphor-icons/react";
import {
  Navbar,
  NavbarBrand,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Progress,
} from "@nextui-org/react";

const NAV_ITEMS = [
  {
    icon: House,
    isExternal: false,
    name: "Overview",
    url: "/account/overview",
  },
  {
    icon: Buildings,
    isExternal: false,
    name: "Rental Deposits",
    url: "/account/deposits",
  },
  {
    icon: ClipboardText,
    isExternal: false,
    name: "Transactions",
    url: "/account/transactions",
  },
  // {
  //   icon: Gear,
  //   isExternal: false,
  //   name: "Profile",
  //   url: "/account/profile",
  // },
  {
    icon: ChatCircleText,
    isExternal: true,
    name: "Support",
    url: "https://whale.help.usepylon.com",
  },
];

const ANIMATION_DURATION = 1500;

export const Account = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Whale | Account";
  }, []);

  // Timestamp when the page started loading
  const timestamp: MutableRefObject<number> = useRef(Date.now());

  // Navigation data from React Router to determine if data is loading
  const navigation = useNavigation();
  const isLoadingData = navigation.state === "loading";

  useEffect(() => {
    if (isLoadingData) {
      timestamp.current = Date.now();
      setShowLoading(true);
    }
  }, [isLoadingData]);

  if (!isLoadingData && showLoading) {
    const elapsed = Date.now() - timestamp.current;
    const multiplier = Math.ceil(elapsed / ANIMATION_DURATION);
    const finished = ANIMATION_DURATION * multiplier;
    const remaining = finished - elapsed;
    setTimeout(() => {
      setShowLoading(false);
    }, remaining);
  }

  return (
    <div className="w-full h-full md:flex">
      {showLoading && (
        <Progress
          aria-label="Loading..."
          classNames={{ base: "fixed", indicator: "bg-sage" }}
          isIndeterminate
          radius="none"
          size="sm"
        />
      )}
      <Navbar
        className="md:hidden"
        height="80px"
        isBlurred={false}
        isBordered
        isMenuOpen={isMenuOpen}
        maxWidth="full"
        onMenuOpenChange={setIsMenuOpen}
        position="static"
      >
        <NavbarBrand>
          <WhaleLogo />
        </NavbarBrand>
        <NavbarMenuToggle
          aria-label={`${isMenuOpen ? "Close" : "Open"} menu`}
        />
        <NavbarMenu className="flex justify-center border-t-1">
          {NAV_ITEMS.map((navItem) => (
            <NavbarMenuItem
              className="w-2/3 mx-auto my-2 text-2xl"
              key={navItem.url}
              onClick={() => setIsMenuOpen(false)}
            >
              <CustomNavLink navItem={navItem} />
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
      <div className="hidden md:block min-w-64">
        <div className="fixed top-0 left-0">
          <div className="py-10 px-5">
            <WhaleLogo />
            <ul className="mt-8 switzer">
              {NAV_ITEMS.map((navItem) => (
                <li key={navItem.url}>
                  <CustomNavLink navItem={navItem} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="md:w-full md:h-full">
        <Outlet />
      </div>
    </div>
  );
};

type Props = {
  navItem: {
    isExternal: boolean;
    name: string;
    url: string;
    icon: any;
  };
};

const CustomNavLink = ({
  navItem: { isExternal, name, url, icon: Icon },
}: Props) => (
  <NavLink
    className={({ isActive }) =>
      isActive
        ? "p-4 rounded-2xl flex items-center bg-warmGrey"
        : "p-4 rounded-2xl flex items-center"
    }
    end
    rel={isExternal ? "noopener noreferrer" : undefined}
    target={isExternal ? "_blank" : undefined}
    to={url}
  >
    <Icon size={32} className="mr-2" />
    {name}
    {isExternal && <ArrowSquareOut size={24} className="ml-2 fill-darkGrey" />}
  </NavLink>
);
