import { Button } from "@nextui-org/react";
import { NavLink } from "react-router-dom";

export const WebsiteTeamSplash = () => (
  <div className="mx-10 md:mx-16 lg:mx-20 xl:mx-28 my-28">
    <h1 className="text-7xl lg:text-9xl font-light mb-14">Meet our Team</h1>
    <h2 className="switzer text-2xl text-griselda max-w-3xl">
      We're passionate about helping people improve their financial health.
      We've assembled a team with decades of experience in finance, real estate,
      technology, and design to create a product that both renters and landlords
      love.
    </h2>
    <Button
      className="w-full lg:w-fit switzer text-lg font-semibold text-midnight rounded-lg border-1 border-midnight my-8"
      size="lg"
      as={NavLink}
      to="/careers"
      variant="bordered"
    >
      View Careers
    </Button>
  </div>
);
