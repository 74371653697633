import { PieChart } from "react-minimal-pie-chart";
import { Link, NavLink, useLoaderData } from "react-router-dom";
import { AccountPieChartLegend } from "./AccountPieChartLegend";
import { AccountRentalDepositsTable } from "./AccountRentalDepositsTable";
import { AccountTransactionsTable } from "./AccountTransactionsTable";
import { LockedIcon, UnlockedIcon } from "./AccountIcons";
import { USD_NUMBER_FORMAT } from "../constants";
import { Button } from "@nextui-org/react";

const DEPOSITS_LIMIT = 3;
const TRANSACTIONS_LIMIT = 3;

type LoaderDataType = {
  investor: {
    firstName: string;
    lastName: string;
  };
  depositTotalFunds: number;
  totalInterestEarned: number;
  totalActiveDepositObligation: number;
  depositObligations: Array<{
    amount: number;
    status: string;
    damages: number | null;
    address: string;
    propertyMarketingName: string;
  }>;
  transactions: Array<{
    date: string;
    amount: number;
    status: string;
  }>;
};

export const AccountOverview = () => {
  const {
    depositObligations,
    depositTotalFunds,
    totalActiveDepositObligation,
    transactions,
  } = useLoaderData() as LoaderDataType;

  const availableFunds = depositTotalFunds - totalActiveDepositObligation;

  return (
    <div className="md:mx-12 md:my-12 2xl:mx-36 2xl:my-20">
      <div className="grid gap-12 grid-cols-1 sm:grid-cols-2 grid-rows-1 mb-12 sm:mt-12 sm:px-12">
        <div className="mt-6 mx-6 sm:m-0 lg:pr-28 xl:pr-32 2xl:pr-40 max-h-[450px]">
          <PieChart
            data={[
              {
                title: "Locked",
                value: totalActiveDepositObligation,
                color: "#ADBCAD",
              },
              {
                title: "Available",
                value: availableFunds,
                color: "#D3F883",
              },
            ]}
            lineWidth={30}
            totalValue={depositTotalFunds}
            paddingAngle={2}
            label={({ dataEntry }) => {
              if (dataEntry.title === "Locked") {
                return (
                  <text
                    dominantBaseline="central"
                    x="50"
                    y="50"
                    dx="0"
                    dy="-6"
                    textAnchor="middle"
                    style={{
                      fontSize: "12px",
                      fontFamily: "Outfit",
                      fill: "#1F2B2B",
                    }}
                  >
                    {USD_NUMBER_FORMAT.format(depositTotalFunds)}
                  </text>
                );
              }
              if (dataEntry.title === "Available") {
                return (
                  <text
                    dominantBaseline="central"
                    x="50"
                    y="50"
                    dx="0"
                    dy="6"
                    textAnchor="middle"
                    style={{
                      fontSize: "5px",
                      fontFamily: "Outfit",
                      fill: "#878D87",
                      fontWeight: "500",
                      letterSpacing: "0.7px",
                    }}
                  >
                    ACCOUNT TOTAL
                  </text>
                );
              }
            }}
          />
        </div>
        <div className="mx-6 sm:mx-0 grid grid-cols-2 gap-x-6 sm:flex sm:flex-col sm:justify-center">
          <div className="sm:mb-5">
            <AccountPieChartLegend
              colorToken="brightGreen"
              icon={UnlockedIcon}
              name="Available"
              value={availableFunds}
            />
          </div>
          <div className="sm:mt-5">
            <AccountPieChartLegend
              colorToken="sage"
              icon={LockedIcon}
              name="Locked"
              value={totalActiveDepositObligation}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-8 sm:gap-12 grid-cols-1 xl:grid-cols-2 pb-12">
        <AccountOverviewCard>
          <div className="flex justify-between">
            <div>
              <h2 className="outfit uppercase text-griselda font-semibold tracking-wider">
                Available Money
              </h2>
              <p className="outfit text-2xl font-light text-midnight">
                {USD_NUMBER_FORMAT.format(availableFunds)}
              </p>
            </div>
            {availableFunds > 0 && (
              <Button
                as={NavLink}
                variant="bordered"
                className="font-semibold"
                to="/withdraw"
              >
                Transfer Money
              </Button>
            )}
          </div>
          <p className="mt-5 p-5 bg-warmGrey rounded-lg font-light">
            <span className="font-semibold">Investing Tip:</span> We recommend
            keeping your money in Whale unless you need it as you will continue
            earning interest.
          </p>
        </AccountOverviewCard>
        <AccountOverviewCard>
          <div className="flex justify-between mb-2">
            <h2 className="outfit md:uppercase text-3xl md:text-base text-midnight md:text-griselda md:font-semibold md:tracking-wider">
              Transactions
            </h2>
            {transactions.length > TRANSACTIONS_LIMIT && (
              <Link
                className="hidden md:inline outfit uppercase text-midnight text-sm font-semibold tracking-wider"
                to="/account/transactions"
              >
                View All
              </Link>
            )}
          </div>
          <AccountTransactionsTable
            limit={TRANSACTIONS_LIMIT}
            transactions={transactions}
          />
          {transactions.length > TRANSACTIONS_LIMIT && (
            <div className="mt-4 md:hidden">
              <Link
                className="outfit uppercase text-midnight text-sm font-semibold tracking-wider"
                to="/account/transactions"
              >
                View All
              </Link>
            </div>
          )}
        </AccountOverviewCard>
        <AccountOverviewCard>
          <div className="flex justify-between mb-4">
            <h2 className="outfit md:uppercase text-3xl md:text-base text-midnight md:text-griselda md:font-semibold md:tracking-wider">
              Rental Deposits
            </h2>
            {depositObligations.length > DEPOSITS_LIMIT && (
              <Link
                className="hidden md:inline outfit uppercase text-midnight text-sm font-semibold tracking-wider"
                to="/account/deposits"
              >
                View All
              </Link>
            )}
          </div>
          <AccountRentalDepositsTable deposits={depositObligations} />
          {depositObligations.length > DEPOSITS_LIMIT && (
            <div className="mt-4 md:hidden">
              <Link
                className="outfit uppercase text-midnight text-sm font-semibold tracking-wider"
                to="/account/deposits"
              >
                View All
              </Link>
            </div>
          )}
        </AccountOverviewCard>
      </div>
    </div>
  );
};

const AccountOverviewCard = ({ children }: any) => (
  <div className="border-warmGrey border-t-[12px] px-5 pt-8 md:rounded-xl md:p-5 md:border-3 md:border-lightGreen">
    {children}
  </div>
);
