import { ElementType } from "react";
import { USD_NUMBER_FORMAT } from "../constants";

type Props = {
  colorToken: string;
  icon: ElementType;
  name: string;
  value: number;
};

export const AccountPieChartLegend = ({
  colorToken,
  icon: Icon,
  name,
  value,
}: Props) => (
  <div>
    <div className="flex items-center">
      <Icon size={40} weight="fill" className="fill-griselda" />
      <div className="ml-2">
        <p className="outfit text-sm sm:text-normal uppercase text-darkSage tracking-widest">
          {name}
        </p>
        <p className="outfit text-xl sm:text-2xl font-light text-midnight">
          {USD_NUMBER_FORMAT.format(value)}
        </p>
      </div>
    </div>
    <div className={`bg-${colorToken} h-[8px] rounded-lg w-full mt-4`} />
  </div>
);
