import { useEffect, useState, useRef, Fragment, KeyboardEvent } from "react";
import { BaseButton } from "../../BaseComponents";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import "./SocialSecurityInput.css";

const noop = () => {};

const STAR = "*";
const NUMBER_KEYS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

type Props = {
  initialValue?: string;
  onChange: (arg: string) => void;
};

const SocialSecurityInput = ({ initialValue = "", onChange }: Props) => {
  const defaultValue =
    initialValue.length === 9 ? Array.from(initialValue) : Array(9).fill("");

  // Store SSN digits in array with format ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
  const [digits, setDigits] = useState<string[]>(defaultValue);

  // Store SSN digits to display on UI. When hidden, numbers will be displayed as a star
  const [isHidden, setIsHidden] = useState(true);
  const [inputDigits, setInputDigits] = useState<string[]>(
    isHidden
      ? defaultValue.map((digit) => (digit !== "" ? STAR : ""))
      : defaultValue
  );

  // Store the index of the currently focused input
  const [focusIndex, setFocusIndex] = useState(0);

  // Array to store refs for HTML input fields
  const refs = useRef(new Array());

  const handleUpdateDigits = (newDigits: string[]) => {
    setDigits(newDigits);
    setInputDigits(
      isHidden
        ? newDigits.map((digit) => (digit !== "" ? STAR : ""))
        : newDigits
    );
    onChange(newDigits.join(""));
  };

  // Whenever the focus index changes, focus the new element
  useEffect(() => {
    refs.current[focusIndex].focus();
  }, [focusIndex, refs]);

  const handleKeyDown = (index: number, event: KeyboardEvent) => {
    const leftIndex = index === 0 ? index : index - 1;
    const rightIndex = index >= digits.length - 1 ? index : index + 1;

    if (NUMBER_KEYS.includes(event.key)) {
      const newDigits = [...digits];
      newDigits[index] = event.key;
      handleUpdateDigits(newDigits);
      setFocusIndex(rightIndex);
    }

    if (event.key === "Backspace") {
      if (digits[index] !== "") {
        const newDigits = [...digits];
        newDigits[index] = "";
        handleUpdateDigits(newDigits);
      }
      setFocusIndex(leftIndex);
    }

    if (event.key === "ArrowLeft") {
      setFocusIndex(leftIndex);
    }

    if (event.key === "ArrowRight") {
      setFocusIndex(rightIndex);
    }
  };

  const handleClickButton = () => {
    setIsHidden((prevIsHidden) => !prevIsHidden);
    setInputDigits(
      isHidden ? digits : digits.map((digit) => (digit === "" ? "" : STAR))
    );
    refs.current[focusIndex].focus();
  };

  return (
    <div className="SocialSecurityInput">
      <div className="flex items-center justify-between">
        {inputDigits.map((inputDigit: string, index) => (
          <Fragment key={index}>
            {(index === 3 || index === 5) && (
              <span className="mx-1 text-xl">-</span>
            )}
            <input
              className="mx-1"
              inputMode="numeric"
              onChange={noop}
              onKeyDown={(event) => handleKeyDown(index, event)}
              pattern="[0-9]*"
              placeholder="0"
              ref={(element) => (refs.current[index] = element)}
              type="text"
              value={inputDigit}
            />
          </Fragment>
        ))}
      </div>
      <BaseButton
        className="mt-8 min-w-[77px] bg-lightGreen"
        onPress={handleClickButton}
        size="sm"
        startContent={isHidden ? <Eye size={16} /> : <EyeClosed size={16} />}
      >
        {isHidden ? "Show" : "Hide"}
      </BaseButton>
    </div>
  );
};

export default SocialSecurityInput;
