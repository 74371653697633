import testimonialMark from "../../images/testimonial-mark.png";
import testimonialTommy from "../../images/testimonial-tommy.png";

export const WebsitePropertiesTestimonials = () => {
  return (
    <div className="mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-64 lg:my-48">
      <h2 className="text-5xl sm:text-7xl md:text-8xl font-light mb-8 lg:mb-16">
        What people are saying
      </h2>
      <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2">
        <div>
          <p className="text-lg">
            "Eliminating the burden of administering thousands of security
            deposits each year has been a huge win for our operations, finance,
            and on-site teams. Our residents love that they're earning money
            each month and are pleased to get their deposit back instantly when
            they move-out. Talk about a win-win."
          </p>
          <div className="flex items-center mt-6">
            <img
              width="72"
              height="72"
              src={testimonialMark}
              alt="Mark Chrisman headshot"
            />
            <div className="ml-3">
              <p className="font-semibold">
                Mark Chrisman from Haven Residential
              </p>
              <p className="text-griselda">~14,000 units on Whale</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-lg">
            "When I saw the tenant's deposit in Whale and off my books it was a
            good feeling. Like relieving me of a burden. I was a little
            concerned about the process and making the change but that feeling
            of relief makes it feel like Whale should be a no brainer for
            landlords."
          </p>
          <div className="flex items-center mt-6">
            <img
              width="72"
              height="72"
              src={testimonialTommy}
              alt="Tommy Ostendorf headshot"
            />
            <div className="ml-3">
              <p className="font-semibold">Tommy Ostendorf, Landlord</p>
              <p className="text-griselda">15 units on Whale</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
