import {
  Bank,
  Coins,
  FirstAidKit,
  Island,
  Laptop,
  Timer,
  TrendUp,
} from "@phosphor-icons/react";
import palmTrees from "../../images/palm-trees.png";

const BENEFITS = [
  {
    icon: Island,
    name: "Unlimited personal time off",
  },
  {
    icon: Timer,
    name: "Flexible working hours",
  },
  {
    icon: Laptop,
    name: "Work remotely (U.S.)",
  },
  {
    icon: TrendUp,
    name: "Company stock options",
  },
  {
    icon: Coins,
    name: "401(k) retirement plan",
  },
  {
    icon: FirstAidKit,
    name: "Medical / Dental insurance",
  },
  {
    icon: Bank,
    name: "FSA / HSA",
  },
];

export const WebsiteCareersBenefits = () => (
  <div className="grid gap-20 grid-rows-1 grid-cols-1 lg:grid-cols-2 lg:ml-20 xl:ml-28 my-32">
    <div className="lg:order-last">
      <img src={palmTrees} alt="Apartment building with palm trees" />
    </div>
    <div className="w-fit flex flex-col justify-center mx-10 md:mx-16 lg:mx-0">
      <h3 className="tracking-tight font-light text-4xl md:text-6xl">
        Benefits and perks
      </h3>
      <p className="text-griselda text-lg mt-4 mb-8">
        At Whale, we invest in our people. In addition to competitive salaries,
        we offer the below to all of our employees.
      </p>
      <ul>
        {BENEFITS.map(({ icon: Icon, name }) => (
          <li key={name} className="switzer text-lg flex items-center my-3">
            <Icon size={32} className="mr-3 min-w-8" />
            {name}
          </li>
        ))}
      </ul>
    </div>
  </div>
);
