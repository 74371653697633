import { Divider, Input } from "@nextui-org/react";
import { MagnifyingGlass, MapPin } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

const AUTOCOMPLETE_API = "https://api.radar.io/v1/search/autocomplete";
const API_PUBLIC_KEY = "prj_test_pk_b474b1a37c282a2ff980ba41d0856549c8e3be7f";

export type AddressAutocompleteType = {
  addressLabel: string;
  city: string;
  country: string;
  countryCode: string;
  countryFlag: string;
  county: string;
  distance: number;
  formattedAddress: string;
  latitude: number;
  layer: string;
  longitude: number;
  number: string;
  postalCode: string;
  state: string;
  stateCode: string;
  street: string;
  aptUnit?: string;
};

type Props = {
  onSelect: (address: AddressAutocompleteType) => void;
};

const AddressAutocomplete = ({ onSelect }: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const updateSuggestions = async (searchQuery: string) => {
    // If there is no search query, clear the suggestions and return early
    if (searchQuery.length === 0) {
      setSuggestions([]);
      return;
    }

    // Construct URL object with query parameters
    const url = new URL(
      `${AUTOCOMPLETE_API}?query=${searchQuery}&layers=address&limit=5`
    );

    // Send request to the autocomplete API
    const response = await fetch(url.href, {
      headers: { Authorization: API_PUBLIC_KEY },
    });

    // Parse the response as JSON data
    const data = await response.json();

    // Update local state with the new list of suggestions
    setSuggestions(data?.addresses || []);
  };

  // Debounce requests to API to avoid sending on every keypress
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateSuggestions(searchQuery);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div>
      <Input
        aria-label="Search"
        placeholder="Search address"
        classNames={{ base: "mb-5", input: "text-md" }}
        isClearable
        onValueChange={setSearchQuery}
        radius="lg"
        startContent={<MagnifyingGlass size={28} />}
        value={searchQuery}
        variant="bordered"
      />
      {suggestions.length > 0 && (
        <ul>
          {suggestions.map((suggestion: AddressAutocompleteType, index) => {
            const { number, street, city, stateCode, formattedAddress } =
              suggestion;
            return (
              <li key={formattedAddress}>
                <button
                  className="flex items-center w-full px-1 py-2 rounded-md hover:bg-lightGreen font-light text-midnight"
                  onClick={() => onSelect(suggestion)}
                >
                  <MapPin size={24} className="mr-2" />
                  {`${number} ${street}, ${city}, ${stateCode}`}
                </button>
                {index < suggestions.length - 1 && <Divider className="my-1" />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default AddressAutocomplete;
