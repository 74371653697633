import { Button } from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import whaleTeam from "../../images/whale-team.png";

export const WebsiteAboutMeetTeam = () => (
  <div className="mx-8 md:mx-16 lg:mx-20 xl:mx-28 my-48 flex flex-col items-center text-center">
    <img src={whaleTeam} width="70%" alt="Whale team" className="max-w-3xl" />
    <h3 className="text-4xl mt-6">The Whale Team</h3>
    <p className="text-2xl text-griselda font-light max-w-lg my-6">
      Our team has decades of experience in finance, real estate, technology,
      and design.
    </p>
    <div className="w-full flex items-center justify-center flex-col lg:flex-row">
      <Button
        className="w-full lg:w-fit m-2 switzer text-lg font-semibold text-midnight rounded-lg bg-brightGreen border-0"
        size="lg"
        as={NavLink}
        to="/team"
        variant="bordered"
      >
        Meet the Team
      </Button>
      <Button
        className="w-full lg:w-fit m-2 switzer text-lg font-semibold text-midnight rounded-lg border-1 border-midnight"
        size="lg"
        as={NavLink}
        to="/careers"
        variant="bordered"
      >
        Careers
      </Button>
    </div>
  </div>
);
